import React, { Component, Fragment, } from 'react';
import { DashboardControl, Extensions, DataSourceWizard, WizardSettings, AjaxRemoteService} from 'devexpress-dashboard-react';
import 'devextreme/dist/css/dx.light.css';
import '@devexpress/analytics-core/dist/css/dx-analytics.common.css';
import '@devexpress/analytics-core/dist/css/dx-analytics.light.css';
import '@devexpress/analytics-core/dist/css/dx-querybuilder.css';
import 'devexpress-dashboard/dist/css/dx-dashboard.light.css';
import Button from 'devextreme-react/button';

export class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.dashboardControlRef = React.createRef();

        this.onBeforeRender = this.onBeforeRender.bind(this);
        this.reloadData = this.reloadData.bind(this);
    }
 
    onBeforeRender(e) { 
        e.component.registerExtension(new DataSourceWizard(e.component));
    }

    reloadData(e) {
        this.dashboardControlRef.current.instance.reloadData();
    }

    render() {
        const dashboardEndpoint = window.location.origin.concat("/api/customdashboard").toLowerCase();


        return (
            <Fragment>
                <div>
                    <div style={{ float: "left", marginLeft: "5px"}}>
                        <Button text="Refresh" onClick={this.reloadData} />
                    </div>
                </div>

                <DashboardControl style={{ height: '93%' }}
                    onBeforeRender={this.onBeforeRender}
                    endpoint={dashboardEndpoint}
                    ref={this.dashboardControlRef}
                    workingMode="Viewer"
                >
                   
                    <Extensions>
                        <DataSourceWizard>
                            <WizardSettings enableOlapDataSource={false}
                                allowCreateNewJsonConnection={true}
                                canCreateNewJsonDataSource={ true}
                            >
                            </WizardSettings>
                        </DataSourceWizard>
                    </Extensions>
                </DashboardControl>
            </Fragment>
        );
    }
}

export default Dashboard;
