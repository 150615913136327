import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Accordion, { Item } from 'devextreme-react/accordion';
import './Accordation.css';
import './Drawer.css';

class NavigationList extends Component {
    render() {
        return (
            <div className="list" >
                <Accordion
                    collapsible={true}
                    multiple={false}
                    animationDuration={300}
                >
                    <Item title="Administrare">
                        <div className="accordionDiv">
                            <Link className="accordionLink" style={{ textDecoration: 'none' }} to="/Utilizatori">Utilizatori</Link>
                        </div>

                        <div className="accordionDiv">                 
                            <Link className="accordionLink" style={{ textDecoration: 'none', cursor: 'default', color: 'gray' }}>
                                Rapoarte & Alerte
                            </Link>
                         </div>
                    </Item>

                    <Item title="Templates & Methodoligies">
                        <Accordion
                            collapsible={true}
                            multiple={false}
                            animationDuration={300}
                        >
                            <Item title="Nomenclatoare">
                                <div className="accordionDiv">
                                    <Link className="accordionLink" style={{ textDecoration: 'none' }} to="/Localitati">Localitati</Link>
                                </div>

                                <div className="accordionDiv">
                                    <Link className="accordionLink" style={{ textDecoration: 'none' }} to="/CAEN">Coduri CAEN</Link>
                                </div>

                                <div className="accordionDiv">
                                    <Link className="accordionLink" style={{ textDecoration: 'none' }} to="/Activitati">Activitati Timesheet</Link>
                                </div>

                                <div className="accordionDiv">
                                    <Link className="accordionLink" style={{ textDecoration: 'none' }} to="/Completitudine">Risk Assesment - Dimensiuni</Link>
                                </div>

                                <div className="accordionDiv">
                                    <Link className="accordionLink" style={{ textDecoration: 'none' }} to="/CategoriiControl">Risk Assesment - Categorii de control</Link>
                                </div>

                                <div className="accordionDiv">
                                    <Link className="accordionLink" style={{ textDecoration: 'none' }} to="/NomenclatorIndicatoriCompanie">Indicatori financiari companie</Link>
                                </div>
                            </Item>
                        </Accordion>
                    </Item>

                    <Item title="Gestiune echipa">
                        <div className="accordionDiv">
                            <Link className="accordionLink" style={{ textDecoration: 'none' }} to="/Angajati">Angajati</Link>
                        </div>

                        <div className="accordionDiv">
                            <Link className="accordionLink" style={{ textDecoration: 'none' }} to="/ClientiAngajati">Distribuirea clientilor pe anajati</Link>
                        </div>

                        <div className="accordionDiv">
                            <Link className="accordionLink" style={{ textDecoration: 'none' }} to="/Planificator">Planificator angajati</Link>
                        </div>

                        <div className="accordionDiv">
                            <Link className="accordionLink" style={{ textDecoration: 'none' }} to="/TimesheetAngajati">Timesheet angajati</Link>
                        </div>

                        <div className="accordionDiv">
                            <Link className="accordionLink" style={{ textDecoration: 'none' }} to="/ProjectTaskComponent">Gantt</Link>
                        </div>

                    </Item>

                    <Item title="Gestiune clienti">
                        <div className="accordionDiv">
                            <Link className="accordionLink" style={{ textDecoration: 'none' }} to="/Clienti">Lista clienti</Link>
                        </div>

                        <div className="accordionDiv">
                            <Link className="accordionLink" style={{ textDecoration: 'none' }} to="/Oferte">Ofertare</Link>
                        </div>

                        <div className="accordionDiv">
                            <Link className="accordionLink" style={{ textDecoration: 'none' }} to="/Project">Proiecte</Link>
                        </div>

                        <div className="accordionDiv">
                            <Link className="accordionLink" style={{ textDecoration: 'none' }} to="/Contracte">Lista contracte</Link>
                        </div>

                        <div className="accordionDiv">
                            <Link className="accordionLink" style={{ textDecoration: 'none' }} to="/Facturare">Facturare</Link>
                        </div>

                        <div className="accordionDiv">
                            <Link className="accordionLink" style={{ textDecoration: 'none' }} to="/EvolutieContracte">Evolutie contracte</Link>
                        </div>

                        <div className="accordionDiv">
                            <Link className="accordionLink" style={{ textDecoration: 'none' }} to="/IndicatoriCompanie">Indicatori companie</Link>
                        </div>

                        <div className="accordionDiv">
                            <Link className="accordionLink" style={{ textDecoration: 'none' }} to="/Dashboard">Dashboard</Link>
                        </div>
                    </Item>

                    <Item title="Risk management">
                        <div className="accordionDiv">
                            <Link className="accordionLink" style={{ textDecoration: 'none' }} to="/CompletitudineClient">Completitudine</Link>
                        </div>
                    </Item>

                    <Item title="Audit Intern">
                        <div className="accordionDiv">
                            <Link className="accordionLink" style={{ textDecoration: 'none' }} to="/AuditIntern">Audit intern</Link>
                        </div>
                    </Item>
                </Accordion>
            </div>
        );
    }
}

export default NavigationList;
