import {getEmployeesDataSource, getClientsDataSource, getProjectDataSource, getActivitiesDataSource} from "./_FormsDataSources"

export const employeeEditorOptions = (onValueChangedFunction) => {
    return {
        valueExpr: "Id",
        displayExpr: "FullName",
        dataSource: getEmployeesDataSource(),
        title: "Angajati",
        onValueChanged: (e) => {
            onValueChangedFunction(e.value);
        }
    }
}

export const clientsEditorOptions = (employeeId, onValueChangedFunction) => {
    return {
        valueExpr: "Id",
        displayExpr: "Name",
        dataSource: getClientsDataSource(employeeId),
        disabled: !employeeId ? true : false,
        title: "Clienti",
        onValueChanged: (e) => {
            onValueChangedFunction(e.value)
        }
    }
}

export const projectsEditorOptions = (clientId) => {
    return {
        valueExpr: "Id",
        displayExpr: "Name",
        dataSource: getProjectDataSource(clientId),
        disabled: !clientId ? true : false,
        title: "Proiecte"
    }
}

export const activitiesEditorOptions = () => {
    return {
        valueExpr: "Id",
        displayExpr: "Name",
        dataSource: getActivitiesDataSource(),
        title: "Activitati"
    }
}