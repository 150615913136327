import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import roMessages from "./../../../ro.json";
import { locale, loadMessages } from "devextreme/localization";
import { getSchedulerData } from "components/EmployeesScheduler/SchedulerComponent/DataSource"
import ReactDOM from 'react-dom';
import { Scheduler } from 'devextreme-react';
import { Editing, Scrolling } from 'devextreme-react/scheduler';
import { AddAppointmentPopup } from "components/EmployeesScheduler/SchedulerComponent/_AddAppointmentPopup"
import { EditAppointmentPopup } from "components/EmployeesScheduler/SchedulerComponent/_EditAppointmentPopup"
import { AppointmentTemplate } from "components/EmployeesScheduler/SchedulerComponent/_AppointmentTemplate";

export class SchedulerComponent extends Component {
    static displayName = SchedulerComponent.name;

    constructor(props) {
        super(props)
        loadMessages(roMessages);
        locale(navigator.language);
        this.state = {
            employeeId: null,
            clientId: null

        };
        this.addPopup = null;
        this.editPopup = null;
        this.isDoubleCell = 0;
        this.prevCellData = null;
        this.isDoubleApp = 0;
        this.schedulerRef = React.createRef();
        this.onCellClick = (e) => {
            if (this.isDoubleCell == 1)
                this.prevCellData = e.cellData;
            this.isDoubleCell++;
            setTimeout(() => {
                if (this.isDoubleCell == 2) {
                    this.renderAddPopUp(e, this.prevCellData)

                }
                else if (this.isDoubleCell == 1) {
                    //Handler single click here
                    //alert('Click');
                }
                this.isDoubleCell = 0;
                this.prevCellData = null;
            }, 300);
        }

        this.renderAddPopUp = (e, prevCellData) => {
            if ((e.cellData.startDate == prevCellData.startDate) && (e.cellData.endDate == prevCellData.endDate)) {
                const component = ReactDOM.render(
                    <AddAppointmentPopup
                        info={{ clientId: this.state.clientId, employeeId: this.state.employeeId, startDate: e.cellData.startDate, schedulerRef: this.schedulerRef }}
                    />,
                    document.getElementById("addPopupContainer")
                );
                component.forceUpdate();
            }
        }

        this.onAppointmentClick = (e) => {
            if (this.isDoubleApp == 1)
                this.prevCellData = e.appointmentData;
            this.isDoubleApp++;
            setTimeout(() => {
                if (this.isDoubleApp == 2) {
                    this.renderEditPopUp(e, this.prevCellData)
                }
                else if (this.isDoubleApp == 1) {
                    //Handler single click here
                }
                this.isDoubleApp = 0;
                this.prevCellData = null;
            }, 300);
        }

        this.renderEditPopUp = (e, prevCellData) => {
            if ((e.appointmentData.Id === prevCellData.Id)) {
                const component = ReactDOM.render(<EditAppointmentPopup info={{ clientId: this.state.clientId, employeeId: this.state.employeeId, startDate: e.appointmentData.startDate, entryId: e.appointmentData.Id, schedulerRef: this.schedulerRef }} />, document.getElementById("editPopupContainer"));
                component.forceUpdate();
            }
        }
    }

    render() {
        return (
            <Scheduler
                timeZone="Europe/Bucharest"
                views={['timelineWorkWeek', 'timelineMonth']}
                defaultCurrentView="timelineMonth"
                defaultCurrentDate={new Date()}
                startDayHour={9}
                ref={this.schedulerRef}
                maxAppointmentsPerCell={6}
                height="79vh"
                onAppointmentFormOpening={(e) => { e.cancel = true }}
                onCellClick={this.onCellClick}
                onAppointmentClick={this.onAppointmentClick}
                appointmentComponent={AppointmentTemplate}
                textExpr="Name"
                scrolling={{ mode: "virtual" }}
                startDateExpr="StartDate"
                endDateExpr="EndDate"
                crossScrollingEnabled={true}
                dataSource={getSchedulerData(this.state.employeeId, this.state.clientId, this.schedulerRef)}
                onAppointmentRendered={(e) => {
                    if(e.appointmentElement.style.transform === ""){
                        return;
                    }
                    var translateLeft = e.appointmentElement.style.transform.split(',')[0].split('(')[1];
                    translateLeft = translateLeft.substring(0, translateLeft.length - 2)
                    var translateTop = e.appointmentElement.style.transform.split(',')[1];
                    translateTop = translateTop.substring(0, translateTop.length - 3).trim();
                    e.appointmentElement.style.height = "85px";
                    if(translateTop === "26"){
                        e.appointmentElement.style.transform = `translate(${translateLeft}px, ${translateTop}px)`;
                    }
                    else{
                        var siblingTranslateTop = e.appointmentElement.previousElementSibling.style.transform.split(',')[1];
                        siblingTranslateTop = siblingTranslateTop.substring(0, siblingTranslateTop.length - 3).trim();
                        e.appointmentElement.style.transform = `translate(${translateLeft}px, ${parseFloat(siblingTranslateTop) + 90}px)`
                    }
                }}
            >
                <Editing allowAdding={false} allowUpdating={false} />
            </Scheduler>
        )
    }

    componentDidUpdate(prevProps) {
        const { employeeId, clientId } = prevProps
        if (this.props.employeeId !== employeeId || this.props.clientId !== clientId) {
            this.setState({
                employeeId: this.props.employeeId,
                clientId: this.props.clientId
            })
        }
    }

    componentDidMount(props) {
        const { employeeId, clientId } = this.props
        this.setState({
            employeeId: employeeId,
            clientId: clientId
        })
    }

}

export default SchedulerComponent;
