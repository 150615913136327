import React, {Component} from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import ReactDOM from 'react-dom';
import { onRowUpdating, calculateFilterExpresion } from "Utils/Utils";
import { locale, loadMessages } from "devextreme/localization";
import DataGrid, {
    Button,
    Column,
    Editing,
    SearchPanel,
    Lookup,
    Scrolling,
    FilterRow,
    Format
} from 'devextreme-react/data-grid';
import { getClientCompanyIndicatorsDataSource, getClientsDataSource, getCompanyIndicatorsDataSource } from './_ClientCompanyIndicatorsDatasources';

const calculateCellValue = (object, year) => {
    if(!object.ClientCompanyIndicatorYears){
        return object[year.toString()]
    }
    if(object[year.toString()]){
        return object[year.toString()]
    }
    for(var i = 0; i < object.ClientCompanyIndicatorYears.length; i++){
        var objectYear = object.ClientCompanyIndicatorYears[i];
        if(objectYear.Year === year){
            return objectYear.Value;
        }
    }
    return null
}

export class CompanyIndicatorsGrid extends Component {
    static displayName = CompanyIndicatorsGrid.name;

    constructor(props){
        super(props)
        locale(navigator.language);
        this.grdRef = React.createRef();
        this.state = {
            ClientId: null,
            StartYear: null,
            EndYear: null,
            IndicatorsCode: null
        }

        this.auditProps = {
            EntryId: 0,
            Page: ""
        }

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "ClientCompanyIndicators");
        }

        this.dateInterval = [];

        this.getDateInterval = (startYear, endYear) => {
            var interval = [{year: startYear, index: "0"}];
            for(var i = startYear + 1; i <= endYear; i++){
                interval[interval.length] = {year: i, index: `${(i - startYear)}`};
            }
            return interval;
        }
    }

    render() {
        return(
                <DataGrid
                    id="clientIndicatorsGrid"
                    ref={this.grdRef}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    wordWrapEnabled={true}
                    columnAutoWidth={true}
                    dataSource={getClientCompanyIndicatorsDataSource(this.state.ClientId, this.state.IndicatorsCode, this.state.StartYear, this.state.EndYear)}
                    onRowUpdating={(e) => { onRowUpdating(e); }}
                    remoteOperations={true}
                    height="38vh"
                    width="auto"
                >
                    <FilterRow visible={true} />
                    <Scrolling columnRenderingMode="standard" mode="standard" scrollByThumb={true} useNative={true} />
                    <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Cautare..." />
                    <Editing allowUpdating={true} allowDeleting={true} allowAdding={true} mode="batch" useIcons={true} />
                    <Column type="buttons" cssClass="vertical-center" minWidth="10em" fixed={true} fixedPosition="left">
                        <Button name="delete" cssClass="underlineBtn" />
                        <Button icon="info" hint="Audit" onClick={this.auditOnClick} />
                    </Column>
                    <Column dataField="CompanyIndicatorId" caption="Indicator" cssClass="vertical-center" alignment="center" minWidth="20em" fixed={true} fixedPosition="left"
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("CompanyIndicator.Id", selectedFilterOperations, filterValue) }}
                        calculateSortValue="CompanyIndicator.Name"
                    >
                        <Lookup displayExpr="Name" valueExpr="Id" cssClass="vertical-center" alignment="center" dataSource={getCompanyIndicatorsDataSource(this.state.IndicatorsCode)} 
                            dropdownOptions={{title: "Indicatori companie"}}
                        />
                    </Column>
                    {
                        this.dateInterval.map(function(item){
                            return (
                                <Column key={item.index} caption={item.year.toString()} dataField={item.year.toString()}
                                    cssClass="vertical-center" alignment="center" minWidth="10em" dataType="number"
                                    allowFiltering={false}
                                    allowSorting={false}
                                    calculateCellValue={(rowObject) => {return calculateCellValue(rowObject, item.year)}}
                                >
                                    <Format type="currency" editorOptions={{format: "currency", precision: 2, currency:"RON"}} precision={2} currency="RON" />
                                </Column>
                            )
                        })
                    }
                </DataGrid>
        )
    }

    componentDidMount(){
        this.setState({
            ClientId: this.props.ClientId,
            StartYear: this.props.StartYear,
            EndYear: this.props.EndYear,
            IndicatorsCode: this.props.IndicatorsCode
        })

        this.dateInterval = this.getDateInterval(this.props.StartYear, this.props.EndYear)
    }

    componentDidUpdate(prevProps){
        const {StartYear, EndYear, ClientId, IndicatorsCode} = prevProps;
        if(StartYear !== this.props.StartYear || EndYear !== this.props.EndYear || ClientId !== this.props.ClientId || IndicatorsCode !== this.props.IndicatorsCode){
            this.setState({
                ClientId: this.props.ClientId,
                StartYear: this.props.StartYear,
                EndYear: this.props.EndYear,
                IndicatorsCode: this.props.IndicatorsCode
            })
            
            this.dateInterval = this.getDateInterval(this.props.StartYear, this.props.EndYear)
        }
    }
    
}