import React, { Component, Fragment } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import roMessages from "./../../../ro.json";
import ReactDOM from 'react-dom';
import { onRowUpdating, isAuditVisible } from "Utils/Utils";
import { locale, loadMessages } from "devextreme/localization";
import { getBillsDataSource, getPaymentMethods, getServiceCategory } from "components/Bills/BillsGrid/DataSources/_DataSources";
import {
    calculatePercentDisplayValue,
    calculateEuroBillCellValue,
    calculateRonBillCellValue,
    calculateFullBillValue,
    getBilledAmmountForMonth,
    getBillEstimatedAmmountForMonth,
    getBillActualAmmountForMonth,
    calculateCheckValue,
    calculateContractNumber,
    getBillRecievedAmmountForMonth,
    calculateBillingMonth
} from "components/Bills/BillsGrid/CalculateCellValues/_CalculateCellValues";
import { getColumnCaption, getDateInterval } from "components/Bills/BillsGrid/DateFunctions/_DateFunctions";
import DataGrid, {
    Button,
    Column,
    Editing,
    SearchPanel,
    Lookup,
    Scrolling,
    FilterRow,
    FormItem,
    Form,
    Popup,
    Format,
    Sorting
} from 'devextreme-react/data-grid';

const linkCell = (rowData, date) => {
    var billDate = new Date(rowData.BillMonth)
    if (rowData.RowType == "summary") {
        var month = String(date.getMonth() + 1).padStart(2, "0")
        var year = String(date.getFullYear())
        var url = `MailBills?month=${year}${month}`
        var sum = 0;
        for (var i = 0; i < rowData.SummaryValuesByMonth.length; i++) {
            var billDateMonth = new Date(rowData.SummaryValuesByMonth[i].BilledDate)
            if (billDateMonth.getMonth() === date.getMonth() && billDateMonth.getFullYear() === date.getFullYear()) {
                sum += rowData.SummaryValuesByMonth[i].EstimatedValue;
            }
        }
        return (
            <a href={url}>
                {+(Math.round(sum + "e+2") + "e-2")}
            </a>
        )
    }
    else {
        if (billDate.getMonth() === date.getMonth() && billDate.getFullYear() === date.getFullYear()) {
            return +(Math.round(((parseFloat(rowData.RonTotal) * parseFloat(rowData.Percent)) / 100.00) + "e+2") + "e-2")
        }
    }
}

export class BillsGrid extends Component {
    static displayName = BillsGrid.name;

    constructor(props) {
        super(props)
        loadMessages(roMessages);
        locale(navigator.language);
        this.state = {
            EntryId: 0,
            Page: "",
            StartDate: new Date(new Date().setDate(1)),
            EndDate: new Date(new Date(new Date().setFullYear(new Date().getFullYear() + 1)).setDate(1)),
            Refresh: 0
        }
        this.contractId = 0;
        this.dateInterval = getDateInterval(this.state.StartDate, this.state.EndDate)
        this.grdRef = React.createRef();

        this.auditProps = {
            EntryId: 0,
            Page: ""
        }

        this.calculateFilterExpresion = (dataField, operation, value) => {
            if (operation == "between") {
                return [[dataField, ">=", value], "and", [dataField, "<=", value]];
            }
            else {
                if (value instanceof Date) {
                    var date = new Date(new Date(value.valueOf()).setDate(value.getDate() + 1));
                    return [[dataField, ">=", value], "and", [dataField, "<=", new Date(date.setDate(date.getDate() + 1))]]
                }
                return [dataField, operation, value];
            }
        }

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "Bills");
        }

        this.renderAuditPopup = (EntryId, Page) => {
            if (EntryId !== 0 && EntryId !== null && EntryId && Page && Page !== "") {
                return <PopupComponent info={{ EntryId: EntryId, Page: Page }} />
            }
        }

        this.decimalEditorOptions = {
            format: {
                type: "fixedPoint",
                precision: 2
            }
        }

        this.dateBoxOptions = {
            displayFormat: "MM.yyyy",
            calendarOptions: {
                maxZoomLevel: "year",
                minZoomLevel: "year",
                zoomLevel: "year"
            }
        }

        this.getMergeDataField = (dataField) => {
            var dataFieldMerge = {
                "ContractNumber": "ContractNumber",
                "Client": "ContractNumber",
                "ContractDetailName": "ContractDetailName",
                "ServiceCategoryId": "ContractNumber",
                "ContractPurpose": "ContractDetailName",
                "EuroTotal": "ContractDetailName",
                "RonTotal": "ContractDetailName",
                "PaymentMethodId": "ContractDetailName",
                "ContractDetailBillValue": "ContractDetailName",
                "CheckRealised": "ContractDetailName"
            }
            if (!dataField) return "Id";
            if (dataField.includes("EstimatedMonths/") || dataField.includes("RealisedMonths/") || dataField.includes("BillingMonths/") || dataField.includes("ReceivedMonths/")) {
                return "ContractDetailName";
            }

            if (dataFieldMerge[dataField]) return dataFieldMerge[dataField];
            
            return "Id"
        }

        this.areButtonsVisible = (e) => {
            return isAuditVisible(e) && e.row.data.RowType !== "summary"
        }

        this.billingUpdate = (e) => {
            this.grdRef.current.instance.beginUpdate();
            this.mergeElements = {}
            this.grdRef.current.instance.refresh();
            this.grdRef.current.instance.endUpdate();
        }
        this.mergeElements = {};

        this.onCellPrepared = (cellInfo) => {
            if (cellInfo.rowType !== "data" || !cellInfo.column.dataField || this.getMergeDataField(cellInfo.column.dataField) == "Id") {
                return;
            }

            if (cellInfo.rowIndex > 0 && cellInfo.column.command != "edit") {
                if (cellInfo.column.dataField.includes("EstimatedMonths/") || cellInfo.column.dataField.includes("RealisedMonths/") || cellInfo.column.dataField.includes("BillingMonths/") || cellInfo.column.dataField.includes("ReceivedMonths/")) {
                    if (cellInfo.component.cellValue(cellInfo.rowIndex - 1, this.getMergeDataField(cellInfo.column.dataField)) == cellInfo.data[this.getMergeDataField(cellInfo.column.dataField)]) {
                        var prev = this.mergeElements[cellInfo.rowIndex - 1][cellInfo.column.dataField];
                        if (!this.mergeElements[cellInfo.rowIndex]) this.mergeElements[cellInfo.rowIndex] = {};
                        this.mergeElements[cellInfo.rowIndex][cellInfo.column.dataField] = prev;
                        if (prev) {
                            if (cellInfo.value) {
                                if (typeof (cellInfo.value) == "number") {
                                    var fractionDigits = new Intl.NumberFormat(undefined, {
                                        style: 'currency',
                                        currency: 'RON'
                                    }).resolvedOptions().maximumFractionDigits;
                                    prev.textContent = (cellInfo.value).toLocaleString("ro-RO", { maximumFractionDigits: fractionDigits, minimumFractionDigits: fractionDigits });
                                }
                                else {
                                    prev.textContent = cellInfo.value;
                                }
                            }
                            cellInfo.cellElement.style.display = "none";
                            var span = prev.getAttribute("rowspan")
                            if (span)
                                prev.setAttribute("rowspan", Number(span) + 1)
                            else
                                prev.setAttribute("rowspan", 2)
                        }
                        return;
                    }
                    else {
                        if (this.mergeElements[cellInfo.rowIndex]) {
                            if (this.mergeElements[cellInfo.rowIndex][cellInfo.column.dataField]) {
                                var prev = this.mergeElements[cellInfo.rowIndex][cellInfo.column.dataField];
                                if (Number(prev.parentElement.getAttribute("aria-rowindex")) == cellInfo.rowIndex + 1) {
                                    cellInfo.cellElement = prev;
                                }
                                else {
                                    cellInfo.cellElement.style.display = "none";
                                }
                            }
                        }
                    }
                }
            }
            if (cellInfo.rowIndex > 0 && cellInfo.column.command != "edit") {
                if (cellInfo.component.cellValue(cellInfo.rowIndex - 1, this.getMergeDataField(cellInfo.column.dataField)) == cellInfo.data[this.getMergeDataField(cellInfo.column.dataField)]) {
                    if (cellInfo.component.cellValue(cellInfo.rowIndex - 1, cellInfo.column.dataField)) {
                        var prev = this.mergeElements[cellInfo.rowIndex - 1][cellInfo.column.dataField]
                        if (!this.mergeElements[cellInfo.rowIndex]) this.mergeElements[cellInfo.rowIndex] = {};
                        if (!this.mergeElements[cellInfo.rowIndex][cellInfo.column.dataField]) {
                            this.mergeElements[cellInfo.rowIndex][cellInfo.column.dataField] = prev;
                            if (prev) {
                                cellInfo.cellElement.style.display = "none";
                                var span = prev.getAttribute("rowspan")
                                if (span)
                                    prev.setAttribute("rowspan", Number(span) + 1)
                                else
                                    prev.setAttribute("rowspan", 2)
                            }
                        }
                        else {
                            if (this.mergeElements[cellInfo.rowIndex][cellInfo.column.dataField] == this.mergeElements[cellInfo.rowIndex - 1][cellInfo.column.dataField]) {
                                cellInfo.cellElement.style.display = "none";
                            }
                        }
                    }
                    else {
                        if (!this.mergeElements[cellInfo.rowIndex]) this.mergeElements[cellInfo.rowIndex] = {};
                        this.mergeElements[cellInfo.rowIndex][cellInfo.column.dataField] = cellInfo.cellElement
                    }
                }
                else {
                    if (!this.mergeElements[cellInfo.rowIndex]) this.mergeElements[cellInfo.rowIndex] = {};
                    this.mergeElements[cellInfo.rowIndex][cellInfo.column.dataField] = cellInfo.cellElement
                }
            }
            else {
                if (!this.mergeElements[cellInfo.rowIndex]) this.mergeElements[cellInfo.rowIndex] = {};
                this.mergeElements[cellInfo.rowIndex][cellInfo.column.dataField] = cellInfo.cellElement
            }
        }

        this.billsGrid_onRowPrepared = (e) => {
            if (e.rowType === "header") {
                e.rowElement.style.height = "3.2em";
            }
        }
    }

    render() {
        return (
            <Fragment>
                <DataGrid
                    id="billsGrid"
                    ref={this.grdRef}
                    dataSource={getBillsDataSource(this.contractId, this.state.StartDate, this.state.EndDate, this.editing)}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    wordWrapEnabled={true}
                    columnAutoWidth={true}
                    width="auto"
                    height="82vh"
                    onRowUpdating={(e) => { onRowUpdating(e); }}
                    remoteOperations={true}
                    onRowPrepared={this.billsGrid_onRowPrepared}
                    onCellPrepared={this.onCellPrepared}
                    remoteOperation={true}
                    onRowUpdated={this.billingUpdate}
                    onEditCanceled={this.billingUpdate}
                >
                    <FilterRow visible={true} />
                    <Sorting mode="none" />
                    <Scrolling columnRenderingMode="standard" mode="standard" scrollByThumb={true} useNative={true} />
                    <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Cautare..." />
                    <Editing allowUpdating={true} mode="popup" useIcons={true}>
                        <Form colCount={1} title="Facturi" />
                        <Popup title="Facturi" showTitle={true} onHiding={this.beforeBillingUpdate} minWidth="20em" maxWidth="40em" height="auto"/>
                    </Editing>
                    <Column type="buttons" cssClass="vertical-center" minWidth="10em">
                        <Button name="edit" cssClass="underlineBtn" visible={this.areButtonsVisible}/>
                        <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={this.areButtonsVisible} />
                    </Column>
                    <Column dataField="Id" visible={false}>
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="ContractNumber" caption="Numar contract" cssClass="vertical-center" alignment="center" minWidth="2em" calculateCellValue={calculateContractNumber}
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => { return this.calculateFilterExpresion("ContractDetail.Contract.Number", selectedFilterOperations, filterValue) }}
                    >
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="Client" caption="Client" cssClass="vertical-center" alignment="center" minWidth="10em"
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => { return this.calculateFilterExpresion("ContractDetail.Contract.Client.Name", selectedFilterOperations, filterValue) }}
                    >
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="ContractDetailName" caption="Contract" cssClass="vertical-center" alignment="center" minWidth="10em" dataType="date"
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => { return this.calculateFilterExpresion("ContractDetail.Date", selectedFilterOperations, filterValue) }}
                    >
                        <FormItem visible={false} editorType="dxDateBox" />
                    </Column>
                    <Column dataField="ServiceCategoryId" caption="Categorie servicii" cssClass="vertical-center" alignment="center" minWidth="15em"
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => { return this.calculateFilterExpresion("ContractDetail.Contract.ServiceCategoryId", selectedFilterOperations, filterValue) }}
                    >
                        <Lookup dataSource={getServiceCategory()} valueExpr="Id" displayExpr="Name" cssClass="vertical-center" alignment="center" minWidth="10em" />
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="ContractPurpose" caption="Obiect contract" cssClass="vertical-center" alignment="center" minWidth="10em"
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => { return this.calculateFilterExpresion("ContractDetail.ContractPurpose", selectedFilterOperations, filterValue) }}
                    >
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="EuroTotal" caption="Onorariu EUR" cssClass="vertical-center" alignment="center" minWidth="10em"
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => { return this.calculateFilterExpresion("ContractDetail.PaymentFeeEur", selectedFilterOperations, filterValue) }}
                    >
                        <FormItem visible={false} />
                        <Format type="fixedPoint" precision={2} />
                    </Column>
                    <Column dataField="RonTotal" caption="Onorariu RON" cssClass="vertical-center" alignment="center" minWidth="10em"
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => { return this.calculateFilterExpresion("ContractDetail.PaymentFeeRon", selectedFilterOperations, filterValue) }}
                    >
                        <FormItem visible={false} />
                        <Format type="fixedPoint" precision={2} />
                    </Column>
                    <Column dataField="PaymentMethodId" caption="Modalitate de plata" cssClass="vertical-center" alignment="center" minWidth="10em"
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => { return this.calculateFilterExpresion("ContractDetail.PaymentMethodId", selectedFilterOperations, filterValue) }}
                    >
                        <FormItem visible={false} />
                        <Lookup dataSource={getPaymentMethods()} valueExpr="Id" displayExpr="Payment" cssClass="vertical-center" alignment="center" minWidth="10em" />
                    </Column>
                    <Column dataField="Percent" caption="Transa" cssClass="vertical-center" alignment="center" minWidth="10em" calculateDisplayValue={calculatePercentDisplayValue}
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => { return this.calculateFilterExpresion("PaymentPercent.Number", selectedFilterOperations, filterValue) }}
                    >
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Transa EUR" dataField="EstimatedEur" cssClass="vertical-center" alignment="center" minWidth="10em" calculateCellValue={calculateEuroBillCellValue}
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => { return this.calculateFilterExpresion(calculateEuroBillCellValue, selectedFilterOperations, filterValue) }}
                        allowFiltering={false}
                    >
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Transa RON" dataField="EstimatedRon" cssClass="vertical-center" alignment="center" minWidth="10em" calculateCellValue={calculateRonBillCellValue}
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => { return this.calculateFilterExpresion(calculateRonBillCellValue, selectedFilterOperations, filterValue) }}
                        allowFiltering={false}
                    >
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Luna facturare" dataField="BillMonth" cssClass="vertical-center" alignment="center" minWidth="10em"
                        editorType="dxDateBox"
                        calculateDisplayValue={calculateBillingMonth}
                        editorOptions={this.dateBoxOptions}
                    >
                    </Column>
                    <Column caption="Numar factura" dataField="BillNumber" cssClass="vertical-center" alignment="center" minWidth="10em">
                    </Column>
                    <Column caption="Data facturare" dataField="BillDate" dataType="date" format="dd.MM.yyyy" cssClass="vertical-center" alignment="center">
                    </Column>
                    <Column caption="Valoare factura (RON)" dataField="BillValue" cssClass="vertical-center" alignment="center" dataType="number" editorOptions={this.decimalEditorOptions} minWidth="10em">
                        <Format type="fixedPoint" precision={2} />
                    </Column>
                    <Column caption="Valoare incasata (RON)" dataField="BillInvoicedAmmount" cssClass="vertical-center" alignment="center" dataType="number" editorOptions={this.decimalEditorOptions} minWidth="10em" visible="false">
                        <Format type="fixedPoint" precision={2} />
                    </Column>
                    <Column caption="Total factura (+ TVA)" dataField="BillTvaValue" cssClass="vertical-center" alignment="center" dataType="number" editorOptions={this.decimalEditorOptions} calculateCellValue={calculateFullBillValue} minWidth="10em"
                        allowFiltering={false}
                    >
                        <Format type="fixedPoint" precision={2} />
                    </Column>
                    <Column caption="Total valoare facturi fara TVA" dataField="ContractDetailBillValue" cssClass="vertical-center" alignment="center" dataType="number" minWidth="10em"
                        allowFiltering={false}
                    >
                        <Format type="fixedPoint" precision={2} />
                        <FormItem visible={false} />
                    </Column>
                    <Column caption="Check realizat vs estimat" dataField="CheckRealised" minWidth="10em" cssClass="vertical-center" alignment="center" dataType="number" calculateCellValue={calculateCheckValue}
                        allowFiltering={false}
                    >
                        <Format type="fixedPoint" precision={2} />
                        <FormItem visible={false} />
                    </Column>
                    {
                        this.dateInterval.map(function (item) {
                            return (
                                <Column key={item.id} caption={getColumnCaption(item.date)} cssClass="vertical-center" alignment="center" minWidth="35em">
                                    <Column caption="Obiect" dataField={"BillingMonths/" + item.date.getMonth() + "/" + item.date.getFullYear()} minWidth="10em" cssClass="vertical-center" alignment="center"
                                        calculateCellValue={function (rowData) { return getBilledAmmountForMonth(item.date, rowData) }}
                                        allowFiltering={false}
                                        allowSorting={false}
                                    >
                                        <FormItem visible={false} />
                                    </Column>
                                    <Column caption="Estimat" minWidth="10em" cssClass="vertical-center" alignment="center"
                                        calculateCellValue={function (rowData) { return getBillEstimatedAmmountForMonth(item.date, rowData); }}
                                        cellRender={function (e) { return linkCell(e.data, item.date) }}
                                        dataField={"EstimatedMonths/" + item.date.getMonth() + "/" + item.date.getFullYear()}
                                        allowFiltering={false}
                                        allowSorting={false}
                                    >
                                        <FormItem visible={false} />
                                        <Format type="fixedPoint" precision={2} />
                                    </Column>
                                    <Column caption="Facturat" minWidth="10em" cssClass="vertical-center" alignment="center"
                                        calculateCellValue={function (rowData) { return getBillActualAmmountForMonth(item.date, rowData) }}
                                        dataField={"RealisedMonths/" + item.date.getMonth() + "/" + item.date.getFullYear()}
                                        allowFiltering={false}
                                        allowSorting={false}
                                    >
                                        <FormItem visible={false} />
                                        <Format type="fixedPoint" precision={2} />
                                    </Column>
                                    <Column caption="Incasat" minWidth="10em" cssClass="vertical-center" alignment="center"
                                        calculateCellValue={function (rowData) { var a = getBillRecievedAmmountForMonth(item.date, rowData); return a; }}
                                        dataField={"ReceivedMonths/" + item.date.getMonth() + "/" + item.date.getFullYear()}
                                        allowFiltering={false}
                                        allowSorting={false}
                                    >
                                        <FormItem visible={false} />
                                        <Format type="fixedPoint" precision={2} />
                                    </Column>
                                </Column>
                            )
                        })
                    }
                </DataGrid>
                {this.renderAuditPopup(this.state.EntryId, this.state.Page)}
            </Fragment>
        )
    }

    componentDidMount() {
        this.setState({
            StartDate: this.props.StartDate,
            EndDate: this.props.EndDate
        })

        this.dateInterval = getDateInterval(this.props.StartDate, this.props.EndDate)
    }

    componentDidUpdate(prevProps) {
        const { StartDate, EndDate } = prevProps;
        if (StartDate != this.props.StartDate || EndDate != this.props.EndDate) {
            this.setState({
                StartDate: this.props.StartDate,
                EndDate: this.props.EndDate
            })
            this.dateInterval = getDateInterval(this.props.StartDate.valueOf(), this.props.EndDate.valueOf())
        }
    }
}

export default BillsGrid;