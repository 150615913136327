import React, { Component } from 'react';
import { GetContractsDataSource } from "../Projects/_DataSources";
import { validatePhoneNumber, onRowUpdating, isNotEmpty, handleErrors, isAuditVisible } from "Utils/Utils";
import { SelectBox } from 'devextreme-react';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

const dataSourceOptions = new DataSource({
    paginate: true,
    store: new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            let params = "?";
            [
                "skip",
                "take",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });
            
            params = params.slice(0, -1);

            return fetch(`/api/contracts${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    };
                })
                .catch(err => err);
        },
        byKey: (key) => {
            return fetch('/api/contracts/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })

        }

    })
    
});

function displayContract(item) {
    // "item" can be null
    return item && `${item.CompanyName} / ${item.Contract}`;
}


export class ContractsDropdown extends Component {
    constructor(props) {
        super(props);
        
    }

    render() {
        return(
            <SelectBox 
                width={400} 
                placeholder="Select a contract..."
                dataSource={dataSourceOptions}
                searchEnabled={true}
                displayExpr={displayContract}
            />
        )
    }
}