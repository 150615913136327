import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import roMessages from "./../../../ro.json";
import CustomStore from 'devextreme/data/custom_store';
import { locale, loadMessages } from "devextreme/localization";
import { handleErrors, onRowUpdating, isNotEmpty, isAuditVisible, multiLineCell } from "Utils/Utils";
import SubMeasureLevel from './SubMeasureLevel/_SubMeasureLevel';
import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import TextArea from 'devextreme-react/text-area';
import DataGrid, {
    Button,
    Column,
    Editing,
    RequiredRule,
    SearchPanel,
    Scrolling,
    FilterRow,
    MasterDetail,
    FormItem,
    Form,
    Popup
} from 'devextreme-react/data-grid';
import Sidebar from '../../Nomenclatoare/_Sidebar';

const dataSourceOptions = new CustomStore({
    key: "Id",
    load: (loadOptions) => {
        let params = "?";
        [
            "skip",
            "take",
            "requireTotalCount",
            "sort",
            "filter"
        ].forEach(function (i) {
            if (i in loadOptions && isNotEmpty(loadOptions[i]))
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        });

        params = params.slice(0, -1);

        return fetch(`/measures${params}`)
            .then(handleErrors)
            .then(response => response.json())
            .then(response => {
                return {
                    data: response.data,
                    totalCount: response.totalCount,
                    summary: response.summary,
                    groupCount: response.groupCount
                };
            })
            .catch(err => err);
    },
    byKey: (key) => {
        return fetch('/measures/' + key)
            .then(response => response.json())
            .then(response => {
                return response
            })

    },
    insert: (values) => {
        return fetch("/measures", {
            method: "POST",
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    },
    update: (key, values) => {
        return fetch("/measures/" + key, {
            method: "PUT",
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    },
    remove: (key) => {
        return fetch("/measures/" + key, {
            method: "DELETE",
            body: JSON.stringify({ id: key }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
})

export class MeasureLevel extends Component {
    static displatName = MeasureLevel.name;

    constructor(props) {
        super(props)
        loadMessages(roMessages);
        locale(navigator.language);

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "Measure")
        }

        this.state = {
            EntryId: 0,
            Page: ""
        }

        this.auditProps = {
            EntryId: 0,
            Page: ""
        }

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.renderAuditPopup = (EntryId, Page) => {
            if (EntryId !== 0 && EntryId !== null && EntryId && Page && Page !== "") {
                return <PopupComponent info={{ EntryId: EntryId, Page: Page }} />
            }
        }
    }

    render() {
        return (
            <DataGrid
                width="auto"
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
                wordWrapEnabled={true}
                columnAutoWidth={true}
                onRowUpdating={onRowUpdating}
                dataSource={dataSourceOptions}
                height="auto"
                remoteOperations={true}
            >
                <FilterRow visible={true} />
                <Scrolling columnRenderingMode="virtual" mode="infinite" scrollByThumb={true} />
                <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Cautare..." />
                <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} mode="popup" useIcons={true}>
                    <Form colCount={1} title="Dimensiune" />
                    <Popup title="Dimensiune" showTitle={true} minWidth="20em" maxWidth="40em" height="auto" />
                </Editing>
                <MasterDetail
                    enabled={true}
                    component={props => {
                        return (
                            <SubMeasureLevel measureId={props.data.key} auditEntry={this.setAuditEntry} />
                        )
                    }}
                />
                <Column type="buttons" cssClass="vertical-center" width="10em">
                    <Button name="edit" cssClass="underlineBtn" />
                    <Button name="delete" cssClass="underlineBtn" />
                    <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                </Column>
                <Column dataField="Id" visible={false} allowEditing={false} showEditorAlways={false}>
                    <FormItem visible={false} />
                </Column>
                <Column dataField="Name" caption="Dimensiune" cssClass="vertical-center" alignment="center" width="15em">
                    <RequiredRule message="Camp obligatoriu!" />
                </Column>
                <Column dataField="InherentRisk" caption="Risc inerent" cssClass="vertical-center" alignment="center" minWidth="20em"
                    cellRender={function (e) { return multiLineCell(e.data.InherentRisk); }} editorType="dxTextArea">
                    <RequiredRule message="Camp obligatoriu!" />
                </Column>
            </DataGrid>
        )
    }
}

export default MeasureLevel;