import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import roMessages from "./../../ro.json";
import Form, { Item, GroupItem } from "devextreme-react/form";
import { locale, loadMessages } from "devextreme/localization";
import { ClientRiskCategories } from "../RiskAssessmentClientDetails/ClientRiskCategories/ClientRiskCategories";
import { ClientRiskMeasures } from "../RiskAssessmentClientDetails/ClientRiskMeasures/ClientRiskMeasures";
import { ClientRiskSubMeasures } from "../RiskAssessmentClientDetails/ClientRiskSubMeasures/ClientRiskSubMeasures";
import { ClientRiskQuestions } from "../RiskAssessmentClientDetails/ClientRiskQuestions/ClientRiskQuestions";
import { RiskScoreByClientId } from "./RiskScoreByClientId/_RiskScoreByClientId";
import { FunctionalExistenceScoreByClientId } from "./FunctionalExistenceScoreByClientId/_FunctionalExistenceScoreByClientId";
import { MeasureRiskScoreByClientId } from "./MeasureRiskScoreByClientId/_MeasureRiskScoreByClientId";
import { MeasureFunctionalExistenceScoreByClientId } from "./MeasureFunctionalExistenceScoreByClientId/_MeasureFunctionalExistenceScoreByClientId";
import { RiskVsFunctionalExistenceByClientId } from "./RiskVsFunctionalExistenceByClientId/_RiskVsFunctionalExistenceByClientId";
import { ScrollView } from 'devextreme-react';
import { data } from 'jquery';

export class RiskAssessmentGraphic extends Component {
    static displayName = RiskAssessmentGraphic.name;

    constructor(props) {
        super(props)
        loadMessages(roMessages);
        locale(navigator.language);

        this.state = {
            redirect: false,
            clientId: 0, 
            measureId: 0,        
            subMeasureId: 0,
            categoryId: 0,
            questionId: 0,
        }

        this.redirect = () => {
            this.setState({
                redirect: true,
                clientId: 0
            })
        }

        this.setMeasureId = (measureId) => {
         
            this.setState({
                measureId: measureId
            })
        }

        this.setSubMeasureId = (subMeasureId) => {
            this.setState({
                subMeasureId: subMeasureId
            })
        }

        this.setCategoryId = (categoryId) => {
        
            this.setState({
                categoryId: categoryId
            })
        }

        this.setQuestionId = (questionId) => {
         
            this.setState({
                questionId: questionId
            })
        }
    }

    render() {
        if (this.state.redirect) {
            return (
                <h1>
                    Nu exista clientId
                </h1>
            )
        }

        return (
            <div className="risk-assessment-graphic-container">
                <ScrollView height="90vh">
                    <Form colCount={1}>
                        <GroupItem colCount={2} colSpan={1} caption="Grafic dimensiuni">

                            <Item>
                                <ClientRiskMeasures clientRiskId={this.state.clientId}  setContainerState={this.setMeasureId} redirect={this.redirect} />
                            </Item>
                            <Item>
                                <ClientRiskSubMeasures clientRiskId={this.state.clientId} measureId={this.state.measureId}  setContainerState={this.setSubMeasureId} redirect={this.redirect} />
                            </Item>
                            <Item>
                                <ClientRiskCategories clientRiskId={this.state.clientId} measureId={this.state.measureId} subMeasureId={this.state.subMeasureId}  setContainerState={this.setCategoryId} redirect={this.redirect} />
                            </Item>
                            <Item>
                                <ClientRiskQuestions clientRiskId={this.state.clientId} measureId={this.state.measureId} subMeasureId={this.state.subMeasureId} categoryId={this.state.categoryId} setContainerState={this.setQuestionId} redirect={this.redirect} />
                            </Item>
                            <Item>
                                <RiskScoreByClientId clientRiskId={this.state.clientId} redirect={this.redirect} />
                            </Item>
                            <Item>
                                <FunctionalExistenceScoreByClientId clientRiskId={this.state.clientId} redirect={this.redirect} />
                            </Item>
                            <Item>
                                <MeasureRiskScoreByClientId clientRiskId={this.state.clientId} measureId={this.state.measureId} redirect={this.redirect} />
                            </Item>
                            <Item>
                                <MeasureFunctionalExistenceScoreByClientId clientRiskId={this.state.clientId} measureId={this.state.measureId} redirect={this.redirect} />
                            </Item>
                            <Item colSpan={2}>
                                <RiskVsFunctionalExistenceByClientId clientRiskId={this.state.clientId} />
                            </Item>
                        </GroupItem>
                    </Form>
                </ScrollView>
            </div>
        )
    }

    


        componentDidMount() {
        var stringId = new URLSearchParams(this.props.location.search.slice(1)).getAll("i")[0]

         
        if (Number.isNaN(parseInt(stringId))) {
            this.setState({
                redirect: true,
                clientId: 0,
                measureId: 0,
                subMeasureId: 0,
                categoryId: 0, 
                questionId: 0
            })
        }
        else {           
            this.setState({
                redirect: false,
                clientId: parseInt(stringId),
              
            })
        }

    }
}