import DataSource from 'devextreme/data/data_source';

export function getParentsTask(id) {
    return fetch('/api/projecttasks?parentId=' + id)
        .then(response => response.json())
        .then(response => {
            return response
        })
}

export const dataSourceTask = new DataSource({
    load: (loadOptions) => {
        return fetch("/../api/projecttasks").then(response => response.json())
            .then(response => {
                return response

            })
    },
    byKey: (key) => {
        return fetch('/api/projecttasks/' + key)
            .then(response => response.json())
            .then(response => {
                return response
            })
    },
    update: (key, values) => {
        return fetch("/api/projecttasks/" + key, {
            method: "PUT",
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json()).then(response => {
            return response

        });
    },
    insert: (values) => {
        return fetch("/api/projecttasks", {
            method: "POST",
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
            .then(response => {
                return response
            })
    },
    remove: (key) => {
        return fetch("/api/projecttasks/" + key, {
            method: "DELETE",
            body: JSON.stringify({ id: key }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
});

export function updateTask(key, values) {
    return fetch("/api/projecttasks/" + key, {
        method: "PUT",
        body: JSON.stringify(values),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(response => response.json()).then(response => {
        return response

    });
}
