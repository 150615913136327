import CustomStore from 'devextreme/data/custom_store';

export function GetServiceCategoryDataSource() {
    return new CustomStore({
        key: "Id",
        load: loadOptions => {
            return fetch("/../api/servicecategories").then(response => response.json())
        },
        byKey: (key) => {
            return fetch('/api/servicecategories/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    })
}

const GetServiceCategoriesByContractId = async (contractId) => {
    const response = await fetch(`/api/contracts/${contractId}/servicecategories`);
    const data = await response.json();

    if (response.state >= 400) {
        throw new Error(data.errors)
    }
    return data;
}
export default GetServiceCategoriesByContractId;

