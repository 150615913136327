import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import DataGrid, {
    Button, Column,
    Editing,
    FilterRow,
    Form, Lookup,
    Popup, RequiredRule,
    SearchPanel,
    Pager, Paging, FormItem
} from 'devextreme-react/data-grid';
import { NumberBox } from 'devextreme-react/number-box';
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import CustomStore from 'devextreme/data/custom_store';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { loadMessages, locale } from "devextreme/localization";
import React, { Component, Fragment } from 'react';
import { isNullOrEmpty, onRowUpdating, isAuditVisible } from "Utils/Utils";
import ContactPersonDropDownBoxComponent from '../Contracts/ContactPersonDropDownBoxComponent';
import { GetServiceCategoryDataSource } from "../Contracts/ContractPurposeData";
import { GetCountyDataSource } from "../Contracts/CountyData";
import fetchExchangeRate from '../Contracts/ExchangeRateByDateData';
import { GetPaymentMethodDataSource } from "../Contracts/PaymentMethodData";
import dataSourceOptions from "../Contracts/ContractsData";
import roMessages from "./../../ro.json";
import ReactDOM from 'react-dom';
import StatusFilterComponent from '../Contracts/StatusFilterComponent'
import config from 'devextreme/core/config';

const DataType = [{ 'Id': true, 'Name': 'Da' }, { 'Id': false, 'Name': 'Nu' }];

const DataStatus = [{ 'Id': true, 'Name': 'Activ' }, { 'Id': false, 'Name': 'Inactiv' }];

function GetOffice(props, value) {
    var selectedValue = props.find(x => x.Id == value);
    let Locality = selectedValue.Locality;
    let Address = selectedValue.Address;
    return Locality.concat(' ', Address);
}

export default class Contracts extends Component {
    static displayName = Contracts.name;

    constructor(props) {
        super(props);
        loadMessages(roMessages);
        locale(navigator.language);
        config({ forceIsoDateParsing: false });
        this.grdRef = React.createRef();

        const statuses = new Map([["cbActive", true], ["cbClosed", false]]);

        this.state = {
            selectedClientEntity: [],
            Office: "",
            PaymentFeeRONValue: null,
            ClientId: null,
            isCustomAdd: false,
            selectedRowData: [],
            EntryId: 0,
            Page: "",
            statusOptions: new Map(statuses),
            dataSourceGrd: dataSourceOptions(statuses),
            isAuthenticated: false,
            user:null
        }

        this.setDataSourceGrd = (options) => {
            this.setState({
                dataSourceGrd: options
            })
        }

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "Contract")
        }

        this.auditProps = {
            EntryId: 0,
            Page: ""
        }

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.renderAuditPopup = (EntryId, Page) => {
            if (EntryId !== 0 && EntryId !== null && EntryId && Page && Page !== "") {
                return <PopupComponent info={{ EntryId: EntryId, Page: Page }} />
            }
        }

        this.clientEditorRender = this.clientEditorRender.bind(this);
        this.officeEditorRender = this.officeEditorRender.bind(this);
        this.paymentFeeRONEditorRender = this.paymentFeeRONEditorRender.bind(this);
        this.paymentFeeEUREditorRender = this.paymentFeeEUREditorRender.bind(this);
        this.onEditingStart = this.onEditingStart.bind(this);
        this.onEditorPreparing = this.onEditorPreparing.bind(this);
        this.statusEditorRender = this.statusEditorRender.bind(this);
        this.contractReceivedEditorRender = this.contractReceivedEditorRender.bind(this);
        this.contractSentEditorRender = this.contractSentEditorRender.bind(this);
        this.settlementOfAdditionalExpensesEditorRender = this.settlementOfAdditionalExpensesEditorRender.bind(this);
        this.onInitNewRow = this.onInitNewRow.bind(this);
        this.onClickAddCustomButon = this.onClickAddCustomButon.bind(this);
        this.onSaving = this.onSaving.bind(this);
        this.onEditCanceled = this.onEditCanceled.bind(this);
        this.isAddCustomIconVisible = this.isAddCustomIconVisible.bind(this);
        this.onEditCanceled = this.onEditCanceled.bind(this);
        this.onRowInserted = this.onRowInserted.bind(this);
        this.allowDeleting = this.allowDeleting.bind(this);
    }

    isAddCustomIconVisible(e) {
        return isNullOrEmpty(e.row.data.AdditionalActNumber);
    }

    ClientsDataSourceOptions = new CustomStore({
        key: "Id",
        load: loadOptions => {
            return fetch("/../client").then(response => response.json())
                .then((data) => {
                    this.setState({
                        selectedClientEntity: data.data
                    });

                    return {
                        data: data.data,
                        totalCount: data.totalCount,
                        summary: data.summary,
                        groupCount: data.groupCount
                    };
                })
                .catch(() => { throw 'Data Loading Error'; });
        },
        byKey: (key) => {
            return fetch('/client/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    });

    onSaving(e) {
        this.setState({ isCustomAdd: false });
    }

    onRowInserted(e) {
        e.component.refresh();
    }

    onInitNewRow(e) {
        e.data.ClientId = null;
        this.state.ClientId = null;

        const info = this.state.selectedRowData;

        if (this.state.isCustomAdd) {
            this.state.ClientId = info.ClientId;
            e.data.Number = info.Number;
            e.data.ClientId = info.ClientId;
            e.data.Office = info.Office;
            e.data.ServiceCategoryId = info.ServiceCategoryId;
            e.data.CountyId = info.CountyId;
            e.data.SettlementOfAdditionalExpenses = info.SettlementOfAdditionalExpenses;
            e.data.ContactPersonId = info.ContactPersonId;
            e.data.ContactPersons = info.ContactPersons;
            e.data.ContractId = info.ContractId;
            e.data.isCustomAdd = this.state.isCustomAdd;
        }
    }

    onEditCanceled(e) {
        this.setState({ isCustomAdd: false });
    }

    onEditingStart(e) {
        if (!isNullOrEmpty(e.data.AdditionalActNumber)) {
            this.setState({ isCustomAdd: true });
            e.data.isCustomAdd = this.state.isCustomAdd;
        }
    }

    onEditorPreparing(e) {
        if (e.parentType === 'dataRow') {
            if (e.dataField === 'Number' || e.dataField == "SettlementOfAdditionalExpenses"
                || e.dataField == "Office" || e.dataField == "ServiceCategoryId" || e.dataField == "CountyId") {
                e.editorOptions.readOnly = this.state.isCustomAdd;
            }

            if (e.dataField == "UniqueId")
                e.editorOptions.readOnly = true;   
        }

        if (!this.state.isCustomAdd)
            if (e.dataField == "AdditionalActNumber")
                e.editorOptions.readOnly = !this.state.isCustomAdd;
    }

    onClientValueChanged(cell, e) {
        cell.setValue(e.value);

        this.setState({
            Office: GetOffice(this.state.selectedClientEntity, e.value),
            ClientId: e.value
        });
    }

    clientEditorRender(cell) {
        let onClientValueChanged = this.onClientValueChanged.bind(this, cell);

        return <SelectBox
            defaultValue={cell.value}
            {...cell.column.lookup}
            readOnly={this.state.isCustomAdd}
            onValueChanged={onClientValueChanged}
        />;
    }

    officeEditorRender(cell) {
        return <TextBox
            id="txtSediu"
            defaultValue={cell.value}
            value={isNullOrEmpty(this.state.Office) ? cell.value : this.state.Office}
            readOnly={true}
            {...cell.column.textbox}
        />;
    }

    paymentFeeEUREditorRender(cell) {

        let onPaymentFeeEURValueChanged = this.onPaymentFeeEURValueChanged.bind(this, cell);

        return <NumberBox
            defaultValue={cell.value}
            showSpinButtons={false}
            format="#,##0.00"
            onValueChanged={onPaymentFeeEURValueChanged}
            {...cell.column.numberbox}
        />;
    }

    onPaymentFeeEURValueChanged(cell, e) {
        this.setState({
            PaymentFeeEURValue: e.value
        });

        fetchExchangeRate().then(value => this.setState({ PaymentFeeRONValue: value.Value * e.value }));

        cell.setValue(e.value);
    }

    paymentFeeRONEditorRender(cell) {

        var paymentFeeRONValue = isNullOrEmpty(this.state.PaymentFeeRONValue) ? cell.value : this.state.PaymentFeeRONValue;
        cell.setValue(paymentFeeRONValue);

        return <NumberBox
            id="nbPaymentFeeRON"
            defaultValue={cell.value}
            showSpinButtons={false}
            format="#,##0.00"
            readOnly={true}
            value={paymentFeeRONValue}
            {...cell.column.numberbox}
            onValueChanged={this.onValueChanged}
        />;
    }

    statusEditorRender(cell) {
        return <SelectBox
            dataSource={DataStatus}
            valueExpr="Id"
            displayExpr="Name"
            onValueChanged={(e) => cell.setValue(e.value)}
            defaultValue={cell.value}
        />;
    }

    contractReceivedEditorRender(cell) {
        return <SelectBox
            dataSource={DataType}
            valueExpr="Id"
            displayExpr="Name"
            onValueChanged={(e) => cell.setValue(e.value)}
            defaultValue={cell.value}
        />;
    }

    contractSentEditorRender(cell) {
        return <SelectBox
            dataSource={DataType}
            valueExpr="Id"
            displayExpr="Name"
            onValueChanged={(e) => cell.setValue(e.value)}
            defaultValue={cell.value}
        />;
    }

    settlementOfAdditionalExpensesEditorRender(cell) {
        return <SelectBox
            dataSource={DataType}
            valueExpr="Id"
            displayExpr="Name"
            readOnly={this.state.isCustomAdd}
            onValueChanged={(e) => cell.setValue(e.value)}
            defaultValue={cell.value}
        />;
    }

    onClickAddCustomButon(e) {
        this.setState({
            isCustomAdd: true,
            selectedRowData: e.row.data
        });

        e.component.addRow();
    }

    allowDeleting(e) {
        return e.row.data.HasDataBill;
    };

    render() {
        return (
            <Fragment>

                <StatusFilterComponent sendRefGrid={this.grdRef} getDataSource={this.setDataSourceGrd} />

                <DataGrid
                    id="contractGrid"
                    ref={this.grdRef}
                    dataSource={this.state.dataSourceGrd}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    wordWrapEnabled={true}
                    columnAutoWidth={true}
                    width="auto"
                    height="85vh"
                    remoteOperations={true}
                    onRowUpdating={onRowUpdating}
                    onEditingStart={this.onEditingStart}
                    onEditorPreparing={this.onEditorPreparing}
                    onInitNewRow={this.onInitNewRow}
                    onRowInserting={this.onRowInserting}
                    onSaving={this.onSaving}
                    onEditCanceled={this.onEditCanceled}
                    onRowInserted={this.onRowInserted}
                >
                    <FilterRow visible={true} />
                    <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Cautare..." />
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true} showInfo={true} infoText="Pagina {0} din {1} ({2} inregistrari)" />

                    <Editing allowAdding={true} allowUpdating={true} allowDeleting={this.allowDeleting} mode="popup" useIcons={true} >
                        <Form colCount={1} title="Contracte" />
                        <Popup title="Contracte" showTitle={true} />
                    </Editing>

                    <Column type="buttons" cssClass="vertical-center" minWidth="10em">
                        <Button name="edit" cssClass="underlineBtn" />
                        <Button name="delete" cssClass="underlineBtn" />

                        <Button visible={this.isAddCustomIconVisible}
                            icon="add"
                            hint="Adauga act aditional"
                            onClick={this.onClickAddCustomButon}
                        />

                        <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />

                    </Column>

                    <Column dataField="UniqueId" caption="Identificator unic client" cssClass="vertical-center" alignment="center" minWidth="10em" readOnly={true}>
                    </Column>

                    <Column dataField="Number" caption="Nr. contract" cssClass="vertical-center" alignment="center" minWidth="10em">
                        <RequiredRule message="Camp obligatoriu!" />
                    </Column>

                    <Column dataField="AdditionalActNumber" caption="Nr. act aditional" cssClass="vertical-center" alignment="center" minWidth="10em">
                    </Column>

                    <Column dataField="Date" caption="Data" cssClass="vertical-center" alignment="center" minWidth="10em" dataType="date" format="dd.MM.yyyy"
                    >
                        <RequiredRule message="Camp obligatoriu!" />
                    </Column>

                    <Column dataField="Year" caption="An" cssClass="vertical-center" alignment="center" minWidth="10em" dataType="number"
                        readOnly={true} allowEditing={false} >
                        <FormItem visible={false} />
                    </Column>

                    <Column dataField="ClientId" caption="Client" cssClass="vertical-center" alignment="center" minWidth="10em" editCellRender={this.clientEditorRender}
                        allowMerging={true}>
                        <Lookup dataSource={this.ClientsDataSourceOptions} valueExpr="Id" displayExpr="Name" />
                        <RequiredRule message="Camp obligatoriu!" />
                    </Column>

                    <Column dataField="Office" caption="Sediu" cssClass="vertical-center" alignment="center" minWidth="10em" editCellRender={this.officeEditorRender} readOnly={true}>
                    </Column>

                    <Column dataField="ServiceCategoryId" caption="Categorie servicii" cssClass="vertical-center" alignment="center" minWidth="10em">
                        <Lookup dataSource={GetServiceCategoryDataSource} valueExpr="Id" displayExpr="Name" />
                        <RequiredRule message="Camp obligatoriu!" />
                    </Column>

                    <Column dataField="ContractPurpose" caption="Obiect contract" cssClass="vertical-center" alignment="center" minWidth="10em">
                    </Column>

                    <Column dataField="CountyId" caption="Locatie" cssClass="vertical-center" alignment="center" minWidth="10em">
                        <Lookup dataSource={GetCountyDataSource} valueExpr="Id" displayExpr="Name" />
                        <RequiredRule message="Camp obligatoriu!" />
                    </Column>

                    <Column dataField="SettlementOfAdditionalExpenses" caption="Decontare alte cheltuieli" cssClass="vertical-center" alignment="center" minWidth="10em"
                        editCellRender={this.settlementOfAdditionalExpensesEditorRender}   >
                        <Lookup dataSource={DataType} valueExpr="Id" displayExpr="Name" />
                    </Column>

                    <Column dataField="PaymentFeeEUR" caption="Onorariu (EUR)" cssClass="vertical-center" alignment="center" minWidth="10em" format="#,##0.00" dataType="number"
                        editCellRender={this.paymentFeeEUREditorRender}>
                        <RequiredRule message="Camp obligatoriu!" />
                    </Column>

                    <Column dataField="PaymentFeeRON" caption="Onorariu (RON)" cssClass="vertical-center" alignment="center" minWidth="10em" format="#,##0.00" dataType="number"
                        editCellRender={this.paymentFeeRONEditorRender}>
                        <RequiredRule message="Camp obligatoriu!" />
                    </Column>

                    <Column dataField="ContractSent" caption="Trimis contract" cssClass="vertical-center" alignment="center" minWidth="10em"
                        editCellRender={this.contractSentEditorRender}                    >
                        <Lookup dataSource={DataType} valueExpr="Id" displayExpr="Name" />
                    </Column>

                    <Column dataField="ContractReceived" caption="Primit contract" cssClass="vertical-center" alignment="center" minWidth="10em"
                        editCellRender={this.contractReceivedEditorRender}>
                        <Lookup dataSource={DataType} valueExpr="Id" displayExpr="Name" />
                    </Column>

                    <Column dataField="PaymentMethodId" caption="Modalitate de plata" cssClass="vertical-center" alignment="center" minWidth="10em">
                        <Lookup dataSource={GetPaymentMethodDataSource} valueExpr="Id" displayExpr="Payment" />
                        <RequiredRule message="Camp obligatoriu!" />
                    </Column>

                    <Column dataField="Status" caption="Status contract" cssClass="vertical-center" alignment="center" minWidth="10em" editCellRender={this.statusEditorRender}>
                        <Lookup dataSource={DataStatus} valueExpr="Id" displayExpr="Name" />
                    </Column>

                    <Column dataField="ContactPersons" caption="Persoane de contact" cssClass="vertical-center" alignment="left" minWidth="15em"
                        editCellComponent={ContactPersonDropDownBoxComponent} encodeHtml={false}
                    >

                    </Column>

                </DataGrid>

            </Fragment >
        );
    }
}
