import React, { Component } from "react";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import roMessages from "./../../../ro.json";
import { locale, loadMessages } from "devextreme/localization";
import { Chart, Series, CommonSeriesSettings, Label, Format, Legend, ConstantLine, ValueAxis, VisualRange } from 'devextreme-react/chart';


export class RiskVsFunctionalExistenceByClientId extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clientRiskId: 0,
            dataSource: null
        }

        loadMessages(roMessages);
        locale(navigator.language);

        this.onPointClick = (e) => { e.target.select(); }
    }

    render() {
        return (
            <Chart dataSource={this.state.dataSource} onPointClick={this.onPointClick}>
                <CommonSeriesSettings
                    argumentField="state"
                    type="bar"
                    hoverMode="allArgumentPoints"
                    selectionMode="allArgumentPoints"
                >
                    <Label visible={true}>
                        <Format type="fixedPoint" precision={0} />
                    </Label>
                </CommonSeriesSettings>
                <Series
                    argumentField="Measure"
                    valueField="FunctionalExistenceScore"
                    name="Existenta & functionalitate"
                    color="#4472C4"
                />
                <Series
                    argumentField="Measure"
                    valueField="RiskScore"
                    name="Risc"
                    color="#ED7D31"
                />
                <ValueAxis>
                    <VisualRange endValue={6} startValue={0} />
                    <ConstantLine
                        width={5}
                        value={1}
                        color="green"
                    >
                        <Label text="Functional/Scazut" />
                    </ConstantLine>
                    <ConstantLine
                        width={5}
                        value={5}
                        color="red"
                    >
                        <Label text="Inexistent/Critic" />
                    </ConstantLine>
                </ValueAxis>
                <Legend verticalAlignment="bottom" horizontalAlignment="center"></Legend>
            </Chart>
        )
    }

    async componentDidMount(props) {
        const { clientRiskId } = this.props;
        var serverResponse = await fetch(`/api/clients/${clientRiskId}/comparison`)
        var dataSource = await serverResponse.json()
        this.setState({
            clientRiskId: clientRiskId,
            dataSource: dataSource
        })
    }
}