import React, { Component, Fragment } from 'react';
import { onRowUpdating, isAuditVisible } from "Utils/Utils";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import roMessages from "./../../ro.json";
import { locale, loadMessages } from "devextreme/localization";
import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import { TreeList, FilterRow, RemoteOperations, Column, SearchPanel, HeaderFilter, Editing, RequiredRule, Texts, FilterPanel, Button } from 'devextreme-react/tree-list';
import Sidebar from "./_Sidebar";
import { dataSourceOptions } from 'components/Nomenclatoare/_ActivityDataSource'

export class Activity extends Component {
    static displayName = Activity.name;
    constructor(props) {
        super(props)
        loadMessages(roMessages);
        locale(navigator.language)
        this.treeListRef = React.createRef();
        this.state = {
            EntryId: 0
        }
        this.setAuditEntry = (e) => {
            this.setState({ EntryId: e.row.key });
        }

        this.renderAuditPopup = (EntryId) => {
            if (EntryId !== 0 && EntryId !== null && EntryId) {
                sessionStorage.setItem("showAudit", true)
                return <PopupComponent info={{ EntryId: EntryId, Page: "Activity" }} />
            }
        }
    }

    get treeList() {
        return this.treeListRef.current.instance;
    }

    render() {
        return (
            <Fragment>
                <TreeList
                    id="id"
                    dataSource={dataSourceOptions}
                    parentIdExpr="ParentId"
                    keyExpr="Id"
                    hasItemsExpr="HasItems"
                    showRowLines={true}
                    showBorders={true}
                    columnAutoWidth={true}
                    wordWrapEnabled={true}
                    searchEnabled={true}
                    ref={this.treeListRef}
                    onRowUpdating={onRowUpdating}
                >
                    <RemoteOperations filtering={true} sorting={true} grouping={true} />
                    <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Cautare..." />
                    <HeaderFilter visible={true} />
                    <FilterRow visible={true} />
                    <FilterPanel visible={true} />
                    <Editing
                        mode="row"
                        allowAdding={true}
                        allowUpdating={true}
                        allowDeleting={true}
                        useIcons={true}
                        confirmDelete={true}>
                        <Texts
                            deleteRow="Sterg"
                            addRow="Adaug"
                            editRow="Modific"
                            confirmDeleteMessage="Confirmati stergerea inregistrarii?"
                        />
                    </Editing>
                    <Column type="buttons" width={120}>
                        <Button name="edit" />
                        <Button name="delete" />
                        <Button name="add" />
                        <Button icon="info" hint="Audit" onClick={this.setAuditEntry} visible={isAuditVisible} />
                    </Column>
                    <Column dataField="Code" caption="Cod">
                        <RequiredRule />
                    </Column>
                    <Column dataField="Name" caption="Nume" minWidth={250}>
                        <RequiredRule />
                    </Column>
                </TreeList>
                {this.renderAuditPopup(this.state.EntryId)}
            </Fragment >
        );
    }
}

