import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import roMessages from "./../../ro.json";
import CustomStore from 'devextreme/data/custom_store';
import { locale, loadMessages } from "devextreme/localization";
import SelectBox from 'devextreme-react/select-box'
import MeasuresDropDownComponent from '../RiskAssesmentClient/MeasuresDropDownComponent';
import { handleErrors, onRowUpdating, isNotEmpty, isAuditVisible } from "Utils/Utils";
import DataGrid, {
    Form,
    Popup,
    Button,
    Column,
    Editing,
    FormItem,
    RequiredRule,
    SearchPanel,
    Scrolling,
    FilterRow,
    Lookup

} from 'devextreme-react/data-grid';

import { withRouter } from "react-router";

const dataSourceOptions = new CustomStore({
    key: "Id",
    load: (loadOptions) => {
        let params = "?";
        [
            "skip",
            "take",
            "requireTotalCount",
            "sort",
            "filter"
        ].forEach(function (i) {
            if (i in loadOptions && isNotEmpty(loadOptions[i]))
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        });

        params = params.slice(0, -1);

        return fetch(`/api/clientRisks${params}`)
            .then(handleErrors)
            .then(response => response.json())
            .then(response => {
                return {
                    data: response.data,
                    totalCount: response.totalCount,
                    summary: response.summary,
                    groupCount: response.groupCount
                };
            })
            .catch(err => err);
    },
    byKey: (key) => {
        return fetch("/api/clientrisks/" + key)
            .then(response => response.json())
            .then(response => {
                return response;
            })
    },
    insert: (values) => {
        return fetch("/api/clientrisks", {
            method: "POST",
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    },
    update: (key, values) => {
        return fetch("/api/clientrisks/" + key, {
            method: "PUT",
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    },
    remove: (key) => {
        return fetch("/api/clientrisks/" + key, {
            method: "DELETE",
            body: JSON.stringify({ id: key }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
})

 class ClientRisk extends Component {

    static displayName = ClientRisk.name;
   
    constructor(props) {
        super(props);
        loadMessages(roMessages);
        locale(navigator.language)
        this.grdRef = React.createRef();

        this.state = {
            EntryId: 0,
            selectedClientEntity: [],
            selectedRowData: []
        }

        this.clientEditorRender = this.clientEditorRender.bind(this);
        this.isClientRedirect = this.isClientRedirect.bind(this);
    }

    isClientRedirect(e) {
        let queryString = "i=" + e.row.data.Id;
        console.log(this.props.history);//for test production
        this.props.history.push(`/Grafic/?${queryString}`);
    }

    ClientsDataSourceOptions = new CustomStore({
        key: "Id",
        load: loadOptions => {
            return fetch("/../client").then(response => response.json())
                .then((data) => {
                    this.setState({
                        selectedClientEntity: data.data
                    });

                    return {
                        data: data.data,
                        totalCount: data.totalCount,
                        summary: data.summary,
                        groupCount: data.groupCount
                    };
                })
                .catch(() => { throw 'Data Loading Error'; });
        },
        byKey: (key) => {
            return fetch('/client/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    });

    clientEditorRender(cell) {
        let onClientValueChanged = this.onClientValueChanged.bind(this, cell);

        return <SelectBox
            defaultValue={cell.value}
            {...cell.column.lookup}
            onValueChanged={onClientValueChanged}
        />;
    }

    onClientValueChanged(cell, e) {
        cell.setValue(e.value);
    }

    render() {
        return (
            <DataGrid
                id="clientRiskGrid"
                ref={this.grdRef}
                dataSource={dataSourceOptions}
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
                wordWrapEnabled={true}
                columnAutoWidth={true}
                width="auto"
                height="90vh"
                onEditorPreparing={this.onEditorPreparing}
                onRowUpdating={onRowUpdating}
            >
                <FilterRow visible={true} />
                <Scrolling columnRenderingMode="virtual" mode="standard" scrollByThumb={true} />
                <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Cautare..." />
                <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} mode="popup" useIcons={true}>
                    <Form colCount={1} title="Completitudine client" />
                    <Popup title="Completitudine client" showTitle={true} />
                </Editing>


                <Column type="buttons" cssClass="vertical-center" minWidth="10em">
                    <Button name="edit" cssClass="underlineBtn" />
                    <Button name="delete" cssClass="underlineBtn" />

                    <Button
                        icon="link"
                        hint="Detalii"
                        onClick={this.isClientRedirect}
                    />

                </Column>
                <Column dataField="Id" visible={false} allowEditing={false} showEditorAlways={false}>
                    <FormItem visible={false} />
                </Column>
                <Column dataField="ClientId" caption="Client" cssClass="vertical-center" alignment="center" minWidth="10em" editCellRender={this.clientEditorRender}
                    allowMerging={true}>
                    <Lookup dataSource={this.ClientsDataSourceOptions} valueExpr="Id" displayExpr="Name" />
                    <RequiredRule message="Camp obligatoriu!" />
                </Column>
                <Column dataField="StartDate" caption="Data" cssClass="vertical-center" alignment="center" minWidth="10em" dataType="date" format="dd.MM.yyyy">
                    <RequiredRule message="Camp obligatoriu!" />
                </Column>

                <Column dataField="Measures" caption="Dimensiuni" cssClass="vertical-center" alignment="left" minWidth="15em"
                    editCellComponent={MeasuresDropDownComponent} encodeHtml={false}
                >

                </Column>

            </DataGrid>
        )
    }


}
export default withRouter(ClientRisk);
