import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import OfferStatuses from './OffersStatuses';
import { getoffersDataSource, getClientTypeDataSource, getOfferStatuses, getClientsDataSource } from "./OffersData";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import roMessages from "./../../ro.json";
import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import { onRowUpdating, isAuditVisible } from "Utils/Utils";
import { locale, loadMessages } from "devextreme/localization";
import './OffersStyle.css'
import DataGrid, {
    Button,
    Column,
    Editing,
    RequiredRule,
    SearchPanel,
    Lookup,
    Scrolling,
    FilterRow,
    FormItem,
    Form,
    Popup
} from 'devextreme-react/data-grid';

const DisableOptions = {
    height: 120,
}

export default class OffersGrid extends Component {

    constructor(props) {
        super(props)
        loadMessages(roMessages);
        locale(navigator.language)

        this.offersGrdRef = React.createRef();

        const offerStatuses = new Map([["cbBidder", true], ["cbAccepted", false], ["cbRefused", false], ["cbDeleted", false]])


        this.state = {
            EntryId: 0,
            Page: "",
            clientNew: false,
            isNewClient: false,
            statusOptions: new Map(offerStatuses),
            offersDataSourceOptions: getoffersDataSource(offerStatuses)
        }

        this.auditProps = {
            EntryId: 0,
            Page: ""
        }

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.renderAuditPopup = (EntryId, Page) => {
            if (EntryId !== 0 && EntryId !== null && EntryId && Page && Page !== "") {
                return <PopupComponent info={{ EntryId: EntryId, Page: Page }} />
            }
        }

        this.setOffers = (options) => {
            this.setState({
                offersDataSourceOptions: options,
            })
        }

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "Offers")
        }

        this.getOffers = () => {
            return this.state.offersDataSourceOptions;
        }
    }




    render() {
        return (
            <Fragment>

                <OfferStatuses sendRefGrid={this.offersGrdRef} getDataSource={this.setOffers} />

                <DataGrid
                    id="grid-offers"
                    dataSource={this.state.offersDataSourceOptions}
                    showBorders={true}
                    ref={this.offersGrdRef}
                    remoteOperations={true}
                    onRowUpdating={onRowUpdating}
                    columnAutoWidth={true}
                    width="auto"
                    height="auto"
                    showColumnLines={true}
                    showRowLines={true}
                    wordWrapEnabled={true}

                >
                    <FilterRow visible={true} />
                    <Scrolling columnRenderingMode="virtual" mode="infinite" scrollByThumb={true} />
                    <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Cautare..." />
                    <Editing allowAdding={true} allowDeleting={true} allowUpdating={true} mode="popup" useIcons={true}>
                        <Form colCount={1} title="Oferte" />
                        <Popup title="Oferte" showTitle={true} />
                    </Editing>
                    <Column type="buttons" cssClass="vertical-center" minWidth="5em">
                        <Button name="edit" cssClass="underlineBtn" />
                        <Button name="delete" cssClass="underlineBtn" />
                        <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                    </Column>
                    <Column dataField="Id" visible={false} allowEditing={false} showEditorAlways={false}>
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="ClientTypeId" caption="Tip client" cssClass="vertical-center" allowEditing={true} alignment="center" showEditorAlways={false}>
                        <FormItem visible={false} />
                        <Lookup dataSource={getClientTypeDataSource} displayExpr="Name" valueExpr="Id" />
                    </Column>
                    <Column dataField="ClientId" caption="Client" cssClass="vertical-center" allowEditing={true} alignment="center" showEditorAlways={false}>
                        <Lookup dataSource={getClientsDataSource} displayExpr="Name" valueExpr="Id" />
                    </Column>
                    <Column dataField="ToDo" caption="ToDo" cssClass="vertical-center" alignment="center" >

                        <FormItem editorOptions={DisableOptions} />
                    </Column>
                    <Column dataField="StatusId" caption="Status" cssClass="vertical-center" allowEditing={true} alignment="center" showEditorAlways={false}>
                        <RequiredRule message="Camp obligatoriu!" />
                        <Lookup dataSource={getOfferStatuses} displayExpr="Name" valueExpr="Id" />

                    </Column>
                </DataGrid>

            </Fragment>
        );
    }
}