import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import roMessages from "./../../ro.json";
import Form, { Item, GroupItem } from "devextreme-react/form";
import { locale, loadMessages } from "devextreme/localization";
import { ProjectTask } from './_ProjectTask';
import { Fragment } from 'react';

export class ProjectTaskComponent extends Component {
    static displayName = ProjectTaskComponent.name;

    constructor(props) {
        super(props)
        loadMessages(roMessages);
        locale(navigator.language);
    }

    render() {
        return (
            <Fragment>
                <Form colCount={1}>
                    <GroupItem caption="Task-uri">
                        <Item>
                            <ProjectTask />
                        </Item>
                    </GroupItem>
                </Form>
                <div id="taskEditPopupContainer" />
            </Fragment>
        )
    }
}

export default ProjectTaskComponent;