import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import roMessages from "./../../../../ro.json";
import CustomStore from 'devextreme/data/custom_store';
import { locale, loadMessages } from "devextreme/localization";
import { handleErrors, onRowUpdating, isNotEmpty, isAuditVisible, multiLineCell } from "Utils/Utils";
import TextArea from 'devextreme-react/text-area';
import DataGrid, {
    Button,
    Column,
    Editing,
    RequiredRule,
    SearchPanel,
    Scrolling,
    FilterRow,
    FormItem,
    Form,
    Popup
} from 'devextreme-react/data-grid';

export class SubMeasureLevel extends Component {
    static displatName = SubMeasureLevel.name;

    constructor(props) {
        super(props)
        loadMessages(roMessages);
        locale(navigator.language);
        this.state = {
            measureId: props.measureId
        };
        this.setAuditEntry = props.auditEntry;
        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "SubMeasure")
        }
    }

    render() {
        return (
            <DataGrid
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
                wordWrapEnabled={true}
                columnAutoWidth={true}
                onRowUpdating={onRowUpdating}
                dataSource={this.dataSourceOptions}
                className="risk-assessment-master-detail"
                width="84vw"
                remoteOperations={true}
            >
                <FilterRow visible={true} />
                <Scrolling columnRenderingMode="virtual" mode="infinite" scrollByThumb={true} />
                <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Cautare..." />
                <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} mode="popup" useIcons={true}>
                    <Form colCount={1} title="Subdimensiune" />
                    <Popup title="Subdimensiune" showTitle={true} minWidth="20em" maxWidth="40em" height="auto"/>
                </Editing>
                <Column type="buttons" cssClass="vertical-center" width="10em">
                    <Button name="edit" cssClass="underlineBtn" />
                    <Button name="delete" cssClass="underlineBtn" />
                    <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                </Column>
                <Column dataField="Id" visible={false} allowEditing={false} showEditorAlways={false}>
                    <FormItem visible={false} />
                </Column>
                <Column dataField="Name" caption="Subdimensiune" cssClass="vertical-center" alignment="center" width="15em">
                    <RequiredRule message="Camp obligatoriu!" />
                </Column>
                <Column dataField="InherentRisk" caption="Risc inerent" cssClass="vertical-center" alignment="center" minWidth="10em"
                    cellRender={function (e) { return multiLineCell(e.data.InherentRisk); }} editorType="dxTextArea">
                    <RequiredRule message="Camp obligatoriu!" />
                </Column>
            </DataGrid>
        )
    }

    dataSourceOptions = new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/measures/${this.state.measureId}/submeasures${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    };
                })
                .catch(err => err);
        },
        byKey: (key) => {
            return fetch('/submeasures/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })

        },
        insert: (values) => {
            values.MeasureId = this.state.measureId;
            return fetch("/submeasures", {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
        },
        update: (key, values) => {
            return fetch("/submeasures/" + key, {
                method: "PUT",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
        },
        remove: (key) => {
            return fetch("/submeasures/" + key, {
                method: "DELETE",
                body: JSON.stringify({ id: key }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        }
    })
}

export default SubMeasureLevel;
