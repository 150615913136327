import CustomStore from 'devextreme/data/custom_store';

import { isNotEmpty, handleErrors } from "Utils/Utils";

export function getClientRisksSubMeasureData(clientRiskId, measureId) {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/clientRisks/${clientRiskId}/measures/${measureId}/submeasures${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {

                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    };
                })
                .catch(err => err);
        },
        byKey: (key) => {
            return fetch(`/api/clientsubmeasures` + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })

        },
        update: (key, values) => {
            return fetch("/api/clientsubmeasures/" + key, {
                method: "PUT",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
        }
    })
}


export function getClientRiskCategoriesData(clientRiskId, measureId, subMeasureId) {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/clientRisks/${clientRiskId}/measures/${measureId}/subMeasures/${subMeasureId}/categories${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    };
                })
                .catch(err => err);
        },
        byKey: (key) => {
            return fetch(`/api/clientcategoryrisks` + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })

        },
        update: (key, values) => {
            return fetch("/api/clientcategoryrisks/" + key, {
                method: "PUT",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
        }
    })

}


export function getClientRiskQuestionsData(clientRiskId, measureId, subMeasureId, categoryId) {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/clientRisks/${clientRiskId}/measures/${measureId}/subMeasures/${subMeasureId}/categories/${categoryId}/questions`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    };
                })
                .catch(err => err);
        },
        byKey: (key) => {
            return fetch(`/api/clientCategoryquestions` + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })

        },
        update: (key, values) => {
            return fetch("/api/clientCategoryquestions/" + key, {
                method: "PUT",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
        }
    })

}