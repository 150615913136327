import React from 'react';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import PersoaneContact from './MasterDetailComponents/_ContactPersons';
import Associates from './MasterDetailComponents/_Associates';
import Administrators from './MasterDetailComponents/_Administrators';
import RealBeneficiaries from './MasterDetailComponents/_RealBeneficiaries';
import BillingData from './MasterDetailComponents/_BillingData';
import MeetingJournal from './MasterDetailComponents/_MeetingJournal';
import Workstation from './MasterDetailComponents/_Workstations';

class ClientsMasterDetail extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            Page: "",
            EntryId: 0
        }
        this.setAuditEntry = (Key, Page) => {
            this.setState({
                EntryId: Key,
                Page: Page
            })
        }
        this.renderAuditPopup = (EntryId, Page) => {
            if (EntryId !== 0 && EntryId !== null && EntryId && Page && Page !== "") {
                return <PopupComponent info={{ EntryId: EntryId, Page: Page }} state={this.state}/>
            }
        }
        this.renderContactPersons = () => { return <PersoaneContact clientId={this.props.info.data.key} func={this.setAuditEntry} /> }
        this.renderAssociates = () => { return <Associates clientId={this.props.info.data.key} func={this.setAuditEntry}/> }
        this.renderAdministrators = () => { return <Administrators clientId={this.props.info.data.key} func={this.setAuditEntry}/> }
        this.renderRealBeneficiaries = () => { return <RealBeneficiaries clientId={this.props.info.data.key} func={this.setAuditEntry}/> }
        this.renderBillingData = () => { return <BillingData clientId={this.props.info.data.key} func={this.setAuditEntry}/> }
        this.renderMeetingsJournal = () => { return <MeetingJournal clientId={this.props.info.data.key} func={this.setAuditEntry} /> }
        this.renderWorkstations = () => { return <Workstation clientId={this.props.info.data.key} func={this.setAuditEntry} /> }
    }

    render() {
        return (
            <div>
                <TabPanel width="95vw">
                    <Item title="Asociati" render={this.renderAssociates} />
                    <Item title="Administratori" render={this.renderAdministrators} />
                    <Item title="Beneficiari reali" render={this.renderRealBeneficiaries} />
                    <Item title="Persoane de contact" render={this.renderContactPersons} />
                    <Item title="Date de facturare" render={this.renderBillingData} />
                    <Item title="Jurnal intalniri" render={this.renderMeetingsJournal} />
                    <Item title="Puncte de lucru" render={this.renderWorkstations} />
                </TabPanel>
                {this.renderAuditPopup(this.state.EntryId, this.state.Page)}
            </div>
        )
    }

    componentDidUpdate(prevProps) {
        //if (this.props.info.Page !== info.Page && this.props.info.EntryId !== info.EntryId) {
        //    this.setState({
        //        Page: this.props.info.Page,
        //        EntryId: this.props.info.EntryId
        //    })
        //}
    }
}

export default ClientsMasterDetail;