import CustomStore from 'devextreme/data/custom_store';
import { isNotEmpty, handleErrors } from "Utils/Utils";

export function getEmployeesDataSource() {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/employees${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return response.data;
                })
                .catch(err => err);
        },
        byKey: (key) => {
            return fetch('/api/employees/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })

        }
    })
}

export function getClientsDataSource() {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/Client${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    };
                })
                .catch(err => err);
        },
        byKey: (key) => {
            return fetch('/client/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    });
}