import React, { Component, Fragment } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import roMessages from "./../../ro.json";
import Form, { Item, GroupItem } from "devextreme-react/form";
import { locale, loadMessages } from "devextreme/localization";
import OffersGrid from './_OffersGrid';

export class Offers extends Component {

    constructor(props) {
        super(props)
        loadMessages(roMessages);
        locale(navigator.language);
    }

    render() {
        return (
            <Fragment>
                <Form colCount={1}>
                    <GroupItem caption="Oferte">
                        <Item>
                            <OffersGrid />
                        </Item>
                    </GroupItem>
                </Form>
                <div id="auditPopupContainer" />
            </Fragment>
        )
    }
}

export default Offers;