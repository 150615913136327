import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import roMessages from "./../../../ro.json";
import CustomStore from 'devextreme/data/custom_store';
import { locale, loadMessages } from "devextreme/localization";
import { handleErrors, onRowUpdating, isAuditVisible, isNullOrEmpty } from "Utils/Utils";
import DateBox, { CalendarOptions } from 'devextreme-react/date-box';
import DataGrid, {
    Button,
    Column,
    Editing,
    RequiredRule,
    SearchPanel,
    Scrolling,
    FilterRow,
    Popup
} from 'devextreme-react/data-grid';

function getDateByYear(year) {
    if (isNullOrEmpty(year))
        return new Date();
    return new Date(year, 1, 1);
}
export class ProjectYears extends Component {
    static displatName = ProjectYears.name;

    constructor(props) {
        super(props)
        loadMessages(roMessages);
        locale(navigator.language);

        this.state = {
            projectId: props.projectId
        };
        this.setAuditEntry = props.auditEntry;
        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "ProjectYears")
        }

        this.yearEditorRender = this.yearEditorRender.bind(this);
        this.onInitNewRow = this.onInitNewRow.bind(this);
    }

    yearEditorRender(cell) {
        return <DateBox
            defaultValue={getDateByYear(cell.value)}
            type="date"
            displayFormat="yyyy"
            pickerType="calendar"
            onValueChanged={(e) => cell.setValue(e.value.getFullYear())}
            {...cell.column.datebox}

        >
            <CalendarOptions zoomLevel="decade"
                minZoomLevel="decade"
                maxZoomLevel="decade" />
        </DateBox>;
    }

    onInitNewRow(e) {
        e.data.Year = getDateByYear(null).getFullYear();
    }

    render() {
        return (
            <DataGrid
                width="100%"
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
                wordWrapEnabled={true}
                columnAutoWidth={true}
                onRowUpdating={onRowUpdating}
                dataSource={this.dataSourceOptions}
                width="93.5vw"
                remoteOperations={false}
                onInitNewRow={this.onInitNewRow}
            >
                <FilterRow visible={true} />
                <Scrolling columnRenderingMode="virtual" mode="infinite" scrollByThumb={true} />
                <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Cautare..." />
                <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} mode="row" useIcons={true}>
                    <Popup title="Ani" showTitle={true} />
                </Editing>

                <Column type="buttons" cssClass="vertical-center" width="10em">
                    <Button name="edit" cssClass="underlineBtn" />
                    <Button name="delete" cssClass="underlineBtn" />
                    <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                </Column>

                <Column dataField="Year" caption="An" cssClass="vertical-center" alignment="center" minWidth="10em"
                    editCellRender={this.yearEditorRender}>
                    <RequiredRule message="Camp obligatoriu!" />
                </Column>
            </DataGrid>
        )
    }

    dataSourceOptions = new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            return fetch(`/api/projects/${this.state.projectId}/projectyears`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return response
                })
                .catch(err => err);
        },
        byKey: (key) => {
            return fetch('/api/projectyears/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })

        },
        insert: (values) => {
            values.ProjectId = this.state.projectId;
            return fetch("/api/projectyears", {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
        },
        update: (key, values) => {
            return fetch("/api/projectyears/" + key, {
                method: "PUT",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
        },
        remove: (key) => {
            return fetch("/api/projectyears/" + key, {
                method: "DELETE",
                body: JSON.stringify({ id: key }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        }
    })
}

export default ProjectYears;