import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import roMessages from "./../../../ro.json";
import ReactDOM from 'react-dom';
import { onRowUpdating, isAuditVisible } from "Utils/Utils";
import { locale, loadMessages } from "devextreme/localization";
import { calculateFilterExpresion } from "Utils/Utils";
import { getEmployeeClientsDataSource, getEmployeesDataSource, getClientsDataSource, getProjectTypesDataSource } from 'components/EmployeeClients/EmployeeClientsGrid/DataSources'
import DataGrid, {
    Button,
    Column,
    Editing,
    RequiredRule,
    SearchPanel,
    Lookup,
    Scrolling,
    FilterRow,
    FormItem,
    Form,
    Popup,
} from 'devextreme-react/data-grid';

export class EmployeeClientsGrid extends Component {
    static displayName = EmployeeClientsGrid.name;

    constructor(props) {
        super(props)
        loadMessages(roMessages);
        locale(navigator.language);
        this.state = {
        }
        this.grdRef = React.createRef();

        this.auditProps = {
            EntryId: 0,
            Page: ""
        }

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "EmployeeClients");
        }
    }

    render() {
        return (
            <div className="container-nomenclature">
                <DataGrid
                    id="employeeClientsGrid"
                    ref={this.grdRef}
                    dataSource={getEmployeeClientsDataSource()}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    wordWrapEnabled={true}
                    columnAutoWidth={true}
                    remoteOperations={true}
                    onRowUpdating={onRowUpdating}
                >
                    <FilterRow visible={true} />
                    <Scrolling columnRenderingMode="standard" mode="standard" scrollByThumb={true} useNative={true} />
                    <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Cautare..." />
                    <Editing allowAdding={true} allowDeleting={true} allowUpdating={true} mode="popup" useIcons={true}>
                        <Form colCount={1} title="Repartizare clientilor pe angajati" />
                        <Popup title="Repartizare clientilor pe angajati" showTitle={true} minWidth="20em" maxWidth="40em" height="auto" />
                    </Editing>
                    <Column type="buttons" cssClass="vertical-center" minWidth="5em">
                        <Button name="edit" cssClass="underlineBtn" />
                        <Button name="delete" cssClass="underlineBtn" />
                        <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                    </Column>
                    <Column dataField="Id" visible={false} allowEditing={false} showEditorAlways={false}>
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="EmployeeId" caption="Angajat" visible={true} cssClass="vertical-center" alignment="center" minWidth="10em"
                        calculateSortValue="Employee.LastName"
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => { return calculateFilterExpresion("Employee.Id", selectedFilterOperations, filterValue) }}
                    >
                        <RequiredRule message="Camp obligatoriu!" />
                        <Lookup dataSource={getEmployeesDataSource()} valueExpr="Id" displayExpr="FullName" />
                    </Column>
                    <Column dataField="ClientCode" caption="Identificator Client" visible={true} cssClass="vertical-center" alignment="center" minWidth="10em"
                        calculateSortValue="Client.UniqueId"
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => { return calculateFilterExpresion("Client.UniqueId", selectedFilterOperations, filterValue) }}
                    >
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="ClientId" caption="Nume Client" visible={true} cssClass="vertical-center" alignment="center" minWidth="10em"
                        calculateSortValue="Client.Name"
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => { return calculateFilterExpresion("Client.Id", selectedFilterOperations, filterValue) }}
                    >
                        <RequiredRule message="Camp obligatoriu!" />
                        <Lookup dataSource={getClientsDataSource()} valueExpr="Id" displayExpr="Name" />
                    </Column>
                    <Column dataField="ProjectTypeId" caption="Tip de Proiect" cssClass="vertical-center" alignment="center" minWidth="10em"
                        calculateSortValue="Employee.ProjectType.Name"
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => { return calculateFilterExpresion("Employee.ProjectType.Id", selectedFilterOperations, filterValue) }}
                    >
                        <FormItem visible={false} />
                        <Lookup dataSource={getProjectTypesDataSource()} valueExpr="Id" displayExpr="Name" />
                    </Column>
                    <Column dataField="SupervisorId" caption="Supervizor" visible={true} cssClass="vertical-center" alignment="center" minWidth="10em"
                        calculateSortValue="Employee.LastName"
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => { return calculateFilterExpresion("Employee.Id", selectedFilterOperations, filterValue) }}

                    >
                        <Lookup dataSource={getEmployeesDataSource()} valueExpr="Id" displayExpr="FullName" allowClearing={true} />
                    </Column>
                </DataGrid>
            </div>
        )
	}
}

export default EmployeeClientsGrid;