import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import roMessages from "./../../ro.json";
import Form, { Item, GroupItem, EmptyItem, Label } from "devextreme-react/form";
import { locale, loadMessages } from "devextreme/localization";
import { SchedulerComponent } from "components/EmployeesScheduler/SchedulerComponent/_SchedulerComponent";
import { TagBox } from 'devextreme-react';
import { getEmployeesDataSource, getClientsDataSource } from "components/EmployeesScheduler/DataSources";

export class EmployeesScheduler extends Component {
    static displayName = EmployeesScheduler.name;

    constructor(props) {
        super(props)
        loadMessages(roMessages);
        locale(navigator.language);
        this.state = {
            refresh: true
        }

        this.clientTagBoxRef = React.createRef();

        this.schedulerComponentRef = React.createRef();

        this.schedulerProps = {
            employeeIds: [],
            clientIds: []
        }

        this.isClientsDisabled = () => {
            return this.schedulerProps.employeeIds.length === 0 
        }

        this.onEmployeeSelectionChanged = (e) => {
            var employeesSelected = this.schedulerProps.employeeIds;
            e.addedItems.forEach(element => {
                if(employeesSelected.indexOf(element.Id) === -1){
                    employeesSelected[employeesSelected.length] = element.Id
                }
            })
            e.removedItems.forEach(element => {
                var index = employeesSelected.indexOf(element.Id)
                employeesSelected.splice(index, 1)
            })
            this.schedulerProps.employeeIds = employeesSelected;
            if(this.schedulerComponentRef.current){
                this.schedulerComponentRef.current.forceUpdate();
            }
        }

        this.onClientsSelectionChanged = (e) => {
            var clientsSelected = this.schedulerProps.clientIds;
            e.addedItems.forEach(element => {
                if(clientsSelected.indexOf(element.Id) === -1){
                    clientsSelected[clientsSelected.length] = element.Id
                }
            })
            e.removedItems.forEach(element => {
                var index = clientsSelected.indexOf(element.Id)
                clientsSelected.splice(index, 1)
            })
            this.schedulerProps.clientIds = clientsSelected;
            if(this.schedulerComponentRef.current){
                this.schedulerComponentRef.current.forceUpdate();
            }
        }

    }

    render() {
        return (
            <div className="risk-assessment-container">
                <Form colCount={1}>
                    <GroupItem caption="Program angajati" colSpan={1} colCount={2}>
                        <GroupItem colSpan={2} colCount={2}>
                            <Item colSpan={1}>
                                <Label alignment="top" showColon={true} text="Angajat" />
                                <TagBox displayExpr="FullName" valueExpr="Id" searchExpr="LastName" dataSource={getEmployeesDataSource()} title="Angajati"
                                    showSelectionControls={true}
                                    maxDisplayedTags={5}
                                    onSelectionChanged={this.onEmployeeSelectionChanged}
                                    applyValueMode="useButtons"
                                    multiline={false}
                                />
                            </Item>
                            <Item colSpan={1}>
                                <Label alignment="top" showColon={true} text="Clienti" />
                                <TagBox displayExpr="Name" valueExpr="Id" searchExpr="Name" dataSource={getClientsDataSource()} title="Angajati"
                                    showSelectionControls={true}
                                    maxDisplayedTags={5}
                                    onSelectionChanged={this.onClientsSelectionChanged}
                                    applyValueMode="useButtons"
                                    ref={this.clientTagBoxRef}
                                    multiline={false}
                                />
                            </Item>
                        </GroupItem>
                        <Item colSpan={2}>
                            <SchedulerComponent employeeId={this.schedulerProps.employeeIds} clientId={this.schedulerProps.clientIds} ref={this.schedulerComponentRef}/>
                        </Item> 
                    </GroupItem>
                </Form>
                <div id="auditPopupContainer" />
                <div id="editPopupContainer" />
                <div id="addPopupContainer" />
            </div>
        );
    }
}