import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import CustomStore from 'devextreme/data/custom_store';
import roMessages from "./../../../ro.json";
import {  isNotEmpty, handleErrors, onRowUpdating} from "Utils/Utils";
import { locale, loadMessages } from "devextreme/localization";
import DataGrid, {
    Button,
    Column,
    Editing,
    FilterRow,
    Form,
    FormItem,
    Popup, RequiredRule,
    StringLengthRule,
    Scrolling, SearchPanel
} from 'devextreme-react/data-grid';

const textAreaOptions = {
    height: "200vh"
}

export class ClientRiskMeasures extends Component {

    constructor(props) {
        super(props)
        this.state = {
            clientRiskId: 0,
            selectedData: [],  
            setContainerState: null,
            dataSource: null
        }
        loadMessages(roMessages);
        locale(navigator.language);

        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onCellPrepared = this.onCellPrepared.bind(this);
    }

    render() {
        return (
            <div id="clientmeasures">
                <DataGrid
                 
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    wordWrapEnabled={true}
                    columnAutoWidth={true}
                    onRowUpdating={onRowUpdating}
                    dataSource={this.state.dataSource}
                    hoverStateEnabled={true}
                    width="100%"
                    remoteOperations={true}
                    selection={{ mode: 'single' }}
                    onSelectionChanged={this.onSelectionChanged}
                    focusedRowEnabled={true}
                    height={350}
                    onCellPrepared={this.onCellPrepared}
                   
                >

                    <FilterRow visible={true} />
                
                    <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Cautare..." />

                    <Editing allowAdding={false} allowUpdating={true} allowDeleting={false} mode="popup" useIcons={true} >
                        <Form colCount={1} title="Dimensiuni" />
                        <Popup title="Dimensiuni" showTitle={true} />
                    </Editing>

                    <Column type="buttons" cssClass="vertical-center" width="32" alignment="left" fixed={true} fixedPosition="left">
                        <Button name="edit" cssClass="underlineBtn" />
                    </Column>

                   
                    <Column dataField="ClientRiskId" visible={false} allowEditing={false} showEditorAlways={false}>
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="SubMeasureId" visible={false} allowEditing={false} showEditorAlways={false} >
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="Name" caption="Dimensiune" allowEditing={false} cssClass="vertical-center"
                        alignment="center" fixed={true} width="80" showEditorAlways={false} cellHintEnabled={true} >
                        <RequiredRule message="Camp obligatoriu!" />
                        <FormItem visible={false} />
                    </Column>

                    <Column dataField="InherentRisk" caption="Risc" allowEditing={false} cssClass="vertical-center"
                        alignment="center" fixed={true} width="80" showEditorAlways={false} cellHintEnabled={true}>
                        <RequiredRule message="Camp obligatoriu!" />
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="FunctionalExistanceNotes" caption="Nota F" cssClass="vertical-center"
                        alignment="center" width="100" fixed={true} cellHintEnabled={true}>
                        <RequiredRule message="Camp obligatoriu!" />
                        <StringLengthRule max={500} message="Nota depaseste limita maxima de 500 de caractere!" />
                        <FormItem colSpan={1} editorType="dxTextArea" editorOption={textAreaOptions} dataField="FunctionalExistanceNotes" />
                    </Column>
                  
                    <Column dataField="FunctionalExistanceValue" caption="F" allowEditing={false} cssClass="vertical-center" fixed={true}
                        alignment="center" width="30" showEditorAlways={false}>
                        <FormItem visible={false} />
                        <RequiredRule message="Camp obligatoriu!" />
                    </Column>
                    <Column dataField="RiskNotes" caption="Nota R" cssClass="vertical-center" 
                        alignment="center" width="100" fixed={true} cellHintEnabled={true}>
                        <RequiredRule message="Camp obligatoriu!" />

                        <StringLengthRule max={500} message="Nota depaseste limita maxima de 500 de caractere!" />
                        <FormItem colSpan={1} editorType="dxTextArea" editorOption={textAreaOptions} dataField="RiskNotes" />
                    </Column>
                    <Column dataField="RiskScore" caption="R" cssClass="vertical-center" fixed={true} allowEditing={false} alignment="center" fixed={true} width="30" showEditorAlways={false}>
                        <RequiredRule message="Camp obligatoriu!" />
                        <FormItem visible={false} />
                    </Column>

                </DataGrid>

            </div>
        )
    }

    dataSourceOptions = new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/clientRisks/${this.state.clientRiskId}/clientmeasures${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    };
                })
                .catch(err => err);
        },
        byKey: (key) => {
            return fetch('/api/clientmeasures/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })

        },
        update: (key, values) => {
            return fetch("/api/clientmeasures/" + key, {
                method: "PUT",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
        }
    })

   
    onSelectionChanged({ selectedRowsData }) {
   
        if (selectedRowsData.length > 0) {
         
          
            const data = selectedRowsData[0];
            this.setState({
                clientRiskId: this.props.clientRiskId,
                setContainerState: this.props.setContainerState(data.Id)
            });      
        }
      

    }

    onCellPrepared(e) {
        if ((e.columnIndex === 5 || e.columnIndex === 6) && e.rowType === 'header' ){
            e.cellElement.bgColor = '#ED7D31';
        }

        if ((e.columnIndex === 3 || e.columnIndex === 4) && e.rowType === 'header') {
            e.cellElement.bgColor = '#4472C4';
        }     
    }
  

    componentDidMount(props) {
        // console.log('mount')
        // console.log(props);
        const { clientRiskId,  setContainerState } = this.props;
        this.setState({
            clientRiskId: clientRiskId,
            selectedData: [1],
            setContainerState: setContainerState,     
            dataSource: this.dataSourceOptions
        })
       
    }

    componentDidUpdate(prevProps) {
        if (this.props.clientRiskId !== prevProps.clientRiskId) {
            const { clientRiskId, setContainerState } = this.props;
            this.setState({ 
                clientRiskId: clientRiskId,               
                setContainerState: setContainerState,
                dataSource: this.dataSourceOptions                
            })

        }

        
    }



}

export default ClientRiskMeasures;