import React, { Component } from 'react';
import { onRowUpdating, isNotEmpty, handleErrors, isAuditVisible } from "Utils/Utils";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import CustomStore from 'devextreme/data/custom_store';
import roMessages from "./../../../ro.json";
import { locale, loadMessages } from "devextreme/localization";
import DataGrid, {
    Button,
    Column,
    Editing,
    RequiredRule,
    FormItem,
    Scrolling,
    Lookup
} from 'devextreme-react/data-grid';

const locationLookupOptions = new CustomStore({
    key: "Id",
    load: (loadOptions) => {
        let params = "?";
        [
            "skip",
            "take",
            "requireTotalCount",
            "sort",
            "filter"
        ].forEach(function (i) {
            if (i in loadOptions && isNotEmpty(loadOptions[i]))
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        });

        params = params.slice(0, -1);

        return fetch(`/../Locality${params}`)
            .then(handleErrors)
            .then(response => response.json())
            .then(response => {
                return {
                    data: response.data,
                    totalCount: response.totalCount,
                    summary: response.summary,
                    groupCount: response.groupCount
                };
            })
            .catch(err => err);
    },
    byKey: (key) => {
        return fetch("/../Locality/" + key)
            .then(response => { return response.json(); })
    }
})

export class Workstation extends Component {

    constructor(props) {
        super(props)
        this.state = {
            workstationDataSourceOptions: null
        };
        loadMessages(roMessages);
        locale(navigator.language);
        this.setAuditEntry = props.func;
        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "Workstation");
        }
    }

    render() {
        return (
            <div className="container-master-detail">
                <DataGrid
                    width="100%"
                    dataSource={this.state.workstationDataSourceOptions}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    wordWrapEnabled={true}
                    height="100%"
                    remoteOperations={false}
                    onRowUpdating={onRowUpdating}
                >
                    <Scrolling columnRenderingMode="virtual" mode="infinite" />
                    <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} mode="row" useIcons={true} />
                    <Column type="buttons" width={120}>
                        <Button name="edit" />
                        <Button name="delete" />
                        <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                    </Column>
                    <Column dataField="Id" visible={false} allowEditing={false}>
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="Name" caption="Nume" alignment="center">
                        <RequiredRule message="Camp obligatoriu!" />
                    </Column>
                    <Column dataField="Address" caption="Adresa" alignment="center">
                        <RequiredRule message="Camp obligatoriu!" />
                    </Column>
                    <Column dataField="LocalityId" caption="Localitate" alignment="center">
                        <RequiredRule message="Camp obligatoriu!" />
                        <Lookup dataSource={locationLookupOptions} displayExpr="Name" valueExpr="Id" />
                    </Column>
                </DataGrid>
            </div>
        );
    }

    componentDidMount(props) {
        const { clientId } = this.props;
        this.setState({
            workstationDataSourceOptions: new CustomStore({
                key: "Id",
                load: () => {
                    return fetch(`/Client/${clientId}/workstations`)
                        .then(handleErrors)
                        .then(response => response.json());
                },
                insert: (values) => {
                    values["ClientId"] = clientId;
                    return fetch("/Workstation", {
                        method: "POST",
                        body: JSON.stringify(values),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(response => response.json());
                },
                update: (key, values) => {
                    return fetch("/Workstation/" + key, {
                        method: "PUT",
                        body: JSON.stringify(values),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(response => response.json());
                },
                remove: (key) => {
                    return fetch("/Workstation/" + key, {
                        method: "DELETE",
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                }
            })
        })
    }
}

export default Workstation;