import React, { Component, Fragment } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import roMessages from "./../../ro.json";
import Form, { Item, GroupItem, EmptyItem, Label } from "devextreme-react/form";
import { locale, loadMessages } from "devextreme/localization";
import { BillsGrid } from "./BillsGrid/_BillsGrid"
import { DateBox } from 'devextreme-react';
import { CalendarOptions } from 'devextreme-react/date-box';

const setDate = (component, date) => {
    component.option("value", date)
}

export class Bills extends Component {
    static displayName = Bills.name;

    constructor(props) {
        super(props)
        loadMessages(roMessages);
        locale(navigator.language);

        this.startDateBoxRef = React.createRef();
        this.endDateBoxRef = React.createRef();

        this.state = {
            startDate: new Date(new Date().getFullYear(), 0, 1),
            endDate: new Date(new Date().getFullYear(), 11, 1)
        }

        this.onValueChanged = (e) => {
            this.updateDates();
        }

        this.updateDates = () => {
            this.setState({
                startDate: new Date(this.startDateBoxRef.current.instance.option("value")),
                endDate: new Date(this.endDateBoxRef.current.instance.option("value"))
            })
        }
    }

    render() {
        return (
            <Fragment>
                <Form colCount={1} labelLocation="top">
                    <GroupItem caption="Facturi" colSpan={1} colCount={2}>
                        <GroupItem colSpan={1} colCount={4}>
                            <Item colSpan={1}>
                                <Label alignment="top" showColon={true} text="De la luna" />
                                <DateBox displayFormat="MM.yyyy" ref={this.startDateBoxRef} onValueChanged={this.onValueChanged} onInitialized={function (e) { setDate(e.component, new Date(new Date().getFullYear(), 0, 1)) }}>
                                    <CalendarOptions maxZoomLevel="year" minZoomLevel="decade" zoomLevel="year" />
                                </DateBox>
                            </Item>
                            <Item colSpan={1}>
                                <Label alignment="top" showColon={true} text="Pana la luna" />
                                <DateBox displayFormat="MM.yyyy" ref={this.endDateBoxRef} onValueChanged={this.onValueChanged} onInitialized={function (e) { setDate(e.component, new Date(new Date().getFullYear(), 11, 1)) }}>
                                    <CalendarOptions maxZoomLevel="year" minZoomLevel="decade" zoomLevel="year" />
                                </DateBox>
                            </Item>
                            <EmptyItem colSpan={2} />
                        </GroupItem>
                        <EmptyItem colSpan={1} />
                        <Item colSpan={2}>
                            <BillsGrid EndDate={this.state.endDate} StartDate={this.state.startDate} />
                        </Item>
                    </GroupItem>
                </Form>
                <div id="auditPopupContainer" />
            </Fragment>
        )
    }
}

export default Bills;