
function deleteContactPersons(key) {
    return fetch("/../api/contactpersons/" + key, {
        method: "DELETE",
        body: JSON.stringify({ id: key }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

function addContactPersons(values) {
    return fetch("/../api/contactpersons", {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

const property_name_client_misc = "ClientMiscId";
const property_name_contract = "ContractId";
function createObjectContactPerson(contractId, clientMiscId) {
    const obj = {};
    obj[property_name_contract] = contractId;
    obj[property_name_client_misc] = clientMiscId;
    return obj;
}


const fetchDataContactPersons = async (contractId, clientMiscId) => {
    const response = await fetch(`/../api/contracts/${contractId}/contactpersons`);
    const data = await response.json();

    if (response.state >= 400) {
        throw new Error(data.errors)
    }
    else {
        if (data.length == 0)
            if (clientMiscId != undefined)
                for (const key of clientMiscId)
                    addContactPersons(createObjectContactPerson(contractId, key));
            else {
                data.forEach(function (item) {
                    deleteContactPersons(item.Id);
                }
                );
                if (clientMiscId != undefined)
                    for (const key of clientMiscId) {
                        addContactPersons(createObjectContactPerson(contractId, key));
                    }
            }
    }
}

export default fetchDataContactPersons;
