import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { calculateCustomFilterExpresionForExportBills } from '../MailBills/_CalculateCustomFilterExpresion';

export default function onExporting(e) {
    var selectedRows = calculateCustomFilterExpresionForExportBills(e.component);
    selectedRows.pop();

    e.component.getDataSource().filter([selectedRows]);
    e.component.beginUpdate();

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Facturi');

    exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true,
        customizeCell: ({ gridCell, excelCell }) => {
            if (gridCell.rowType === 'data') {
                if (gridCell.column.dataField === 'ContactPersons') {
                    excelCell.value = gridCell.value.replace("<br>", "\n");
                }
            }
        }
    }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Facturi.xlsx');
        });
    }).then(function () {
        e.component.endUpdate();
        e.component.clearFilter();
    });
    e.cancel = true;
}