import { handleErrors, isNotEmpty, isNullOrEmpty } from "Utils/Utils";
import CustomStore from 'devextreme/data/custom_store';


function getOfferStatus(statuses) {
   // console.log(statuses);
    let mapToArray = Array.from(statuses.values());
    var param = "";
    mapToArray.forEach(function (i) {
        param += "&statuses=" + i;
    });
   // console.log(param);
    return param;
}


export function getClientsDataSource() {
    return new CustomStore({
        key: "Id",
        load: loadOptions => {
            return fetch('/../client').then(response => response.json())
        },
        byKey: (key) => {
            return fetch('/client/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    });
}


export function getClientTypeDataSource(){
    return new CustomStore({
        key: "Id",
        load: () => {
            return fetch(`/../api/clientTypes`)
                .then(handleErrors)
                .then(response => response.json());
        },
        byKey: (key) => {
            return fetch("/api/clientTypes/" + key)
                .then(response => response.json())
                .then(response => {
                    return response;
                })
        }
    });
}


export function getOfferStatuses(){
    return new CustomStore({
        key: "Id",
        load: () => {
            return fetch(`/../api/offerstatuses`)
                .then(handleErrors)
                .then(response => response.json());
        },
        byKey: (key) => {
            return fetch("/api/offerstatuses/" + key)
                .then(response => response.json())
                .then(response => {
                    return response;
                })
        }


    });
}
export function  getoffersDataSource(statuses){

    //console.log(statuses);
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                 {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                   // console.log(params);
                 }               
                });

            params = params.slice(0, -1);


            //console.log(params);
            return fetch(`/api/offers${params}${getOfferStatus(statuses)}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    };
                })
                .catch(err => err);
        },
        byKey: (key) => {
            return fetch('/api/offers/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })

        },
        insert: (values) => {          
            return fetch("/api/offers", {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        },
        update: (key, values) => {
        
            return fetch("/api/offers/" + key, {
                method: "PUT",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
        },
        remove: (key) => {
            return fetch("/api/offers/" + key, {
                method: "DELETE",
                body: JSON.stringify({ id: key }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        }
    })
}
