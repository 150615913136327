import React from 'react';
import { loadMessages, locale } from "devextreme/localization";
import roMessages from "./../../ro.json";
import { Popup, Position } from 'devextreme-react/popup';
import { getEmployeesDataSource } from "components/Employees/EmployeesGrid/DataSources";
import { Button } from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import '../ProjectTasks/ProjectTaskStyle.css';
import { SelectBox } from 'devextreme-react';
import DateBox from 'devextreme-react/date-box';
import { isNullOrEmpty } from "Utils/Utils";
import { NumberBox } from 'devextreme-react/number-box';
import RadioGroup from 'devextreme-react/radio-group';
import { dataSourceOptions } from 'components/Nomenclatoare/_ActivityDataSource'
import { updateTask, getParentsTask } from "components/ProjectTasks/_TaskDataSource";
const tasks = ['Selecteaza activitate', 'Adauga task'];

const property_name_title = "Title";
const property_name_parentId = "ParentId";
const property_name_progress = "Progress";
const property_name_activityId = "ActivityId";
const property_name_employeeId = "EmployeeId";
const property_name_startDate = "StartDate";
const property_name_endDate = "EndDate";

export function createObjectTask(title, parentId, progress, activityId, employeeId, startDate, endDate) {
    const obj = {};
    obj[property_name_title] = title;
    obj[property_name_parentId] = parentId;
    obj[property_name_progress] = progress;
    obj[property_name_activityId] = activityId;
    obj[property_name_employeeId] = employeeId;
    obj[property_name_startDate] = startDate;
    obj[property_name_endDate] = endDate;
    return obj;
}

export function getParentProgress(leafs, startDate, endDate, progress, key) {
    var minutes = 0;
    var sumProgress = 0;

    leafs.forEach(function (leaf) {
        var startDt = new Date(leaf.StartDate);
        var endDt = new Date(leaf.EndDate);
        var progr = leaf.Progress;

        if (leaf.Id === key) {
            progr = progress;
            startDt = new Date(startDate);
            endDt = new Date(endDate);
        }

        minutes += Math.floor(endDt - startDt) / 1000 / 60;
        sumProgress += (Math.floor(endDt - startDt) / 1000 / 60) * progr;
    });

    return sumProgress / minutes;
}

function refreshGantt(e) {
    if (e.props.ganttRef != undefined && e.props.ganttRef != null) {
        e.props.ganttRef.current.instance.beginUpdate();
        e.props.dataSourceGantt.reload();
        e.props.ganttRef.current.instance.endUpdate();
        e.props.ganttRef.current.instance.repaint();
    }
}

function getMinDate(startDate, values, key){
    var date;
    var stDate;
    for (var i = 0; i < values.length - 1; i++) {
        if (values[i].Id === key)
            stDate = startDate;
        else
            stDate = new Date(values[i].StartDate);

        if (stDate < new Date(values[i + 1].StartDate))
            date = stDate;
        else
            date = new Date(values[i + 1].StartDate);
    }

    if (new Date(startDate) > date)
        date = startDate;

    return date;
}

function getMaxDate(date1, values, key) {
    var date;
    var endDate;
    for (var i = 0; i < values.length - 1; i++) {
        if (values[i].Id === key)
            endDate = date1;
        else
            endDate = new Date(values[i].EndDate);

        if (endDate > new Date(values[i + 1].EndDate))
            date = endDate;
        else
            date = new Date(values[i + 1].EndDate);
    }

    if (new Date(date1) > date)
        date = date1;

    return date;
}

export function updateParentTask(parentId, startDate, endDate, e, progress, key) {
    var leafs = [];
    if (parentId === 0) {
        if (e.popupRef != undefined)
            e.popupRef.current.instance.hide();
        return;
    }

    getParentsTask(parentId).then(value => {
        var updateStartDate = false;
        var newProgress = 0;

        value.forEach(function (item) {
            var EndDate = item.EndDate;
            var StartDate = item.StartDate;
            var updateEndDate = false;

            if (item.Leafs.length > 0) {
                leafs = item.Leafs;

                EndDate = getMaxDate(endDate, leafs, key);

                StartDate = getMinDate(startDate, leafs, key);
            }
            else {
                if (new Date(item.EndDate) < new Date(endDate)) {
                    EndDate = endDate;
                    updateEndDate = true;
                }

                if (new Date(item.StartDate) > new Date(startDate)) {
                    StartDate = startDate;
                    updateStartDate = true;
                }
            }

            if (StartDate != startDate)
                updateStartDate = true;

            if (EndDate != endDate)
                updateEndDate = true;

            var Progr = item.Progress;
            newProgress = getParentProgress(leafs, startDate, endDate, progress, key);

            if (newProgress != item.Progress) {
                Progr = Math.round(newProgress);
                updateStartDate = true;
            }

            if (updateStartDate) {
                updateTask(item.Id, createObjectTask(item.Title, item.ParentId, Progr, item.ActivityId, item.EmployeeId, StartDate, EndDate));
                updateEndDate = false;
                updateStartDate = false;
            }
            else
                if (updateEndDate) {
                    updateTask(item.Id, createObjectTask(item.Title, item.ParentId, Progr, item.ActivityId, item.EmployeeId, StartDate, EndDate));
                    updateEndDate = false;
                }
        });
        refreshGantt(e);
    });

    refreshGantt(e);

    if (e.popupRef != undefined || e.popupRef != null)
        e.popupRef.current.instance.hide();
}

function getDisabled(color) {
    if (color === "RoyalBlue")
        return false;
    else
        return true;
}

class TaskForm extends React.Component {
    constructor(props) {
        super(props);
        loadMessages(roMessages);
        locale(navigator.language);

        this.state = {
            popupVisible: props.info.Visible,
            TaskId: props.info.TaskId,
            dataSource: props.dataSource,
            disabledActivity: false,
            disabledTask: true,
            ganttRef: props.ganttRef,
            changedRadio: false
        };

        this.hideInfo = () => {
            this.props.info.Visible = false;

            this.setState({
                popupVisible: false,
                TaskId: null,
                dataSource: null,
                disabledActivity: false,
                disabledTask: true,
                changedRadio: false
            });
        }

        this.popupRef = React.createRef();
        this.textBoxRef = React.createRef();
        this.selectBoxRef = React.createRef();
        this.dateBoxStartRef = React.createRef();
        this.dateBoxEndRef = React.createRef();
        this.progressRef = React.createRef();
        this.radioGroupRef = React.createRef();
        this.selectBoxActivityRef = React.createRef();
        this.changeSelectionTask = this.changeSelectionTask.bind(this);
        this.onClickSave = this.onClickSave.bind(this);


    }
    get selectBoxRefActivity() {
        return this.selectBoxActivityRef.current.instance;
    }

    onClickSave(e) {
        const key = this.props.dataSource.Id;
        const activityId = this.selectBoxRefActivity.option("value");
        const parentId = this.props.dataSource.ParentId;
        const title = !isNullOrEmpty(activityId) ? this.selectBoxRefActivity.option("text") : this.textBoxRef.current.instance.option("value");
        const progress = this.progressRef.current.instance.option("value");
        const employeeId = this.selectBoxRef.current.instance.option("value");
        const startDate = this.dateBoxStartRef.current.instance.option("value");
        const endDate = this.dateBoxEndRef.current.instance.option("value");

        updateTask(key, createObjectTask(title, parentId, progress, activityId, employeeId, new Date(startDate), new Date(endDate)));
        
        updateParentTask(parentId, new Date(startDate), new Date(endDate), this, progress, key);
    }

    changeSelectionTask(e) {
        this.setState({ changedRadio: true });
        if (e.value === "Selecteaza activitate") {
            this.textBoxRef.current.instance.option("disabled", true);
            this.selectBoxActivityRef.current.instance.option("disabled", false);
            this.setState({
                disabledActivity: false,
                disabledTask: true
            });
        }
        else {
            this.selectBoxActivityRef.current.instance.option("disabled", true);
            this.textBoxRef.current.instance.option("disabled", false);
            this.setState({
                disabledActivity: true,
                disabledTask: false
            });
        }
    }

    render() {
        return (
            <div >
                <Popup
                    visible={this.props.info.Visible}
                    onHiding={this.hideInfo}
                    dragEnabled={true}
                    closeOnOutsideClick={false}
                    showCloseButton={true}
                    showTitle={true}
                    title="Task Edit"
                    height="48vh"
                    width="30vw"
                    ref={this.popupRef}
                >
                    <Position
                        at="center"
                        my="center"

                    />
                    <div>

                        <div className="dx-field">
                            <div className="dx-field-label-radio">
                                <RadioGroup
                                    ref={this.radioGroupRef}
                                    onValueChanged={this.changeSelectionTask}
                                    items={tasks}
                                    layout="vertical"
                                    defaultValue={tasks[0]}
                                />
                            </div>
                            <div className="dx-control-task">
                                <SelectBox
                                    dataSource={dataSourceOptions}
                                    displayExpr="Name"
                                    valueExpr="Id"
                                    ref={this.selectBoxActivityRef}
                                />
                            </div>
                            <div className="dx-control-task">
                                <TextBox
                                    showClearButton={true}
                                    ref={this.textBoxRef}
                                />
                            </div>
                        </div>

                        <div className="dx-field">
                            <div className="dx-field-label-task">Angajati</div>
                            <div className="dx-field-control-task">
                                <SelectBox
                                    defaultValue={this.props.dataSource != null ? this.props.dataSource.EmployeeId : null}
                                    dataSource={getEmployeesDataSource()}
                                    displayExpr="FullName"
                                    valueExpr="Id"
                                    showClearButton={true}
                                    ref={this.selectBoxRef}
                                />
                            </div>
                        </div>

                        <div className="dx-field">
                            <div className="dx-field-label-task">Data start</div>
                            <div className="dx-field-control-task">
                                <DateBox
                                    type="datetime"
                                    displayFormat="dd.MM.yyyy HH:mm"
                                    defaultValue={this.props.dataSource != null ? this.props.dataSource.StartDate : null}
                                    showClearButton={true}
                                    useMaskBehavior={true}
                                    ref={this.dateBoxStartRef}
                                />
                            </div>
                        </div>

                        <div className="dx-field">
                            <div className="dx-field-label-task">Data stop</div>
                            <div className="dx-field-control-task">
                                <DateBox
                                    type="datetime"
                                    displayFormat="dd.MM.yyyy HH:mm"
                                    defaultValue={this.props.dataSource != null ? this.props.dataSource.EndDate : null}
                                    showClearButton={true}
                                    useMaskBehavior={true}
                                    ref={this.dateBoxEndRef}
                                />
                            </div>
                        </div>

                        <div className="dx-field">
                            <div className="dx-field-label-task">Progres</div>
                            <div className="dx-field-control-task">
                                <NumberBox
                                    defaultValue={this.props.dataSource != null ? this.props.dataSource.Progress : null}
                                    showClearButton={true}
                                    showSpinButtons={true}
                                    ref={this.progressRef}
                                />
                            </div>
                        </div>

                        <div>

                            <div className="dx-button-task">
                                <Button
                                    text="Renunt"
                                />
                            </div>

                            <div className="dx-button-task">
                                <Button
                                    text="Salvez"
                                    onClick={this.onClickSave}
                                />
                            </div>

                        </div>
                    </div>

                </Popup>
            </div>
        );
    }

    componentDidMount(props) {
        const { info } = this.props

        this.setState({
            dataSource: this.props.dataSource,
            popupVisible: info.Visible,
            TaskId: info.TaskId,
            disabledActivity: false,
            disabledTask: true,
            ganttRef: this.props.ganttRef
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.changedRadio) {
            if (this.props.dataSource != null) {
                if (this.props.dataSource.EmployeeId == undefined) {
                    this.selectBoxRef.current.instance.option("value", null);
                }
                else {
                    this.selectBoxRef.current.instance.option("value", this.props.dataSource != null ? this.props.dataSource.EmployeeId : null);
                }
            }

            this.dateBoxStartRef.current.instance.option("value", this.props.dataSource.StartDate);
            this.dateBoxStartRef.current.instance.option("disabled", getDisabled(this.props.dataSource.Color));
            this.dateBoxEndRef.current.instance.option("value", this.props.dataSource.EndDate);
            this.dateBoxEndRef.current.instance.option("disabled", getDisabled(this.props.dataSource.Color));
            this.progressRef.current.instance.option("value", this.props.dataSource.Progress);
            this.progressRef.current.instance.option("disabled", getDisabled(this.props.dataSource.Color));
            this.textBoxRef.current.instance.option("value", this.props.dataSource.Title);
            this.textBoxRef.current.instance.option("disabled", this.state.disabledTask);

        }

        this.selectBoxActivityRef.current.instance.option("disabled", this.state.disabledActivity);
        this.selectBoxRefActivity.option("value", isNullOrEmpty(this.props.dataSource.ActivityId) ? null : this.props.dataSource.ActivityId);
    }

}

export default TaskForm;
