import React, { Component } from 'react';
import { CheckBox } from 'devextreme-react/check-box';
import { isNotEmpty } from '../../Utils/Utils';
import queryString from 'query-string';

function deleteMailBill(key) {
    return fetch("/../api/mailbills/" + key, {
        method: "DELETE",
        body: JSON.stringify({ id: key }),
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

function addMailBill(values) {
    return fetch("/../api/mailbills", {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

function updateMailBill(values, key) {
    return fetch("/../api/mailbills/" + key, {
        method: "PUT",
        body: JSON.stringify(values),
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

const property_name_billId = "BillId";
const property_name_month = "Month";
const property_name_adjournment = "Adjournment";
function createObjectMailBill( month, adjournment, billId) {
    const obj = {};
    obj[property_name_billId] = billId;
    obj[property_name_month] = month;
    obj[property_name_adjournment] = adjournment;
    return obj;
}

function createUpdateObjectMailBill(adjournment, month) {
    const obj = {};
    obj[property_name_adjournment] = adjournment;
    obj[property_name_month] = month;
    return obj;
}

function handleQueryString() {
    return queryString.parse(window.location.search);
}

export default class AdjournmentComponent extends Component {
    constructor(props) {
        super(props);

        this.onValueChanged = this.onValueChanged.bind(this);
    }

    onValueChanged(e) {
        let mailBillId = this.props.data.data.MailBillId;
        let month = handleQueryString().month;
        let billId = this.props.data.data.Id;
        let deleted = this.props.data.data.Deleted;

        if (isNotEmpty(mailBillId) && !isNotEmpty(deleted))
            deleteMailBill(mailBillId);
        else
            if (isNotEmpty(mailBillId) && isNotEmpty(deleted))
                updateMailBill(createUpdateObjectMailBill(e.value, month), mailBillId);
            else
                addMailBill(createObjectMailBill(month, e.value, billId));
        this.props.data.component.refresh();
    }

    render() {
        return (
            <div className="dx-field">
                <CheckBox
                    defaultValue={this.props.data.value}
                    onValueChanged={this.onValueChanged}
                />
            </div>
        );
    }
}

