export function calculatePercentDisplayValue(rowData) {
    if (rowData) {
        if (rowData.Percent) {
            return `${rowData.Percent.toString()}%`;
        }
    }
    return "";
}

export function calculateContractNumber(rowData) {
    if (rowData.RowType === "summary") {
        return null;
    }

    return rowData.ContractNumber;
}

export function calculateEuroBillCellValue(rowData) {
    if (rowData.RowType === "summary") {
        return parseFloat(rowData.EuroTotal).toFixed(2);
    }
    return ((parseFloat(rowData.EuroTotal) * parseFloat(rowData.Percent)) / 100.00).toFixed(2);
}

export function calculateRonBillCellValue(rowData) {
    if (rowData.RowType === "summary") {
        return parseFloat(rowData.RonTotal).toFixed(2);
    }
    return ((parseFloat(rowData.RonTotal) * parseFloat(rowData.Percent)) / 100.00).toFixed(2);
}

export function calculateFullBillValue(rowData) {
    if (!rowData.BillValue) {
        return null
    }
    return (parseFloat(rowData.BillValue)) * 1.19
}

export function calculateBillingMonth(rowData) {
    if (rowData.RowType === "summary") {
        return ""
    }
    var date = new Date(rowData.BillMonth)
    return `${String((date.getMonth() + 1)).padStart(2, '0')}.${String(date.getFullYear())}`;
}

export function getBilledAmmountForMonth(date, rowData) {
    var billDate = new Date(rowData.BillMonth)
    if (billDate.getMonth() === date.getMonth() && billDate.getFullYear() === date.getFullYear()) {
        return `Transa ${rowData.Percent}% ${billDate.getFullYear()}`;
    }
    return null;
}

export function getBillEstimatedAmmountForMonth(date, rowData) {
    var billDate = new Date(rowData.BillMonth)
    if (rowData.RowType == "summary") {
        var sum = 0;
        for (var i = 0; i < rowData.SummaryValuesByMonth.length; i++) {
            var billDateMonth = new Date(rowData.SummaryValuesByMonth[i].BilledDate)
            if (billDateMonth.getMonth() === date.getMonth() && billDateMonth.getFullYear() === date.getFullYear()) {
                sum += rowData.SummaryValuesByMonth[i].EstimatedValue;
            }
        }
        return sum;
    }
    if (billDate.getMonth() === date.getMonth() && billDate.getFullYear() === date.getFullYear()) {
        return (parseFloat(rowData.RonTotal) * parseFloat(rowData.Percent)) / 100.00
    }
    return null;
}

export function getBillActualAmmountForMonth(date, rowData) {
    var billDate = new Date(rowData.BillMonth)
    if (rowData.RowType == "summary") {
        var sum = 0;
        for (var i = 0; i < rowData.SummaryValuesByMonth.length; i++) {
            var billDateMonth = new Date(rowData.SummaryValuesByMonth[i].BilledDate)
            if (billDateMonth.getMonth() === date.getMonth() && billDateMonth.getFullYear() === date.getFullYear()) {
                sum += rowData.SummaryValuesByMonth[i].BilledValue;
            }
        }
        return sum;
    }
    if (billDate.getMonth() === date.getMonth() && billDate.getFullYear() === date.getFullYear()) {
        if (!rowData.BillValue) {
            return "0,00";
        }
        return parseFloat(rowData.BillValue)
    }
    return null;
}

export function getBillRecievedAmmountForMonth(date, rowData) {
    var billDate = new Date(rowData.BillMonth)
    if (rowData.RowType == "summary") {
        var sum = 0;
        for (var i = 0; i < rowData.SummaryValuesByMonth.length; i++) {
            var billDateMonth = new Date(rowData.SummaryValuesByMonth[i].BilledDate)
            if (billDateMonth.getMonth() === date.getMonth() && billDateMonth.getFullYear() === date.getFullYear()) {
                sum += rowData.SummaryValuesByMonth[i].InvoicedValue;
            }
        }
        return sum;
    }
    if (billDate.getMonth() === date.getMonth() && billDate.getFullYear() === date.getFullYear()) {
        if (!rowData.BillInvoicedAmmount) {
            return "0,00";
        }
        return parseFloat(rowData.BillInvoicedAmmount)
    }
    return null;
}

export function calculateCheckValue(rowData) {
    return parseFloat(rowData.ContractDetailBillValue) - parseFloat(rowData.RonTotal);
}