import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import roMessages from "./../../../ro.json";
import { validatePhoneNumber, getClientMiscDataSource, onRowUpdating, isAuditVisible } from "Utils/Utils";
import { locale, loadMessages } from "devextreme/localization";
import DataGrid, {
    Button,
    Column,
    Editing,
    RequiredRule,
    EmailRule,
    CustomRule
} from 'devextreme-react/data-grid';


export class Administrators extends Component {

    constructor(props) {
        super(props)
        this.state = {
            clientId: props.clientId
        };
        loadMessages(roMessages);
        locale(navigator.language);
        this.setAuditEntry = props.func;
        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "ClientMisc");
        }
    }

    render() {
        return (
            <div className="container-master-detail">
                <DataGrid
                    id={"clientMiscGrid" + this.state.clientId}
                    width="100%"
                    dataSource={getClientMiscDataSource(this.state.clientId, "ClientMisc")}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    height="100%"
                    remoteOperations={false}
                    onRowUpdating={onRowUpdating}
                >
                    <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} mode="row" useIcons={true} />
                    <Column type="buttons" width={120}>
                        <Button name="edit" />
                        <Button name="delete" />
                        <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                    </Column>
                    <Column dataField="Id" visible={false} allowEditing={false} />
                    <Column dataField="Name" caption="Nume">
                        <RequiredRule message="Camp obligatoriu!" />
                    </Column>
                    <Column dataField="Phone" caption="Numar de telefon">
                        <RequiredRule message="Camp obligatoriu!" />
                        <CustomRule message="Numar de telefon invalid" validationCallback={validatePhoneNumber} />
                    </Column>
                    <Column dataField="Email" caption="Adresa de e-mail">
                        <RequiredRule message="Camp obligatoriu!" />
                        <EmailRule message="Adresa de e-mail nu este valida" />
                    </Column>
                </DataGrid>
            </div>
        );
    }

    componentDidMount(props) {
        const { clientId } = this.props;
        this.setState({
            clientId: clientId
        });
    }
}

export default Administrators;