import CustomStore from "devextreme/data/custom_store";
import { isNotEmpty, handleErrors } from "Utils/Utils";

export function getSchedulerData(employeeIds, clientIds, schedulerRef) {
    if (!schedulerRef.current) {
        return [];
    }
    var instance = schedulerRef.current.instance
    return new CustomStore({
        key: "Id",
        load: () => {
            let employeeIdsQuery="&";
            employeeIds.forEach(function(i){
                employeeIdsQuery += `employeeIds=${i}&`
            })
            employeeIdsQuery.slice(0, -1);

            let clientIdsQuery="&"
            clientIds.forEach(function(i){
                clientIdsQuery += `clientIds=${i}&`
            })
            clientIdsQuery.slice(0, -1)

            return fetch(`/api/employeeappointments?startDate=${instance.getStartViewDate().valueOf() / 1000}&endDate=${instance.getEndViewDate().valueOf() / 1000}${employeeIdsQuery}${clientIdsQuery}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response =>
                {
                    return response;
                })
        },
        remove: (key) => {
            return fetch(`/api/employeeappointments/${key}`, {
                method: "DELETE",
                body: JSON.stringify({ id: key }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        }
    })
}

export function getProjectDataSource(clientId) {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            if (!clientId) {
                return Promise.resolve([])
            }

            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);
            return fetch(`/api/clients/${clientId}/projects${params}`)
                .then(response => response.json())
                .then(response => {
                    return response;
                })
        },
        byKey: (key) => {
            return fetch('/api/projects/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    });
}

export function getProjectYearsDataSource(projectId) {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            if (!projectId) {
                return Promise.resolve([])
            }

            return fetch(`/api/projects/${projectId}/projectyears`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return response
                })
                .catch(err => err);
        },
        byKey: (key) => {
            return fetch('/api/projectyears/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })

        },
    })
}

export function getActivitiesDataSource() {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/activities/parentactivities${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return response
                })
                .catch(err => err);
        },
        byKey: (key) => {
            return fetch('/api/activities/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })

        },
    })
}

export function getClientsDataSource(employeeId) {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            if (!employeeId) {
                return Promise.resolve([])
            }

            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/employees/${employeeId}/clients${params}`)
                .then(response => response.json())
                .then(response => {
                    return response;
                })
        },
        byKey: (key) => {
            return fetch('/client/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    });
}
