import React from 'react';
import { Popup } from 'devextreme-react/popup';
import { Form, ScrollView } from 'devextreme-react';
import { ButtonItem, EmptyItem, Item, Label, RequiredRule } from "devextreme-react/form";
import {
    activitiesEditorOptions,
    clientsEditorOptions,
    employeeEditorOptions,
    projectsEditorOptions
} from "./_FormsEditorOptions"

export class EditPopup extends React.Component{
    constructor(props){
        super(props);
        this.popupRef = React.createRef();
        this.formRef = React.createRef(0);
        this.state = {
            grdRef: null,
            employeeId: null,
            clientId: null,
            entryId: null
        }

        this.setData = false;

        this.setEmployeeId = (employeeId) => {
            this.setData = true;
            this.setState({
                employeeId: employeeId
            })
            this.formRef.current.instance.getEditor("ClientId").option("value", undefined)
        }

        this.setClientId = (clientId) => {
            this.setData = true;
            this.setState({
                clientId: clientId
            })
            this.formRef.current.instance.getEditor("ProjectId").option("value", undefined)
        }

        this.addButtonOptions = () => {
            return {
                text: "Salvez",
                onClick: (e) => {
                    var instance = this.formRef.current.instance;
                    if (!instance.validate().isValid) {
                        return;
                    }

                    var formData = instance.option("formData")
                    formData.UpdateEmployeeTimesheetHours = [];
                    return fetch(`/api/employeetimesheets/${this.state.entryId}`, {
                        method: "PUT",
                        body: JSON.stringify(formData),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(response => response.json())
                        .then(response => {
                            this.formRef.current.instance.resetValues();
                            this.state.grdRef.current.instance.refresh();
                            this.popupRef.current.instance.hide();
                        })

                }
            }
        }

        this.setFormData = (data) => {
            this.formRef.current.instance.getEditor("EmployeeId").option("value", data.EmployeeId);
            this.formRef.current.instance.getEditor("ClientId").option("value", data.ClientId);
            this.formRef.current.instance.getEditor("ProjectId").option("value", data.ProjectId);
            this.formRef.current.instance.getEditor("ActivityId").option("value", data.ActivityId);
        }
    }

    render() {
        return (
            <div>
                <Popup
                    dragEnabled={true}
                    closeOnOutsideClick={false}
                    showTitle={true}
                    ref={this.popupRef}
                    title="Activitatea angajatului"
                    minWidth="20em"
                    maxWidth="40em"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <Form
                            ref={this.formRef}
                            showColonAfterLabel={true}
                            labelLocation="left"
                            colCount={1}
                        >
                            <Item
                                dataField="EmployeeId"
                                caption="Angajat"
                                editorType="dxLookup"
                                editorOptions={employeeEditorOptions(this.setEmployeeId)}
                            >
                                <Label text="Angajat" />
                                <RequiredRule message="Camp obligatoriu!" />
                            </Item>
                            <Item
                                dataField="ClientId"
                                caption="Client"
                                editorType="dxLookup"
                                editorOptions={clientsEditorOptions(this.state.employeeId, this.setClientId)}
                            >
                                <Label text="Client" />
                                <RequiredRule message="Camp obligatoriu!" />
                            </Item>
                            <Item
                                dataField="ProjectId"
                                caption="Proiect"
                                editorType="dxLookup"
                                editorOptions={projectsEditorOptions(this.state.clientId)}
                            >
                                <Label text="Proiect" />
                                <RequiredRule message="Camp obligatoriu!" />
                            </Item>
                            <Item
                                dataField="ActivityId"
                                caption="Activitate"
                                editorType="dxLookup"
                                editorOptions={activitiesEditorOptions()}
                            >
                                <Label text="Activitate" />
                                <RequiredRule message="Camp obligatoriu!" />
                            </Item>
                            <EmptyItem />
                            <ButtonItem
                                horizontalAlignment="right"
                                buttonOptions={this.addButtonOptions()}
                            />
                        </Form>
                    </ScrollView>
                </Popup>
            </div>
        )
    }

    componentDidMount(props){
        const { entryId, grdRef, formData } = this.props;
        this.setState({
            employeeId: formData.EmployeeId,
            clientId: formData.ClientId,
            entryId: entryId,
            grdRef: grdRef
        })
        this.popupRef.current.instance.show();
        if(this.formRef.current !== null){
            this.setFormData(formData)
        }
    }

    componentDidUpdate(prevProps){
        const { entryId, grdRef, formData } = prevProps;
        if(entryId !== this.props.entryId){
            this.setState({
                employeeId: formData.EmployeeId,
                clientId: formData.ClientId,
                entryId: entryId,
                grdRef: grdRef
            })
        }
        this.popupRef.current.instance.show();
        if(!this.setData){
            if(this.formRef.current !== null){
                this.setFormData(formData);
                if(this.state.employeeId == null){
                    this.setState({
                        employeeId: formData.EmployeeId,
                        clientId: formData.ClientId
                    })
                }
            }
        }
        this.setData = false;
    }
}