import React, { Component } from 'react';
import DataGrid, { Column, Summary, TotalItem, FilterRow, SearchPanel, Pager, Paging, Lookup, Export } from 'devextreme-react/data-grid';
import { GetServiceCategoryDataSource } from "../Contracts/ContractPurposeData";
import { GetPaymentMethodDataSource } from "../Contracts/PaymentMethodData";
import { GetBillsDataSource } from "../MailBills/_MailBillsDataSource";
import { loadMessages, locale } from "devextreme/localization";
import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import queryString from 'query-string';
import roMessages from "./../../ro.json";
import ReactDOM from 'react-dom';
import { monthNames, calculateFilterExpresion } from "Utils/Utils";
import AdjournmentComponent from "../MailBills/_AdjournmentComponent";
import Invoiced from "../MailBills/_Invoiced";
import OnClickExportPdf from "../MailBills/_ExportPdfMailBills";
import onExporting from "../MailBills/_ExportExcelMailBills";
import { calculateEuroBillCellValue } from "components/Bills/BillsGrid/CalculateCellValues/_CalculateCellValues";

export class MailBills extends Component {
    static displayName = MailBills.name;

    constructor(props) {
        super(props);
        loadMessages(roMessages);
        locale(navigator.language)
        this.grdRef = React.createRef();

        this.state = {
            EntryId: 0,
            Page: "",
            month: 0
        }

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "MailBills")
        }

        this.auditProps = {
            EntryId: 0,
            Page: ""
        }

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.renderAuditPopup = (EntryId, Page) => {
            if (EntryId !== 0 && EntryId !== null && EntryId && Page && Page !== "") {
                return <PopupComponent info={{ EntryId: EntryId, Page: Page }} />
            }
        }

        this.onInitialized = this.onInitialized.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    }

    onInitialized(e) {
        let queryStrings = queryString.parse(this.props.location.search);
        this.setState({
            month: queryStrings.month
        });

        let captionEstimatedValue = "De facturat ";
        e.component.columnOption('EstimatedValueEUR', 'caption'
            , captionEstimatedValue.concat(monthNames[queryStrings.month.substring(5)], " ", queryStrings.month.substring(0, 4), "(EUR)"));

        let captionInvoiced = "Facturat ";
        e.component.columnOption('Invoiced', 'caption'
            , captionInvoiced.concat(monthNames[queryStrings.month.substring(5)], " ", queryStrings.month.substring(0, 4), "(EUR)"));
    }

    render() {
        return (
            <div>
                <div className="container-datagrid">

                    <DataGrid
                        id="mailBills"
                        ref={this.grdRef}
                        dataSource={GetBillsDataSource(this.state.month)}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        wordWrapEnabled={true}
                        columnAutoWidth={true}
                        width="98.5vw"
                        height="80vh"
                        remoteOperations={true}
                        keyExpr="Id"
                        onInitialized={this.onInitialized}
                        onExporting={onExporting}
                        onToolbarPreparing={this.onToolbarPreparing}
                    >
                        <FilterRow visible={true} />
                        <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Cautare..." />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} showInfo={true} infoText="Pagina {0} din {1} ({2} inregistrari)" />

                        <Column dataField="Id" cssClass="vertical-center" alignment="center" minWidth="10em" visible={false} />

                        <Column dataField="Client" caption="Client" cssClass="vertical-center" alignment="center" minWidth="10em"
                            calculateSortValue="ContractDetail.Contract.Client.Name"
                            calculateFilterExpression={(filterValue, selectedFilterOperations, target) => { return calculateFilterExpresion("ContractDetail.Contract.Client.Name", selectedFilterOperations, filterValue) }} >
                        </Column>

                        <Column dataField="ContractNumber" caption="Contract" cssClass="vertical-center" alignment="center" minWidth="10em"
                            calculateSortValue="ContractDetail.Contract.Number"
                            calculateFilterExpression={(filterValue, selectedFilterOperations, target) => { return calculateFilterExpresion("ContractDetail.Contract.Number", selectedFilterOperations, filterValue) }}>
                        </Column>

                        <Column dataField="ServiceCategoryId" caption="Serviciu" cssClass="vertical-center" alignment="center" minWidth="10em"
                            calculateSortValue="ContractDetail.Contract.ServiceCategoryId"
                            calculateFilterExpression={(filterValue, selectedFilterOperations, target) => { return calculateFilterExpresion("ContractDetail.Contract.ServiceCategoryId", selectedFilterOperations, filterValue) }} >
                            <Lookup dataSource={GetServiceCategoryDataSource} valueExpr="Id" displayExpr="Name" />
                        </Column>

                        <Column dataField="ContractPurpose" caption="Obiect contract" cssClass="vertical-center" alignment="center" minWidth="10em"
                            calculateSortValue="ContractDetail.ContractPurpose"
                            calculateFilterExpression={(filterValue, selectedFilterOperations, target) => { return calculateFilterExpresion("ContractDetail.ContractPurpose", selectedFilterOperations, filterValue) }} >
                        </Column>

                        <Column dataField="EuroTotal" caption="Onorariu (EUR)" cssClass="vertical-center" alignment="center" minWidth="10em" format="#,##0.00" dataType="number"
                            calculateSortValue="ContractDetail.PaymentFeeEur"
                            calculateFilterExpression={(filterValue, selectedFilterOperations, target) => { return calculateFilterExpresion("ContractDetail.PaymentFeeEur", selectedFilterOperations, filterValue) }}>
                        </Column>

                        <Column dataField="PaymentMethodId" caption="Modalitate de plata" cssClass="vertical-center" alignment="center" minWidth="10em"
                            calculateSortValue="ContractDetail.PaymentMethodId"
                            calculateFilterExpression={(filterValue, selectedFilterOperations, target) => { return calculateFilterExpresion("ContractDetail.PaymentMethodId", selectedFilterOperations, filterValue) }}
                        >
                            <Lookup dataSource={GetPaymentMethodDataSource} valueExpr="Id" displayExpr="Payment" />
                        </Column>

                        <Column dataField="Percent" caption="Transa" cssClass="vertical-center" alignment="center" minWidth="10em" dataType="number"
                            allowSorting={true} calculateSortValue="PaymentPercent.Number"
                            calculateFilterExpression={(filterValue, selectedFilterOperations, target) => { return calculateFilterExpresion("PaymentPercent.Number", selectedFilterOperations, filterValue) }}
                        >
                        </Column>

                        <Column dataField="EstimatedValueEUR" cssClass="vertical-center" alignment="center" dataType="number" wordWrapEnabled={true}
                            allowFiltering={false}
                            allowSorting={false}
                            calculateCellValue={function (rowData) { return calculateEuroBillCellValue(rowData); }}
                        />

                        <Column dataField="Adjournment" caption="Amanare facturare" cssClass="vertical-center" alignment="center" minWidth="5em" dataType="number"
                            allowFiltering={false} allowExporting={false}
                            allowSorting={false}
                            cellComponent={AdjournmentComponent}>
                        </Column>

                        <Column dataField="Invoiced" cssClass="vertical-center" alignment="center" minWidth="5em" dataType="number" cellRender={Invoiced}
                            allowFiltering={false} allowSorting={false}>
                        </Column>

                        <Column dataField="ContactPersons" caption="Persoane de contact" cssClass="vertical-center" alignment="center" minWidth="5em" encodeHtml={false}
                            allowFiltering={false} allowSorting={false}
                        >
                        </Column>

                        <Export enabled={true} />

                        <Summary >
                            <TotalItem
                                column="EstimatedValueEUR"
                                showInColumn="EstimatedValueEUR"
                                summaryType="sum"
                                displayFormat="Total : {0}"
                            />
                        </Summary>
                    </DataGrid>

                </div >
            </div>
        );
    }

    onToolbarPreparing(e) {
        let toolbarItems = e.toolbarOptions.items;

        toolbarItems.forEach(function (item) {
            if (item.name === "exportButton") {
                item.location = "before";
                item.icon = "exportButton";
            }
        });

        toolbarItems.push({
            widget: 'dxButton',
            options: {
                icon: 'exportpdf',
                hint: 'Export pdf',
                onClick: function () { OnClickExportPdf(e); }
            },
            location: 'before'
        });
    }
}

export default MailBills;