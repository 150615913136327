import DataSource from 'devextreme/data/data_source';

export const dataSourceDependency = new DataSource({
    load: (loadOptions) => {
        return fetch("/../api/projectdependencies").then(response => response.json())
            .then(response => {
                return response
            })
    },
    byKey: (key) => {
        return fetch('/api/projectdependencies/' + key)
            .then(response => response.json())
            .then(response => {
                return response
            })
    },
    insert: (values) => {
        // console.log('insertde');
        // console.log(values);
        return fetch("/api/projectdependencies", {
            method: "POST",
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
            .then(response => {
                return response
            })
    },
    update: (key, values) => {
        return fetch("/api/projectdependencies/" + key, {
            method: "PUT",
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    },
    remove: (key) => {
        return fetch("/api/projectdependencies/" + key, {
            method: "DELETE",
            body: JSON.stringify({ id: key }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
});
