import CustomStore from 'devextreme/data/custom_store';
import { isNotEmpty, handleErrors } from "Utils/Utils";

export function getBillsDataSource(contractId, startDate, endDate, editingFlag) {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            if (loadOptions.skip === 0) contractId = 0;
            loadOptions.take = Number(loadOptions.take)
            let params = "&";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i])) {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            });
            params = params.slice(0, -1);
            return fetch(`/api/contracts/${contractId}/bills?startDate=${startDate.valueOf() / 1000}&endDate=${endDate.valueOf() / 1000}${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    contractId = response.data[response.data.length - 1].ContractNumber;
                    if (editingFlag) {
                        editingFlag = false;
                    }
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    };
                })
                .catch(err => err);
        },
        update: (key, values) => {
            contractId = 0;
            return fetch("/api/bills/" + key, {
                method: "PUT",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
        }
    })
}

export function getPaymentMethods() {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/paymentmethods${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return response;
                })
                .catch(err => err);
        },
        byKey: (key) => {
            return fetch(`/api/paymentmethods/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response;
                })
        }
    })
}

export function getServiceCategory() {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/servicecategories${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return response;
                })
                .catch(err => err);
        },
        byKey: (key) => {
            return fetch(`/api/servicecategories/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    })
}