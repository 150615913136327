import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import roMessages from "./../../ro.json";
import CustomStore from 'devextreme/data/custom_store';
import { locale, loadMessages } from "devextreme/localization";
import { handleErrors, onRowUpdating, isAuditVisible, isNullOrEmpty } from "Utils/Utils";
import DateBox, { CalendarOptions } from 'devextreme-react/date-box';
import DataGrid, {
    Button,
    Column,
    Editing,
    RequiredRule,
    SearchPanel,
    Scrolling,
    FilterRow,
    Popup
} from 'devextreme-react/data-grid';
import { NumberBox } from 'devextreme-react/number-box';

function getDateByYear(year) {
    if (isNullOrEmpty(year))
        return new Date();
    return new Date(year, 1, 1);
}
export class ContractEvolutionDetail extends Component {
    static displatName = ContractEvolutionDetail.name;

    constructor(props) {
        super(props)
        loadMessages(roMessages);
        locale(navigator.language);

        this.state = {
            contractEvolutionId: props.contractEvolutionId
        };
        this.setAuditEntry = props.auditEntry;
        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "ContractEvolutionDetail")
        }

        this.yearEditorRender = this.yearEditorRender.bind(this);
        this.paymentFeeEUREditorRender = this.paymentFeeEUREditorRender.bind(this);
        this.billingValueEditorRender = this.billingValueEditorRender.bind(this);
        this.onInitNewRow = this.onInitNewRow.bind(this);
    }

    yearEditorRender(cell) {
        return <DateBox
            defaultValue={getDateByYear(cell.value)}
            type="date"
            displayFormat="yyyy"
            pickerType="calendar"
            onValueChanged={(e) => cell.setValue(e.value.getFullYear())}
            {...cell.column.datebox}
        >
            <CalendarOptions zoomLevel="decade"
                minZoomLevel="decade"
                maxZoomLevel="decade" />
        </DateBox>;
    }

    paymentFeeEUREditorRender(cell) {
        let onPaymentFeeEURValueChanged = this.onPaymentFeeEURValueChanged.bind(this, cell);

        return <NumberBox
            id="nbPaymentFeeEUR"
            defaultValue={cell.value}
            showSpinButtons={false}
            format="#,##0.00"
            {...cell.column.numberbox}
            onValueChanged={onPaymentFeeEURValueChanged}
        />;
    }

    onPaymentFeeEURValueChanged(cell, e) {
        cell.setValue(e.value);
    }

    billingValueEditorRender(cell) {
        let onBillingValueChanged = this.onBillingValueChanged.bind(this, cell);

        return <NumberBox
            id="nbBillingValue"
            defaultValue={cell.value}
            showSpinButtons={false}
            format="#,##0.00"
            {...cell.column.numberbox}
            onValueChanged={onBillingValueChanged}
        />;
    }

    onBillingValueChanged(cell, e) {
        cell.setValue(e.value);
    }

    onInitNewRow(e) {
        e.data.Year = getDateByYear(null).getFullYear();
    }

    render() {
        return (
            <DataGrid
                width="100%"
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
                wordWrapEnabled={true}
                columnAutoWidth={true}
                onRowUpdating={onRowUpdating}
                dataSource={this.dataSourceOptions}
                width="93.5vw"
                remoteOperations={false}
                onInitNewRow={this.onInitNewRow}
            >
                <FilterRow visible={true} />
                <Scrolling columnRenderingMode="virtual" mode="infinite" scrollByThumb={true} />
                <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Cautare..." />
                <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} mode="row" useIcons={true}>
                    <Popup title="Contracte evolutie" showTitle={true} />
                </Editing>

                <Column type="buttons" cssClass="vertical-center" width="10em">
                    <Button name="edit" cssClass="underlineBtn" />
                    <Button name="delete" cssClass="underlineBtn" />
                    <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                </Column>

                <Column dataField="Year" caption="An" cssClass="vertical-center" alignment="center" minWidth="10em"
                    editCellRender={this.yearEditorRender}>
                    <RequiredRule message="Camp obligatoriu!" />
                </Column>

                <Column dataField="PaymentFeeEUR" caption="Onorariu ( EUR )" format="#,##0.00" cssClass="vertical-center" alignment="center"
                    dataType="number" wordWrapEnabled={true}
                    editCellRender={this.paymentFeeEUREditorRender}
                />

                <Column dataField="BillingValue" caption="Valoare de facturat" format="#,##0.00" cssClass="vertical-center" alignment="center"
                    dataType="number" wordWrapEnabled={true}
                    editCellRender={this.billingValueEditorRender}

                />

                <Column dataField="ContractEvolutionId" cssClass="vertical-center" alignment="center" visible={false}/>

            </DataGrid>
        )
    }

    dataSourceOptions = new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            return fetch(`/api/contractevolutions/${this.state.contractEvolutionId}/contractevolutiondetails`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return response
                })
                .catch(err => err);
        },
        byKey: (key) => {
            return fetch('/api/contractevolutiondetails/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })

        },
        insert: (values) => {
            values.ContractEvolutionId = this.state.contractEvolutionId;
            return fetch("/api/contractevolutiondetails", {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
        },
        update: (key, values) => {
            return fetch("/api/contractevolutiondetails/" + key, {
                method: "PUT",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
        },
        remove: (key) => {
            return fetch("/api/contractevolutiondetails/" + key, {
                method: "DELETE",
                body: JSON.stringify({ id: key }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        }
    })
}

export default ContractEvolutionDetail;