import React from 'react';
import { CheckBox } from 'devextreme-react/check-box';

export default function Invoiced(cellData) {
    return (
        <div className="dx-field">
            <CheckBox
                readOnly={true}
                defaultValue={cellData.data.Invoiced}
            />
        </div>
    );
}
