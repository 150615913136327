import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import roMessages from "./../../ro.json";
import Form, { Item, GroupItem } from "devextreme-react/form";
import { locale, loadMessages } from "devextreme/localization";
import  ClientRisk  from 'components/RiskAssesmentClient/ClientRisk';

export class ClientRiskComponent extends Component {
    static displayName = ClientRiskComponent.name;

    constructor(props) {
        super(props)
        loadMessages(roMessages);
        locale(navigator.language);
    }

    render() {
        return (
                <Form colCount={1}>
                    <GroupItem caption="Completitudine client">
                        <Item>
                            <ClientRisk />
                        </Item>
                    </GroupItem>
                </Form>
        )
    }
}

export default ClientRiskComponent;