import React from 'react';
import DataGrid, {
    Column,
    Paging,
    Scrolling,
    Selection
} from 'devextreme-react/data-grid';
import DropDownBox from 'devextreme-react/drop-down-box';
import { getClientMiscDataSource, isNullOrEmpty } from "Utils/Utils";

export default class ContactPersonDropDownBoxComponent extends React.Component {
    constructor(props) {
        super(props);
        const clientId = isNullOrEmpty(props.data.row.data.ClientId) ? 0 : props.data.row.data.ClientId;

        this.state = {
            currentValue: props.data.data.ContactPersonId,
            ContactPersons: props.data.value,
            ClientId: props.data.row.data.ClientId,
            selectedRowKeys: props.data.data.ContactPersonId,
            dataSource: getClientMiscDataSource(clientId, "ContactPersons")
        };

        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.dataGridRender = this.dataGridRender.bind(this);
        this.dropDownBoxRef = React.createRef();
        this.gridBox_displayExpr = this.gridBox_displayExpr.bind(this);
        this.onOpened = this.reRender.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data.row.data.ClientId != this.state.ClientId ) {
            this.setState({
                ClientId: prevProps.data.row.data.ClientId,
                selectedRowKeys: [],
                dataSource: getClientMiscDataSource(prevProps.data.row.data.ClientId, "ContactPersons")
            });
        }
    }

    dataGridRender() {
        return (
            <DataGrid
                dataSource={this.state.dataSource}
                keyExpr="Id"
                valueExpr="Id"
                height={250}
                showBorders={true}
                selectedRowKeys={this.state.selectedRowKeys}
                hoverStateEnabled={true}
                onSelectionChanged={this.onSelectionChanged}
                focusedRowEnabled={true}
         
            >
                <Column dataField="Name" caption="Nume" />
                <Column dataField="Position" caption="Functie" />
                <Column dataField="Phone" caption="Telefon" />
                <Column dataField="Email" caption="E-mail" />
                <Paging enabled={true} defaultPageSize={10} />
                <Scrolling mode="virtual" />
                <Selection mode="multiple" showCheckBoxesMode="always" />
            </DataGrid>
        );
    }

    onSelectionChanged(e) {
        this.setState({
            currentValue: e.selectedRowKeys,
            selectedRowKeys: e.selectedRowKeys
        });
        this.props.data.setValue(e.selectedRowKeys);
        this.props.data.data.ContactPersonId = this.state.selectedRowKeys;
     
    }

    gridBox_displayExpr(item) {
        if (!isNullOrEmpty(item))
            return `${item.Name}`;
        else
            return item;
    }

    reRender = () => {
        this.forceUpdate();
    };


    render() {
        const selectedRowKeys = this.state.selectedRowKeys;
        return (
            <DropDownBox
                ref={this.dropDownBoxRef}
                value={selectedRowKeys}
                readOnly={this.props.data.row.data.isCustomAdd}
                onOpened={this.reRender}
                deferRendering={false}
                dataSource={this.state.dataSource}
                placeholder="Selecteaza o persoana..."
                showClearButton={true}
                contentRender={this.dataGridRender}
                displayExpr={this.gridBox_displayExpr}
            />
        );
    }
}