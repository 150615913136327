import React, { Component } from 'react';
import { CheckBox } from 'devextreme-react/check-box';
import '../Contracts/CustomStyle.css';
import dataSourceOptions from "../Contracts/ContractsData";

export class StatusFilterComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedItems: new Map([["cbActive", true], ["cbClosed", false]])
        };

        this.checkBoxClosedRef = React.createRef();
        this.checkBoxActiveRef = React.createRef();
        this.onValueChanged = this.onValueChanged.bind(this);
    }

    onValueChanged(e) {
        const item = e.element.id;
        const isChecked = e.value;

        this.setState(
            prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) })
        );

        this.props.getDataSource(dataSourceOptions(this.state.checkedItems));

        this.props.sendRefGrid.current.instance.refresh();
    }

    render() {
        return (
            <div className="dx-field-all">

                <div className="dx-field-label-Status">
                    <CheckBox
                        ref={this.checkBoxActiveRef}
                        id="cbActive"
                        text="Active"
                        defaultValue={true}
                        value={this.checkBoxActiveRef.current != null ? this.state.checkedItems.get(this.checkBoxActiveRef.current.props.id) : true}
                        onValueChanged={this.onValueChanged}
                    />

                </div>
                <div className="dx-field-label-Status">
                    <CheckBox
                        ref={this.checkBoxClosedRef}
                        id="cbClosed"
                        text="Inactive"
                        defaultValue={false}
                        value={this.checkBoxClosedRef.current != null ? this.state.checkedItems.get(this.checkBoxClosedRef.current.props.id) : false}
                        onValueChanged={this.onValueChanged}
                    />
                </div>

            </div>
        );
    }
}

export default StatusFilterComponent;