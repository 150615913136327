import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import roMessages from "./../../../ro.json";
import { locale, loadMessages } from "devextreme/localization";
import Form, { Item, RequiredRule, CompareRule, ButtonItem, PatternRule, StringLengthRule } from "devextreme-react/form";
import TextBox from "devextreme-react/text-box";
import notify from 'devextreme/ui/notify';
import { Redirect } from 'react-router';

export class ForgotPassword extends Component {

    constructor(props) {
        super(props)
        loadMessages(roMessages);
        locale(navigator.language);

        this.state = {
            redirect: false
        }
        this.formRef = React.createRef();
        this.state = {
            token: null
        }
        this.formRef = React.createRef()
        this.compareRule = (e) => {
            return this.formRef.current.instance.getEditor("NewPassword").option("value");
        }

        this.submitButtonOptions = {
            text: "Schimb parola",
            onClick: (e) => {
                this.submitButtonOnClick(e);
            }
        }

        this.submitButtonOnClick = (e) => {
            var formData = this.formRef.current.instance.option("formData");
            formData["Token"] = this.state.token;
            fetch("/passwords/password", {
                method: "PUT",
                body: JSON.stringify(formData),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                switch (response.status) {
                    case 200:
                        notify("Parola a fost setata cu succes! Ve-ti fi redirectionat catre pagina de autentificare in scurt timp.", "success", 7000);
                        this.setState({
                            redirect: true
                        });
                        break;
                    case 400:
                        notify("Acest link nu este valid!", "error", 3000)
                        break;
                    case 404:
                        notify("Acest link nu este valid sau a fost deja folosit", "error", 3000)
                        break;
                }
                
            })
        }
    }

    render() {
        if (this.state.redirect) {
            return (
                <Redirect to="/Autentificare" />    
            )
        }
        return (
            <div classname="vertical-element">
                <Form
                    ref={this.formRef}
                    showColonAfterLabel={true}
                    labelLocation="left"
                    colCount={1}
                >
                    <Item
                        dataField="NewPassword"
                        editorType="dxTextBox"
                        editorOptions={{ mode: "password" }}
                        label={{ text: "Noua parola" }}
                    >
                        <RequiredRule message="Camp obligatoriu" />
                        <PatternRule pattern='\w*\d\w*' message="Parola trebuie sa contina cel putin o cifra!" />
                        <PatternRule pattern='\w*[A-Z]\w*' message="Parola trebuie sa contina cel putin o litera majuscula!" />
                        <PatternRule pattern='\w*[!#$%&()+-/:;@[\]^_|~]\w*' message="Parola trebuie sa contina cel putin un caracter non-alfanumeric!" />
                        <PatternRule pattern='\w*[^\s]\w*' message="Parola nu trebuie sa contina spatii!" />
                        <StringLengthRule min={6} max={20} message="Parola trebuie sa aiba intre 6 si 24 de caractere!" />
                    </Item>
                    <Item
                        editorType="dxTextBox"
                        editorOptions={{ mode: "password" }}
                        label={{ text: "Confirmare parola" }}
                    >
                        <CompareRule comparisonTarget={this.compareRule} />
                    </Item>
                    <ButtonItem horizontalAlignment="center" buttonOptions={this.submitButtonOptions} />
                </Form>
            </div>
        )
    }

    componentDidMount() {
        const { token } = this.props;
        this.setState({
            token: token
        })
    }
}