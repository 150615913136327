import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import roMessages from "./../../ro.json";
import Form, { Item, GroupItem } from "devextreme-react/form";
import { locale, loadMessages } from "devextreme/localization";
import { UsersGrid } from './UsersGrid/_UsersGrid';

export class Users extends Component {
    static displayName = Users.name;

    constructor(props) {
        super(props)
        loadMessages(roMessages);
        locale(navigator.language);
    }

    render() {
        return (
          
            <Form colCount={1}>
                <GroupItem caption="Utilizatori">
                    <Item>
                        <UsersGrid />
                    </Item>
                </GroupItem>
            </Form>
           
        )
    }
}
