import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { calculateCustomFilterExpresionForExportBills } from '../MailBills/_CalculateCustomFilterExpresion';

export function OnClickExportPdf(e) {

    const doc = new jsPDF('p', 'px', 'a4');
    var selectedRows = calculateCustomFilterExpresionForExportBills(e.component);
    selectedRows.pop();

    e.component.getDataSource().filter([selectedRows]);
    const dataGrid = e.component;

    exportDataGridToPdf({
        jsPDFDocument: doc,
        component: dataGrid,
        customizeCell: function (options) {
            const { gridCell, pdfCell } = options;
            if (gridCell.column.dataField === 'EstimatedValueEUR' || gridCell.column.dataField === 'Invoiced' || gridCell.column.dataField === 'Adjournment') {
                if (gridCell.rowType === 'header') {
                    pdfCell.styles = {
                        cellWidth: 35
                    }
                }
            }

            if (gridCell.column.dataField === 'ContactPersons') {
                if (gridCell.rowType === 'data') {
                    pdfCell.content = gridCell.value.replace("<br>", "\n");
                    pdfCell.styles = {
                        cellWidth: 68,
                        halign: 'left'
                    }
                }
            }
        },

        autoTableOptions: {
            horizontalPageBreak: false,
            theme: "grid",
            styles: {
                fontSize: 8,
                valign: 'middle',
                halign: 'center',
                overflow: 'linebreak',
                cellPadding: 1.5,
                cellWidth: 'wrap'
            },

            headStyles: {
                textColor: [255, 255, 255],
                fontStyle: 'bold',
                fontSize: 8,
                halign: 'center',
                cellWidth: 'auto',
                minCellWidth: 1
            },
            alternateRowStyles: {
                fillColor: [220, 220, 220]
            },
            tableWidth: 'wrap',
            pageBreak: 'auto',
            margin: { left: 10, top: 20, right: 10, bottom: 10 },
            rowPageBreak: 'avoid'
        }
    }).then(() => {
        doc.save('Facturi.pdf');
        e.component.clearFilter();
    });
}
export default OnClickExportPdf;