import React, { Component, Fragment } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import roMessages from "./../../../ro.json";
import { locale, loadMessages } from "devextreme/localization";
import { Form } from 'devextreme-react';
import { Label, Item, GroupItem, EmptyItem } from 'devextreme-react/form';
import { handleErrors, isNotEmpty } from "Utils/Utils";
import { Lookup } from 'devextreme-react';
import { TreeList, FilterRow, RemoteOperations, Column, SearchPanel, HeaderFilter, Editing, RequiredRule, Texts, FilterPanel, Button } from 'devextreme-react/tree-list';
import CustomStore from 'devextreme/data/custom_store';
import { Sidebar } from './../_Sidebar'

const getCompanyIndicatorTypesDataSourceOptions = (lookupRef) => {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            let params = "?";
            [
                "sort",
                "filter",
                "group",
                "parentIds"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/companyindicatortypes${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    if (response.data.length > 0 && !lookupRef.current.instance.option("value")) {
                        lookupRef.current.instance.option("value", response.data[0].Id)
                    }
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    };
                })
                .catch(err => err);
        },
        byKey: (key) => {
            return fetch(`/api/companyindicatortypes/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response;
                })
        }
    })
}

const getCompanyIndicatorsDataSourceOptions = (indicatorType) => {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            if (!indicatorType) {
                return [];
            }

            let params = "?";
            [
                "sort",
                "filter",
                "group",
                "parentIds"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);
            return fetch(`/api/companyindicators/type/${indicatorType}${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    };
                })
                .catch(err => err);
        },
        insert: (values) => {
            values.CompanyIndicatorTypeId = indicatorType;
            return fetch("/api/companyindicators", {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json());
        },

        update: (key, values) => {
            return fetch("/api/companyindicators/" + key, {
                method: "PUT",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json());
        },
        remove: (key) => {
            return fetch("/api/companyindicators/" + key, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        }
    })
}

export class CompanyIndicators extends Component {
    static displayName = CompanyIndicators.name;
    constructor(props) {
        super(props)
        loadMessages(roMessages);
        locale(navigator.language)
        this.state = {
            indicatorType: null
        }
        this.lookupRef = React.createRef();
    }

    render() {
        return (
            <Fragment>
                <Form colCount={1}>
                    <GroupItem caption="Nomenclator indicatori companie" colSpan={1} colCount={4}>
                        <Item colSpan={1}>
                            <Label alignment="top" showColon={true} text="Tip de indicatori" />
                            <Lookup dataSource={getCompanyIndicatorTypesDataSourceOptions(this.lookupRef)} displayExpr="Name" valueExpr="Id"
                                onValueChanged={(e) => { this.setState({ indicatorType: e.value }) }}
                                dropDownOptions={{
                                    title: "Tipuri de indicatori"
                                }}
                                onInitialized={(e) => { if (!e.component.option("value")) e.component.getDataSource().load() }}
                                ref={this.lookupRef}
                            />
                        </Item>
                        <EmptyItem colSpan={3} />
                        <Item colSpan={4}>
                            <TreeList
                                parentIdExpr="ParentId"
                                keyExpr="Id"
                                hasItemsExpr="HasItems"
                                showRowLines={true}
                                showBorders={true}
                                columnAutoWidth={true}
                                wordWrapEnabled={true}
                                searchEnabled={true}
                                dataSource={getCompanyIndicatorsDataSourceOptions(this.state.indicatorType)}
                            >
                                <RemoteOperations filtering={true} sorting={true} grouping={true} />
                                <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Cautare..." />
                                <HeaderFilter visible={true} />
                                <FilterRow visible={true} />
                                <Editing
                                    mode="row"
                                    allowAdding={(e) => { return e.row.data.ParentId === 0 }}
                                    allowUpdating={true}
                                    allowDeleting={true}
                                    useIcons={true}
                                    confirmDelete={true}>
                                    <Texts
                                        deleteRow="Sterg"
                                        addRow="Adaug"
                                        editRow="Modific"
                                        confirmDeleteMessage="Confirmati stergerea inregistrarii?"
                                    />
                                </Editing>
                                <Column type="buttons" width={120}>
                                    <Button name="edit" />
                                    <Button name="delete" />
                                    <Button name="add" />
                                </Column>
                                <Column dataField="Name" caption="Indicator">
                                    <RequiredRule message="Camp obligatoriu!" />
                                </Column>
                            </TreeList>
                        </Item>
                    </GroupItem>
                </Form>
                <div id="auditPopupContainer" />
            </Fragment>
        )
    }
}