import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import DataGrid, {
    Button, Column,
    Editing,
    FilterRow,
    Form, Lookup,
    Popup, RequiredRule,
    SearchPanel,
    Pager, Paging,
    MasterDetail
} from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { loadMessages, locale } from "devextreme/localization";
import React, { Component } from 'react';
import { onRowUpdating, isAuditVisible, isNullOrEmpty, calculateFilterExpresion, GetCustomDate_dd_MM_yyy } from "Utils/Utils";
import roMessages from "./../../ro.json";
import ReactDOM from 'react-dom';
import { GetClientsDataSource, GetProjectDataSource, GetContractsDataSource } from "../Projects/_DataSources";
import ProjectYears from '../Projects/ProjectYears/_ProjectYearsGrid';

export class ProjectGidComponent extends Component {
    static displayName = ProjectGidComponent.name;

    constructor(props) {
        super(props);
        loadMessages(roMessages);
        locale(navigator.language)
        this.grdRef = React.createRef();
        this.contractEditiorRef = React.createRef();

        this.dataSourceClients = GetClientsDataSource();

        this.state = {
            EntryId: 0,
            Page: "",
            ClientId: null,
            dataSourceContracte: null
        }

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "Project")
        }

        this.auditProps = {
            EntryId: 0,
            Page: ""
        }

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.renderAuditPopup = (EntryId, Page) => {
            if (EntryId !== 0 && EntryId !== null && EntryId && Page && Page !== "") {
                return <PopupComponent info={{ EntryId: EntryId, Page: Page }} />
            }
        }

        this.getFilteredContracts = this.getFilteredContracts.bind(this);
        this.loookupContracts_displayExpr = this.loookupContracts_displayExpr.bind(this);
    }

    getFilteredContracts(options) {
        if (!isNullOrEmpty(options) && !isNullOrEmpty(options.data)) 
            return GetContractsDataSource(options.data.ClientId);
        else 
            return [];
    }

    cellTemplate(container, options) {
        container.textContent = options.data.Contract;
    }

    setStateValue(rowData, value) {
        rowData.ClientId = null;
        rowData.ContractId = null;
        this.defaultSetCellValue(rowData, value);
    }

    loookupContracts_displayExpr(item) {
        if (!isNullOrEmpty(item))
            return `${item.Number} / ${GetCustomDate_dd_MM_yyy(item.ContractDetails[0].Date)}`;
        else
            return item;
    }

    render() {
        return (
                    <DataGrid
                        id="projectGrid"
                        ref={this.grdRef}
                        dataSource={GetProjectDataSource()}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        wordWrapEnabled={true}
                        columnAutoWidth={true}
                        width="auto"
                        height="auto"
                        remoteOperations={true}
                        focusedRowEnabled={false}
                        onRowUpdating={onRowUpdating}
                    >
                        <FilterRow visible={true} />
                        <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Cautare..." />
                        <Paging defaultPageSize={10} />
                        <Pager showPageSizeSelector={true} showInfo={true} infoText="Pagina {0} din {1} ({2} inregistrari)" />

                        <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} mode="popup" useIcons={true}>
                            <Form colCount={1} title="Proiecte" />
                            <Popup title="Proiecte" showTitle={true} />
                        </Editing>

                        <MasterDetail
                            enabled={true}
                            component={props => {
                                return (
                                    <ProjectYears projectId={props.data.key} auditEntry={this.setAuditEntry} />
                                )
                            }}
                        />
                        <Column type="buttons" cssClass="vertical-center" width="10em">
                            <Button name="edit" cssClass="underlineBtn" />
                            <Button name="delete" cssClass="underlineBtn" />

                            <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                        </Column>

                        <Column dataField="Name" caption="Nume" cssClass="vertical-center" alignment="center" minWidth="10em">
                            <RequiredRule message="Camp obligatoriu!" />
                        </Column>

                        <Column dataField="ClientId" caption="Client" cssClass="vertical-center" alignment="center" minWidth="10em"
                            setCellValue={this.setStateValue}
                            calculateSortValue="Contract.Client.Id"
                            calculateFilterExpression={(filterValue, selectedFilterOperations, target) => { return calculateFilterExpresion("Contract.Client.Id", selectedFilterOperations, filterValue) }}>
                            <Lookup dataSource={this.dataSourceClients} valueExpr="Id" displayExpr="Name" />
                            <RequiredRule message="Camp obligatoriu!" />
                        </Column>

                        <Column dataField="ContractId" caption="Contract" cssClass="vertical-center" alignment="center" minWidth="10em"
                            cellTemplate={this.cellTemplate}
                            calculateSortValue="Contract.Id"
                            allowFiltering={false}
                            calculateFilterExpression={(filterValue, selectedFilterOperations, target) => { return calculateFilterExpresion("Contract.Id", selectedFilterOperations, filterValue) }}                        >
                            <Lookup dataSource={this.getFilteredContracts} displayExpr={this.loookupContracts_displayExpr} valueExpr="Id" />
                            <RequiredRule message="Camp obligatoriu!" />
                        </Column>

                    </DataGrid>
        );
    }
}
