import CustomStore from 'devextreme/data/custom_store';

export function GetPaymentMethodDataSource() {
    return new CustomStore({
        key: "Id",
        load: loadOptions => {
            return fetch("/../api/paymentmethods").then(response => response.json())
        },
        byKey: (key) => {
            return fetch('/api/paymentmethods/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    })
}
