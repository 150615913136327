import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import roMessages from "./../../ro.json";
import { locale, loadMessages } from "devextreme/localization";
import { Form } from 'devextreme-react';
import ClientsGrid from './_ClientsGrid';
import { GroupItem, Item } from 'devextreme-react/form';

export class Clients extends Component {
    static displayName = Clients.name;

    constructor(props) {
        super(props)
        loadMessages(roMessages);
        locale(navigator.language)
    }

    clientsGrid_onRowPrepared(e) {
        if (e.rowType === "data") {
            e.rowElement.style.height = "7em";
        }
        else {
            if (e.rowType === "header") {
                e.rowElement.style.height = "4em";
            }
        }
    }

    render() {
        return (
            <Form colCount={1}>
                <GroupItem caption="Clienti" colSpan={1} colCount={1}>
                    <Item colSpan={1}>
                        <ClientsGrid />
                    </Item>
                </GroupItem>
            </Form>
        );
    }
}