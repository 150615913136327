import React, { Component } from "react";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import roMessages from "./../../../ro.json";
import { locale, loadMessages } from "devextreme/localization";
import {
    PolarChart,
    CommonSeriesSettings,
    ArgumentAxis,
    Series,
    Tooltip,
    ValueAxis,
    WholeRange
} from "devextreme-react/polar-chart";

export class RiskScoreByClientId extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clientRiskId: 0,
            dataSource: null
        }

        loadMessages(roMessages);
        locale(navigator.language);
        this.redirect = props.redirect;
    }

    scoreSources = [
        { value: 'AverageScore', name: 'Actual', color: "#F8CBAD", type: "area" },
        { value: 'ExcelencyScore', name: 'Scazut', color: "green", type: "line" },
        { value: 'CriticalScore', name: 'Critic', color: "red", type: "line" }
    ];

    render() {
        return (
            <PolarChart
                dataSource={this.state.dataSource}
                useSpiderWeb={true}
                containerBackgroundColor="#00bf29"
            >
                <ArgumentAxis inverted={true} />
                <ValueAxis>
                    <WholeRange startValue={1} endValue={5} inverted={true} />
                </ValueAxis>
                <CommonSeriesSettings argumentField="Measure" />
                {
                    this.scoreSources.map(function (item) {
                        return <Series key={item.value} valueField={item.value} name={item.name} color={item.color} type={item.type} />;
                    })
                }
                <Tooltip enabled={true} />
            </PolarChart>
        );
    }

    async componentDidMount(props) {
        const { clientRiskId } = this.props;
        var serverResponse = await fetch(`/api/clients/${clientRiskId}/riskscore`)
        var dataSource = await serverResponse.json()
        this.setState({
            clientRiskId: clientRiskId,
            dataSource: dataSource
        })
    }
}
