import CustomStore from 'devextreme/data/custom_store';
import { isNotEmpty, handleErrors, isValidNumber } from "Utils/Utils";


export function getClientsDataSource(lookupRef) {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/client${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    if(lookupRef.current){
                        if(response.data.length > 0 && !lookupRef.current.instance.option("value")){
                            lookupRef.current.instance.option("value", response.data[0].Id)
                        }
                    }
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    };
                })
                .catch(err => err);
        },
        byKey: (key) => {
            return fetch('/client/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })

        }
    })
}

export function getCompanyIndicatorsDataSource(indicatorTypeCode){
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            if(!indicatorTypeCode || indicatorTypeCode === ""){
                return [];
            }
            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/companyindicators/type/code/${indicatorTypeCode}${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    };
                })
                .catch(err => err);
        },
        byKey: (key) => {
            return fetch('/api/companyindicators/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })

        }
    })
}

export function getClientCompanyIndicatorsDataSource(clientId, typeCode, startYear, endYear){
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            if(!clientId || !typeCode){
                return [];
            }
            let params = "&";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/clientcompanyindicators/${typeCode}/client/${clientId}?startYear=${startYear}&endYear=${endYear}${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    }
                })
                .catch(err => err)
        },
        remove: (key) => {
            return fetch(`/api/clientcompanyindicators/${key}`, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        },
        update: (key, values) => {
            for(const prop in values){
                if(isValidNumber(prop)){
                    const found = values.ClientCompanyIndicatorYears.find(element => element.Year === parseInt(prop))
                    if(found){
                        found.Value = values[prop]
                    }
                    else{
                        var year = {
                            Year: parseInt(prop),
                            Value: values[prop]
                        }
                        values.ClientCompanyIndicatorYears[values.ClientCompanyIndicatorYears.length] = year;
                        //console.log(year)
                    }
                    delete values[prop]
                }
            }
            values.ClientId = clientId;
            values.UpdateClientCompanyIndicatorYears = values.ClientCompanyIndicatorYears;
            return fetch(`/api/clientcompanyindicators/${key}`, {
                method: "PUT",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
        },
        insert: (values) => {
            var clientYears = []
            for(const prop in values){
                if(isValidNumber(prop)){
                    var year = {
                        Year: parseInt(prop),
                        Value: values[prop]
                    }
                    clientYears[clientYears.length] = year
                    delete values[prop]
                }
            }
            values.ClientId = clientId;
            values.CreateClientCompanyIndicatorYears = clientYears;
            return fetch(`/api/clientcompanyindicators`, {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
        }
    })
}