import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import roMessages from "./../../../ro.json";
import ReactDOM from 'react-dom';
import { onRowUpdating, isAuditVisible } from "Utils/Utils";
import { locale, loadMessages } from "devextreme/localization";
import { calculateFilterExpresion } from "Utils/Utils";
import { getEmployeesDataSource, getEmployeeFunctionsDataSource, getProjectTypesDataSource, getEmployeeTypesDataSource } from "components/Employees/EmployeesGrid/DataSources";
import DataGrid, {
    Button,
    Column,
    Editing,
    RequiredRule,
    SearchPanel,
    Lookup,
    Scrolling,
    FilterRow,
    FormItem,
    Form,
    Popup,
    Format,
    AsyncRule,
    StringLengthRule
} from 'devextreme-react/data-grid';

export class EmployeesGrid extends Component {
    static displayName = EmployeesGrid.name;

    constructor(props) {
        super(props)
        loadMessages(roMessages);
        locale(navigator.language);
        this.state = {
        }
        this.grdRef = React.createRef();
        this.editing = false;
        this.auditProps = {
            EntryId: 0,
            Page: ""
        }

        this.employeeCodeValidation = (e) => {
            return fetch(`/api/employees/employeecode?employeeCode=${e.value}`)
                .then(response => response.json())
                .then(response => {
                    return response;
                })
        }

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "Employees");
        }

        this.decimalEditorOptions = {
            format: {
                type: "fixedPoint",
                precision: 2
            }
        }

        this.nameChange = {
            firstName: "",
            lastName: ""
        }

        this.setCellValue = (newData, value, prop, previousData) => {
            if (this.editing) {
                return;
            }

            this.nameChange[prop] = value;
            if (previousData.EmployeeCode) {
                return;
            }

            if (this.nameChange.firstName !== "" && this.nameChange.lastName !== "") {
                newData.EmployeeCode = `${this.nameChange.lastName.substring(0, 1)}${this.nameChange.firstName.substring(0, 2).toUpperCase()}`
            }
        }

        this.resetNameChange = () => {
            this.nameChange = {
                firstName: "",
                lastName: ""
            }
        }
    }

    render() {
        return (
                <DataGrid
                    id="employeesGrd"
                    ref={this.grdRef}
                    dataSource={getEmployeesDataSource()}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    wordWrapEnabled={true}
                    columnAutoWidth={true}
                    remoteOperations={true}
                    onRowUpdating={onRowUpdating}
                    onRowUpdated={() => { this.editing = false; }}
                    onEditingStart={() => { this.editing = true; this.resetNameChange(); }}
                    onEditCanceled={() => { this.editing = false; }}
                    onRowInserted={() => { this.resetNameChange(); }}
                >
                    <FilterRow visible={true} />
                    <Scrolling columnRenderingMode="standard" mode="standard" scrollByThumb={true} useNative={true} />
                    <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Cautare..." />
                    <Editing allowAdding={true} allowDeleting={true} allowUpdating={true} mode="popup" useIcons={true}>
                        <Form colCount={1} title="Angajati" />
                        <Popup title="Angajati" showTitle={true} onHiding={this.beforeBillingUpdate} minWidth="20em" maxWidth="40em" height="auto" />
                    </Editing>
                    <Column type="buttons" cssClass="vertical-center" minWidth="5em">
                        <Button name="edit" cssClass="underlineBtn" />
                        <Button name="delete" cssClass="underlineBtn" />
                        <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                    </Column>
                    <Column dataField="Id" visible={false} allowEditing={false} showEditorAlways={false}>
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="LastName" caption="Nume" visible={true} cssClass="vertical-center" alignment="center" minWidth="10em"
                        setCellValue={(newData, value, currentRowData) => { newData.LastName = value; if (!this.editing) { this.setCellValue(newData, value, "lastName", currentRowData) } }}
                    >
                        <RequiredRule message="Camp obligatoriu!" />
                        <StringLengthRule message="Numele trebuie sa aiba maxim 30 de caractere" max={30} />
                    </Column>
                    <Column dataField="FirstName" caption="Prenume" visible={true} cssClass="vertical-center" alignment="center" minWidth="10em"
                        setCellValue={(newData, value, currentRowData) => { newData.FirstName = value; if (!this.editing) { this.setCellValue(newData, value, "firstName", currentRowData) } }}
                    >
                        <RequiredRule message="Camp obligatoriu!" />
                        <StringLengthRule message="Prenumele trebuie sa aiba maxim 50 caractere" max={50} />
                    </Column>
                    <Column dataField="EmployeeCode" caption="Identificator (Marca)" cssClass="vertical-center" alignment="center" minWidth="10em">
                        <RequiredRule message="Camp obligatoriu!" />
                        <AsyncRule message="Idetificatorul acesta este deja folosit!" validationCallback={this.employeeCodeValidation} />
                        <StringLengthRule message="Identificatorul trebuie sa aiba maxim 5 caractere" max={5} />
                    </Column>
                    <Column dataField="EmployeeFunctionId" caption="Functie" cssClass="vertical-center" alignment="center" minWidth="10em"
                        calculateSortValue="EmployeeFunction.Name"
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => { return calculateFilterExpresion("EmployeeFunction.Id", selectedFilterOperations, filterValue) }}
                    >
                        <RequiredRule message="Camp obligatoriu!" />
                        <Lookup dataSource={getEmployeeFunctionsDataSource()} valueExpr="Id" displayExpr="Name" />
                    </Column>
                    <Column dataField="EmployeeTypeId" caption="Entitate" cssClass="vertical-center" alignment="center" minWidth="10em"
                        calculateSortValue="EmployeeType.Name"
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => { return calculateFilterExpresion("EmployeeType.Id", selectedFilterOperations, filterValue) }}
                    >
                        <RequiredRule message="Camp obligatoriu!" />
                        <Lookup dataSource={getEmployeeTypesDataSource()} valueExpr="Id" displayExpr="Name" />
                    </Column>
                    <Column dataField="ProjectTypeId" caption="Tipuri de Proiecte" cssClass="vertical-center" alignment="center" minWidth="10em"
                        calculateSortValue="ProjectType.Name"
                        calculateFilterExpression={(filterValue, selectedFilterOperations, target) => { return calculateFilterExpresion("ProjectType.Id", selectedFilterOperations, filterValue) }}
                    >
                        <RequiredRule message="Camp obligatoriu!" />
                        <Lookup dataSource={getProjectTypesDataSource()} valueExpr="Id" displayExpr="Name" />
                    </Column>
                    <Column dataField="HourlyPay" caption="Rata Orara" cssClass="vertical-center" alignment="center" minWidth="10em"
                        editorOptions={this.decimalEditorOptions}
                    >
                        <RequiredRule message="Camp obligatoriu!" />
                        <Format type="fixedPoint" precision={2} />
                    </Column>
                </DataGrid>
        )
    }
}