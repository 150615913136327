import CustomStore from 'devextreme/data/custom_store';
import { isNotEmpty, handleErrors, GetCustomDate_yyyy_MM_dd } from "Utils/Utils";

function getDateMonth(month) {
    return new Date(month.substring(0, 4) , month.substring(5, 6), 1);
}

export function GetBillsDataSource(month) {

    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/bills${params}&month=${GetCustomDate_yyyy_MM_dd(getDateMonth(month))}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    var summaryValue = 0.00;
                    response.data.forEach((i) => (summaryValue += parseFloat(i.EstimatedValueEUR)));

                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: [summaryValue.toFixed(2)],
                        groupCount: response.groupCount
                    };
                })
                .catch(err => err);
        }
    })
}

