import React, { Component } from 'react';
import { handleErrors, isNotEmpty, isAuditVisible } from "Utils/Utils";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import CustomStore from 'devextreme/data/custom_store';
import roMessages from "./../../ro.json";
import { locale, loadMessages } from "devextreme/localization"
import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import Sidebar from './_Sidebar'
import DataGrid, {
    Button,
    Column,
    Editing,
    SearchPanel,
    RequiredRule
} from 'devextreme-react/data-grid';

const dataSourceOptions = new CustomStore({
    key: "Id",
    load: (loadOptions) => {
        let params = "?";
        [
            "skip",
            "take",
            "requireTotalCount",
            "sort",
            "filter"
        ].forEach(function (i) {
            if (i in loadOptions && isNotEmpty(loadOptions[i]))
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        });

        params = params.slice(0, -1);
        return fetch(`/ClientGroup${params}`)
            .then(handleErrors)
            .then(response => response.json())
            .then(response => {
                return {
                    data: response.data,
                    totalCount: response.totalCount,
                    summary: response.summary,
                    groupCount: response.groupCount
                };
            })
            .catch(err => err);
    },
    insert: (values) => {
        return fetch("/ClientGroup", {
            method: "POST",
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    },
    update: (key, values) => {
        return fetch("/ClientGroup/" + key, {
            method: "PUT",
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    },
    remove: (key) => {
        return fetch("/ClientGroup/" + key, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
})


export class ClientGroup extends Component {
    static displayName = ClientGroup.name;
    constructor(props) {
        super(props)
        loadMessages(roMessages);
        locale(navigator.language)
        this.grdRef = React.createRef();
        this.state = {
            EntryId: 0
        }
        this.setAuditEntry = (e) => {
            this.setState({ EntryId: e.row.key });
        }

        this.renderAuditPopup = (EntryId) => {
            if (EntryId !== 0 && EntryId !== null && EntryId) {
                sessionStorage.setItem("showAudit", true)
                return <PopupComponent info={{ EntryId: EntryId, Page: "ClientGroup" }} />
            }
        }
    }

    render() {
        return (
            <div className="container-nomenclatures">
                <Sidebar />
                <div className="content-container">
                    <DataGrid
                        ref={this.grdRef}
                        width="100%"
                        dataSource={dataSourceOptions}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                        id="grdLocatii"
                        remoteOperations={true}
                    >
                        <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Cautare..." />
                        <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} mode="row" useIcons={true} />
                        <Column type="buttons" width={120}>
                            <Button name="edit" />
                            <Button name="delete" />
                            <Button icon="info" hint="Audit" onClick={this.setAuditEntry} visible={isAuditVisible}/>
                        </Column>
                        <Column dataField="Id" visible={false} allowEditing={false} />
                        <Column dataField="Name" caption="Grup de firme">
                            <RequiredRule message="Camp obligatoriu!" />
                        </Column>
                    </DataGrid>
                </div>
                {this.renderAuditPopup(this.state.EntryId)}
            </div>
        );
    }
}