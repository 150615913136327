import React, { Component, Fragment } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import roMessages from "./../../ro.json";
import Form, { Item, GroupItem, EmptyItem, Label } from "devextreme-react/form";
import { locale, loadMessages } from "devextreme/localization";
import { DateBox } from 'devextreme-react';
import { CalendarOptions } from 'devextreme-react/date-box';
import { TimesheetGrid } from './TimesheetGrid/_TimesheetGrid';

const setDate = (component, date) => {
    component.option("value", date)
}

export class EmployeeTimesheet extends Component {
    static displayName = EmployeeTimesheet.name;

    constructor(props){
        super(props)
        loadMessages(roMessages);
        locale(navigator.language);
        this.dateBoxRef = React.createRef();

        this.dateBoxOnValueChanged = (e) => {
            this.setState({
                timesheetMonth: e.value
            });
        }

        this.state = {
            timesheetMonth: new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        }
    }

    render() {
        return (
            <Fragment>
                <Form colCount={1}>
                    <GroupItem caption="Timesheet angajati" colSpan={1} colCount={2}>
                        <GroupItem colSpan={1} colCount={4}>
                            <Item colSpan={1}>
                                <Label alignment="top" showColon={true} text="Luna" />
                                <DateBox displayFormat="MM.yyyy" ref={this.dateBoxRef} onValueChanged={this.dateBoxOnValueChanged}
                                    onInitialized={(e) => setDate(e.component, this.state.timesheetMonth)}
                                >
                                    <CalendarOptions maxZoomLevel="year" minZoomLevel="decade" zoomLevel="year" />
                                </DateBox>
                            </Item>
                        </GroupItem>
                        <EmptyItem colSpan={1} />
                        <Item colSpan={2}>
                            <TimesheetGrid StartDate={this.state.timesheetMonth} />
                        </Item>
                    </GroupItem>
                </Form>
                <div id="auditPopupContainer" />
                <div id="editPopupContainer" />
                <div id="addPopupContainer" />
            </Fragment>
        )
    }

}