export function getColumnCaption(date) {
    var newDate;
    if (typeof (date) === typeof (0)) {
        newDate = new Date(date)
    }
    else newDate = date;

    return `Facturare ${getMonthFromDate(newDate)} ${getYearFromDate(newDate)} (RON)`;
}

export function getDateInterval(startDate, endDate) {
    startDate = new Date(startDate);
    endDate = incrementDateByMonth(new Date(endDate));
    var dateList = [];
    var index = 0;
    while (!(startDate.getMonth() == ((endDate.getMonth() + 1) % 13) - 1 && startDate.getFullYear() == endDate.getFullYear())) {
        var date = startDate.valueOf();
        dateList[index] = { date: new Date(date), id: index };
        index += 1;
        startDate = incrementDateByMonth(startDate);
    }
    return dateList;
}

function getMonthFromDate(date) {
    var monthDict = getMonthDict()
    return monthDict[(date.getMonth())]
}

function getYearFromDate(date) {
    return (date.getYear() + 1900).toString();
}

function getMonthDict() {
    return {
        0: "Jan.",
        1: "Feb.",
        2: "Mar.",
        3: "Apr.",
        4: "May.",
        5: "Jun.",
        6: "Jul.",
        7: "Aug.",
        8: "Sep.",
        9: "Oct.",
        10: "Nov.",
        11: "Dec."
    }
}

function incrementDateByMonth(date) {
    date.setMonth(date.getMonth() + 1);
    return new Date(date);
}