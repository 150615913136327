import CustomStore from 'devextreme/data/custom_store';
import { isNotEmpty, handleErrors, isValidDate } from "Utils/Utils";

export function getEmployeesDataSource() {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/employees${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return response.data;
                })
                .catch(err => err);
        },
        byKey: (key) => {
            return fetch('/api/employees/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })

        }
    })
}

export function getClientsDataSource(){
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/client${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return response.data;
                })
                .catch(err => err)
        },
        byKey: (key) => {
            return fetch(`/client/${key}`)
                .then(response => response.json())
                .then(response => {
                    return response;
                })
        }
    })
}

export function getEmployeeTimesheets(date){
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            let params = "&";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/employeetimesheets?month=${getDateFormat(date.valueOf())}${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    };
                })
                .catch(err => err)
        },
        remove: (key) => {
            return fetch(`/api/employeetimesheets/${key}`, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        },
        update: (key, values) => {
            for(const prop in values){
                if(isValidDate(new Date(prop))){
                    const found = values.EmployeeTimesheetHours.find(element => new Date(element.Date).valueOf() === new Date(prop).valueOf())
                    if(found){
                        found.Hour = values[prop]
                    }
                    else{
                        var hour = {
                            Date: new Date(prop).toISOString(),
                            Hour: values[prop]
                        }
                        values.EmployeeTimesheetHours[values.EmployeeTimesheetHours.length] = hour;
                    }
                    delete values[prop]
                }
            }
            values.UpdateEmployeeTimesheetHours = values.EmployeeTimesheetHours;
            delete values.EmployeeTimesheetHours;
            return fetch(`/api/employeetimesheets/${key}`, {
                method: "PUT",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
        }
    });
}

function getDateFormat(date){
    return `${new Date(date).getFullYear()}${(new Date(date).getMonth() + 1).toString().padStart(2, "0")}`;
}