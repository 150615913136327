import React from 'react';
import { handleErrors, isNotEmpty, isNullOrEmpty } from "Utils/Utils";
import DataGrid, {
    Column,
    Paging,
    Scrolling,
    Selection
} from 'devextreme-react/data-grid';
import DropDownBox from 'devextreme-react/drop-down-box';
import CustomStore from 'devextreme/data/custom_store';
const dropDownOptions = { width: 500 };



export default class MeasuresDropDownComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentValue: props.data.data.MeasureIds,
            Measures: props.data.value,          
            selectedRowKeys: props.data.data.MesureIds,
            dataSource: dataSourceOptions
        };
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.dataGridRender = this.dataGridRender.bind(this);
        this.dropDownBoxRef = React.createRef();
        this.gridBox_displayExpr = this.gridBox_displayExpr.bind(this);   
        this.onOpened = this.reRender.bind(this);

    }

    dataGridRender() {
        return (
            <DataGrid
                dataSource={this.state.dataSource}
                keyExpr="Id"
                valueExpr="Id"
                height={250}
                showBorders={true}
                selectedRowKeys={this.state.selectedRowKeys}
                hoverStateEnabled={true}
                onSelectionChanged={this.onSelectionChanged}
                focusedRowEnabled={true}
                defaultFocusedRowKey={this.state.currentValue}
            >
                <Column dataField="Name" />               
                <Paging enabled={true} defaultPageSize={10} />
                <Scrolling mode="virtual" />
                <Selection mode="multiple" showCheckBoxesMode="always" />
            </DataGrid>
        );
    }



    onSelectionChanged(e) {
        this.setState({
            currentValue: e.selectedRowKeys,
            selectedRowKeys: e.selectedRowKeys
        });
        this.props.data.setValue(e.selectedRowKeys);
        this.props.data.data.MeasureIds = this.state.selectedRowKeys;
    }

    gridBox_displayExpr(item) {
        if (!isNullOrEmpty(item))
            return `${item.Name}`;
        else
            return item;
    }

    reRender = () => {
        this.forceUpdate();
    };


    render() {
        const selectedRowKeys = this.state.selectedRowKeys;

        return (
            <DropDownBox
                ref={this.dropDownBoxRef}
                value={selectedRowKeys}
                deferRendering={false}
                onOpened={this.reRender}

                dataSource={this.state.dataSource}
                showClearButton={true}
                contentRender={this.dataGridRender}
                contentRender={this.dataGridRender}
                displayExpr={this.gridBox_displayExpr}
            />
        );
    }

}
const dataSourceOptions = new CustomStore({
    key: "Id",
    load: (loadOptions) => {
        let params = "?";
        [
            "skip",
            "take",
            "requireTotalCount",
            "sort",
            "filter"
        ].forEach(function (i) {
            if (i in loadOptions && isNotEmpty(loadOptions[i]))
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        });

        params = params.slice(0, -1);

        return fetch(`/measures${params}`)
            .then(handleErrors)
            .then(response => response.json())
            .then(response => {
                return {
                    data: response.data,
                    totalCount: response.totalCount,
                    summary: response.summary,
                    groupCount: response.groupCount
                };
            })
            .catch(err => err);
    },
    byKey: (key) => {
        return fetch('/measures/' + key)
            .then(response => response.json())
            .then(response => {
                return response
            })

    }

});