import React, { Component, Fragment } from 'react';
import { onRowUpdating, isNotEmpty, handleErrors, isAuditVisible, multiLineCell } from "Utils/Utils";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import CustomStore from 'devextreme/data/custom_store';
import roMessages from "./../../../ro.json";
import { locale, loadMessages } from "devextreme/localization";
import { ControlCategoryQuestions } from "./ControlCategoryQuestions/_ControlCategoryQuestions";
import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import TextArea from 'devextreme-react/text-area';
import Sidebar from './../_Sidebar'
import DataGrid, {
    FilterRow,
    Scrolling,
    Form,
    Popup,
    FormItem,
    Button,
    Column,
    Editing,
    SearchPanel,
    MasterDetail,
    RequiredRule,
    NumericRule,
    RangeRule
} from 'devextreme-react/data-grid';


const riskImpactDataSourceOptions = new CustomStore({
    key: "Id",
    load: (loadOptions) => {
        let params = "?";
        [
            "skip",
            "take",
            "requireTotalCount",
            "sort",
            "filter"
        ].forEach(function (i) {
            if (i in loadOptions && isNotEmpty(loadOptions[i]))
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        });

        params = params.slice(0, -1);

        return fetch(`/riskimpacts${params}`)
            .then(handleErrors)
            .then(response => response.json())
            .then(response => {
                return {
                    data: response.data,
                    totalCount: response.totalCount,
                    summary: response.summary,
                    groupCount: response.groupCount
                };
            })
            .catch(err => err);
    },
    byKey: (key) => {
        return fetch('/riskimpacts/' + key)
            .then(response => response.json())
            .then(response => {
                return response
            })

    }
})

const riskProbabilityDataSourceOptions = new CustomStore({
    key: "Id",
    load: (loadOptions) => {
        let params = "?";
        [
            "skip",
            "take",
            "requireTotalCount",
            "sort",
            "filter"
        ].forEach(function (i) {
            if (i in loadOptions && isNotEmpty(loadOptions[i]))
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        });

        params = params.slice(0, -1);

        return fetch(`/riskprobabilities${params}`)
            .then(handleErrors)
            .then(response => response.json())
            .then(response => {
                return {
                    data: response.data,
                    totalCount: response.totalCount,
                    summary: response.summary,
                    groupCount: response.groupCount
                };
            })
            .catch(err => err);
    },
    byKey: (key) => {
        return fetch('/riskprobabilities/' + key)
            .then(response => response.json())
            .then(response => {
                return response
            })

    }
})

export class ControlCategories extends Component {
    static displayName = ControlCategories.name;
    constructor(props) {
        super(props);
        loadMessages(roMessages);
        locale(navigator.language)
        this.treeListRef = React.createRef();
        this.state = {
            EntryId: 0,
            Page: "",
            MaxPercent: 100
        }

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "ControlCategory")
        }

        this.setAuditEntry = (EntryId, Page) => {
            this.setState({ EntryId: EntryId, Page: Page });
        }

        this.getMaxPercent = () => {
            return fetch("/api/controlcategories/percent").then(response => response.text())
                .then(response => {
                    this.setState({
                        MaxPercent: Number(response)
                    });
                });
        }

        this.renderAuditPopup = (EntryId, Page) => {
            if (EntryId !== 0 && Page !== "") {
                return <PopupComponent info={{ EntryId: EntryId, Page: Page }} />
            }
        }

        this.onEditingStart = (e) => {
            this.getMaxPercent();
        }

        this.dataSource = new CustomStore({
            key: "Id",
            load: (loadOptions) => {
                let params = "?";
                [
                    "skip",
                    "take",
                    "requireTotalCount",
                    "sort",
                    "filter"
                ].forEach(function (i) {
                    if (i in loadOptions && isNotEmpty(loadOptions[i]))
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                });

                params = params.slice(0, -1);

                return fetch(`/api/controlcategories${params}`)
                    .then(handleErrors)
                    .then(response => response.json())
                    .then(response => {
                        return {
                            data: response.data,
                            totalCount: response.totalCount,
                            summary: response.summary,
                            groupCount: response.groupCount
                        };
                    })
                    .catch(err => err);
            },
            byKey: (key) => {
                return fetch('/api/controlcategories/' + key)
                    .then(response => response.json())
                    .then(response => {
                        return response
                    })

            },
            insert: (values) => {
                return fetch("/api/controlcategories", {
                    method: "POST",
                    body: JSON.stringify(values),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json())
            },
            update: (key, values) => {
                return fetch("/api/controlcategories/" + key, {
                    method: "PUT",
                    body: JSON.stringify(values),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json())
            },
            remove: (key) => {
                return fetch("/api/controlcategories/" + key, {
                    method: "DELETE",
                    body: JSON.stringify({ id: key }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
            }
        })

    }

    render() {
        return (
            <Fragment>
                <DataGrid
                    onRowUpdating={onRowUpdating}
                    wordWrapEnabled={true}
                    columnAutoWidth={true}
                    dataSource={this.dataSource}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    onEditingStart={this.onEditingStart}
                    height="auto"
                    width="auto"
                >
                    <FilterRow visible={true} />
                    <Scrolling columnRenderingMode="virtual" mode="infinite" scrollByThumb={true} />
                    <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Cautare..." />
                    <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} mode="popup" useIcons={true}>
                        <Form colCount={1} title="Categorii de control" />
                        <Popup title="Categorii de control" showTitle={true} minWidth="20em" maxWidth="40em" height="auto" />
                    </Editing>
                    <MasterDetail
                        enabled={true}
                        component={props => {
                            return (
                                <ControlCategoryQuestions controlCategoryId={props.data.key} auditEntry={this.setAuditEntry} />
                            )
                        }}
                    />
                    <Column type="buttons" cssClass="vertical-center" width="10em">
                        <Button name="edit" cssClass="underlineBtn" />
                        <Button name="delete" cssClass="underlineBtn" />
                        <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                    </Column>
                    <Column dataField="Id" visible={false} allowEditing={false} showEditorAlways={false}>
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="Name" caption="Categorie de control" cssClass="vertical-center" alignment="center" width="15em">
                        <RequiredRule message="Camp obligatoriu!" />
                    </Column>
                    <Column dataField="Percent" caption="Pondere" cssClass="vertical-center" alignment="center" width="5em"
                        calculateDisplayValue={function (e) { return e.Percent + "%" }}
                        editorType="dxNumberBox"
                    >
                        <RequiredRule message="Camp obligatoriu!" />
                        <NumericRule message="Valoarea trebuie sa fie numerica!" />
                        <RangeRule min={1} max={this.state.MaxPercent} message="Suma ponderelor trenuie sa fie cel mult 100%!" />
                    </Column>
                    <Column dataField="InherentRisk" caption="Risc inerent" cssClass="vertical-center" alignment="center" minWidth="10em"
                        cellRender={function (e) { return multiLineCell(e.data.InherentRisk); }} editorType="dxTextArea">
                        <RequiredRule message="Camp obligatoriu!" />
                    </Column>
                </DataGrid>
                {this.renderAuditPopup(this.state.EntryId, this.state.Page)}
            </Fragment>
        )
    }
}

export default ControlCategories;