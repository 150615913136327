import React, { Component, Fragment } from 'react';
import { validatePhoneNumber, onRowUpdating, isNotEmpty, handleErrors, isAuditVisible } from "Utils/Utils";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import CustomStore from 'devextreme/data/custom_store';
import roMessages from "./../../ro.json";
import { locale, loadMessages } from "devextreme/localization";
import DataGrid, {
    Button,
    Column,
    Editing,
    RequiredRule,
    SearchPanel,
    Lookup,
    Scrolling,
    FilterRow,
    MasterDetail,
    EmailRule,
    CustomRule,
    FormItem,
    Popup,
    Form
} from 'devextreme-react/data-grid';

import ClientsMasterDetail from './_ClientsMasterDetail.js';

const tagBoxOptions = {
    onInitialized: function (e) {
        e.component.option("openOnFieldClick", false)
    },
    height: "auto",
    visible: true,
    id: "affiliatedPartsTagBox",
    acceptCustomValue: true
};

const dataSourceOptions = new CustomStore({
    key: "Id",
    load: (loadOptions) => {
        let params = "?";
        [
            "skip",
            "take",
            "requireTotalCount",
            "sort",
            "filter"
        ].forEach(function (i) {
            if (i in loadOptions && isNotEmpty(loadOptions[i]))
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        });

        params = params.slice(0, -1);

        return fetch(`/Client${params}`)
            .then(handleErrors)
            .then(response => response.json())
            .then(response => {
                return {
                    data: response.data,
                    totalCount: response.totalCount,
                    summary: response.summary,
                    groupCount: response.groupCount
                };
            })
            .catch(err => err);
    },
    byKey: (key) => {
        return fetch('/Client/' + key)
            .then(response => response.json())
            .then(response => {
                return response
            })

    },
    insert: (values) => {
        return fetch("/Client", {
            method: "POST",
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    },
    update: (key, values) => {
        return fetch("/Client/" + key, {
            method: "PUT",
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    },
    remove: (key) => {
        return fetch("/Client/" + key, {
            method: "DELETE",
            body: JSON.stringify({ id: key }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
})

const locationDataSourceOptions = new CustomStore({
    key: "Id",
    load: (loadOptions) => {
        let params = "?";
        [
            "skip",
            "take",
            "requireTotalCount",
            "sort",
            "filter"
        ].forEach(function (i) {
            if (i in loadOptions && isNotEmpty(loadOptions[i]))
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        });

        params = params.slice(0, -1);

        return fetch(`/../Locality${params}`)
            .then(handleErrors)
            .then(response => response.json())
            .then(response => {
                return {
                    data: response.data,
                    totalCount: response.totalCount,
                    summary: response.summary,
                    groupCount: response.groupCount
                };
            })
            .catch(err => err);
    },
    byKey: (key) => {
        return fetch("/../Locality/" + key)
            .then(response => { return response.json(); })
    }
})

const clientGroupsDataSourceOptions = new CustomStore({
    key: "Id",
    load: (loadOptions) => {
        let params = "?";
        [
            "skip",
            "take",
            "requireTotalCount",
            "sort",
            "filter"
        ].forEach(function (i) {
            if (i in loadOptions && isNotEmpty(loadOptions[i]))
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        });

        params = params.slice(0, -1);

        return fetch(`/ClientGroup${params}`)
            .then(handleErrors)
            .then(response => response.json())
            .then(response => {
                return {
                    data: response.data,
                    totalCount: response.totalCount,
                    summary: response.summary,
                    groupCount: response.groupCount
                };
            })
            .catch(err => err);
    },
    byKey: (key) => {
        return fetch("/ClientGroup/" + key)
            .then(response => response.json())
            .then(response => {
                return response;
            })
    }
})

const CAENCodeDataSourceOptions = new CustomStore({
    key: "Id",
    load: (loadOptions) => {
        let params = "?";
        [
            "skip",
            "take",
            "requireTotalCount",
            "sort",
            "filter"
        ].forEach(function (i) {
            if (i in loadOptions && isNotEmpty(loadOptions[i]))
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        });

        params = params.slice(0, -1);

        return fetch(`/CAENCode${params}`)
            .then(handleErrors)
            .then(response => response.json())
            .then(response => {
                return {
                    data: response.data,
                    totalCount: response.totalCount,
                    summary: response.summary,
                    groupCount: response.groupCount
                };
            })
            .catch(err => err);
    },
    byKey: (key) => {
        return fetch("/caencode/" + key)
            .then(response => response.json())
            .then(response => {
                return response;
            })
    }
})

const statusDataSourceOptions = new CustomStore({
    key: "Id",
    load: () => {
        return fetch(`/ClientStatus`)
            .then(handleErrors)
            .then(response => response.json());
    },
    byKey: (key) => {
        return fetch("/ClientStatus/" + key)
            .then(response => response.json())
            .then(response => {
                return response;
            })
    }
})

export class ClientsGrid extends Component{
    constructor(props) {
        super(props)
        loadMessages(roMessages);
        locale(navigator.language)
        this.state = {
            EntryId: 0,
            Page: ""
        }
        this.Page = "";
        this.EntryId = 0;
        this.setAuditEntry = (Key, Page) => {
            this.setState({
                EntryId: Key,
                Page: Page
            })
        }

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "Client");
        }

        this.renderAuditPopup = (EntryId, Page) => {
            if (EntryId !== 0 && EntryId !== null && EntryId && Page && Page !== "") {
                return <PopupComponent info={{ EntryId: EntryId, Page: Page }} />
            }
        }
        this.clientsGrdRef = React.createRef();
        this.getRef = () => {
            return this.clientsGrdRef;
        }
    }

    clientsGrid_onRowPrepared(e) {
        if (e.rowType === "data") {
            e.rowElement.style.height = "7em";
        }
        else {
            if (e.rowType === "header") {
                e.rowElement.style.height = "4em";
            }
        }
    }

    render() {
        return (
            <Fragment>
                <DataGrid
                    id="clientsGrid"
                    dataSource={dataSourceOptions}
                    showBorders={true}
                    ref={this.clientsGrdRef}
                    showColumnLines={true}
                    showRowLines={true}
                    onRowPrepared={this.clientsGrid_onRowPrepared}
                    onRowUpdating={onRowUpdating}
                    wordWrapEnabled={true}
                    columnAutoWidth={true}
                    width="auto"
                    height="auto"
                >
                    <FilterRow visible={true} />
                    <Scrolling columnRenderingMode="virtual" mode="infinite" scrollByThumb={true} />
                    <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Cautare..." />
                    <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} mode="popup" useIcons={true}>
                        <Form colCount={1} title="Clienti" />
                        <Popup title="Clienti" showTitle={true} />
                    </Editing>
                    <MasterDetail
                        enabled={true}
                        component={props => {
                            return <ClientsMasterDetail info={props} func={this.setAuditEntry} />
                        }}  
                    />
                    <Column type="buttons" cssClass="vertical-center" minWidth="5em">
                        <Button name="edit" cssClass="underlineBtn" />
                        <Button name="delete" cssClass="underlineBtn" />
                        <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                    </Column>
                    <Column dataField="Id" visible={false} allowEditing={false} showEditorAlways={false}>
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="UniqueId" caption="Identificator unic" cssClass="vertical-center" alignment="center" minWidth="10em">
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="Name" caption="Nume" cssClass="vertical-center" alignment="center" minWidth="10em">
                        <RequiredRule message="Camp obligatoriu!" />
                    </Column>
                    <Column dataField="ClientGroupId" caption="Grup" cssClass="vertical-center" alignment="center" minWidth="10em">
                        <RequiredRule message="Camp obligatoriu!" />
                        <Lookup dataSource={clientGroupsDataSourceOptions} valueExpr="Id" displayExpr="Name" />
                    </Column>
                    <Column dataField="ClientStatusId" caption="Status" cssClass="vertical-center" alignment="center" minWidth="10em">
                        <RequiredRule message="Camp obligatoriu!" />
                        <Lookup dataSource={statusDataSourceOptions} displayExpr="Name" valueExpr="Id" />
                    </Column>
                    <Column dataField="CUI" caption="CUI" cssClass="vertical-center" alignment="center" minWidth="10em">
                        <RequiredRule message="Camp obligatoriu!" />
                    </Column>
                    <Column dataField="J" caption="Codul J" cssClass="vertical-center" alignment="center" minWidth="10em">
                        <RequiredRule message="Camp obligatoriu!" />
                    </Column>
                    <Column dataField="CAENCodeId" caption="Codul CAEN" cssClass="vertical-center" alignment="center" minWidth="10em">
                        <RequiredRule message="Camp obligatoriu!" />
                        <Lookup dataSource={CAENCodeDataSourceOptions} valueExpr="Id" displayExpr="Name" />
                    </Column>
                    <Column dataField="Address" caption="Adresa" cssClass="vertical-center" alignment="center" minWidth="10em">
                        <RequiredRule message="Camp obligatoriu!" />
                    </Column>
                    <Column dataField="LocalityId" caption="Localitate" cssClass="vertical-center" alignment="center" minWidth="10em">
                        <RequiredRule message="Camp obligatoriu!" />
                        <Lookup dataSource={locationDataSourceOptions} displayExpr="Name" valueExpr="Id" />
                    </Column>
                    <Column dataField="HQPhone" caption="Telefon (sediu)" cssClass="vertical-center" alignment="center" minWidth="10em">
                        <RequiredRule message="Camp obligatoriu!" />
                        <CustomRule message="Numar de telefon invalid" validationCallback={validatePhoneNumber} />
                    </Column>
                    <Column dataField="HQFax" caption="Fax (sediu)" cssClass="vertical-center" alignment="center" minWidth="10em">
                        <RequiredRule message="Camp obligatoriu!" />
                    </Column>
                    <Column dataField="Email" caption="Adresa de e-mail" cssClass="vertical-center" alignment="center" minWidth="10em">
                        <RequiredRule message="Camp obligatoriu!" />
                        <EmailRule message="Adresa de e-mail nu este valida" />
                    </Column>
                    <Column dataField="Website" caption="Site-ul web" cssClass="vertical-center" alignment="center" minWidth="10em">
                        <RequiredRule message="Camp obligatoriu!" />
                    </Column>
                    <Column dataField="AffiliatedParts" caption="Parti afiliate" cssClass="vertical-center" alignment="center" minWidth="10em">
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="AffiliatedPartsTags" visible={false} caption="Parti afiliate">
                        <FormItem colSpan={1} editorType="dxTagBox" editorOptions={tagBoxOptions} dataField="AffiliatedPartsTags" />
                    </Column>
                </DataGrid>
                {this.renderAuditPopup(this.state.EntryId, this.state.Page)}
            </Fragment>
        )
    }
}

export default ClientsGrid;