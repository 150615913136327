
export function calculateCustomFilterExpresionForExportBills(component) {
    var selectedRows = [];
    for (var i = 0; i < component.getVisibleRows().length; i++) {
        if (!component.getVisibleRows()[i].data.Adjournment) {
            selectedRows.push(["Id", "=", component.getVisibleRows()[i].data.Id]);
            selectedRows.push("or");
        }
    }
    return selectedRows;
}