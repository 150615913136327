import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import roMessages from "./../../../ro.json";
import { locale, loadMessages } from "devextreme/localization";
import Button from 'devextreme-react/button';
import { Popup } from 'devextreme-react/popup';
import Form, { Item, RequiredRule, EmailRule, ButtonItem } from "devextreme-react/form";
import notify from 'devextreme/ui/notify';

export class ForgotPassword extends Component {
    static displayName = ForgotPassword.name;

    constructor(props) {
        super(props);
        loadMessages(roMessages);
        locale(navigator.language);

        this.passwordRef = React.createRef();

        this.state = {
            visible: false
        }

        this.hidePopup = (e) => {
            this.setState({
                visible: false
            })
        }

        this.forgotPasswordButtonOnClick = (e) => {
            this.setState({
                visible: true
            })
        }

        this.sendPasswordReset = (e) => {
            var validationResult = this.passwordRef.current.instance.validate();
            if (validationResult.isValid) {
                var email = this.passwordRef.current.instance.option("formData");
                fetch("/account/forgot-password", {
                    method: "POST",
                    body: JSON.stringify(email),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(response => {
                    if (response.status === 200) {
                        this.hidePopup();
                        notify("Un e-mail cu un link pentru schimbarea parolei a fost trimis la adresa specificata.", "success", 6000)
                    }
                    else {
                        notify("Aceasta adresa de e-mail nu este asociata unui cont.", "error", 3000)
                    }
                })
            }
        }

        this.forgotPasswordSubmitOptions = {
            onClick: (e) => { this.sendPasswordReset(e) },
            text: "Reseteaza parola"
        }
    }

    render() {
        return(
             <div className="popup-wrapper">
       
                <Button text="Am uitat parola" onClick={this.forgotPasswordButtonOnClick} width="12em"/>
                <Popup
                    visible={this.state.visible}
                    onHiding={this.hidePopup}
                    showTitle={true}
                    title="Am uitat parola"
                    height="auto"
                    minWidth="20vw"
                    width="auto"
                    maxWidth="80vw"
                >
                    <Form
                        ref={this.passwordRef}
                        showColonAfterLabel={true}
                        labelLocation="top"
                        colCount={1}
                    >
                        <Item
                            dataField="Email"
                            editorType="dxTextBox"
                            label={{ text: "Adresa de e-mail" }}
                            width="100em"
                        >
                            <RequiredRule message="Adresa de e-mail este obligatorie!" />
                            <EmailRule message="Adresa de e-mail introdusa nu are un format valid!" />
                        </Item>
                        <ButtonItem horizontalAlignment="center" buttonOptions={this.forgotPasswordSubmitOptions} />
                    </Form>
                </Popup>
            </div>
        )
    }
}
