import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import PopupComponent from 'components/Shared/AuditPopup/_PopupComponent';
import roMessages from "./../../../ro.json";
import ReactDOM from 'react-dom';
import { onRowUpdating, calculateFilterExpresion } from "Utils/Utils";
import { locale, loadMessages } from "devextreme/localization";
import { getEmployeesDataSource, getClientsDataSource, getEmployeeTimesheets } from "./_TimesheetDataSources";
import { AddPopup } from "./Forms/_AddPopup";
import { EditPopup } from './Forms/_EditPopup';
import DataGrid, {
    Button,
    Column,
    Editing,
    SearchPanel,
    Lookup,
    Scrolling,
    FilterRow,
    Sorting
} from 'devextreme-react/data-grid';

const getColumnCaption = (date) => {
    var day = date.getDate().toString().padStart(2, "0");
    var month = (date.getMonth() + 1).toString().padStart(2, "0")
    var year = date.getFullYear();

    return `${day}.${month}.${year}`;
}

const getColumnDataField = (date) => {
    var day = date.getDate().toString().padStart(2, "0");
    var month = (date.getMonth() + 1).toString().padStart(2, "0")
    var year = date.getFullYear();

    return `${year}-${month}-${day}`;
}

const calculateCellValue = (object, date) => {
    if(!object.EmployeeTimesheetHours){
        return object[getColumnDataField(date)]
    }
    if(object[getColumnDataField(date)]){
        return object[getColumnDataField(date)]
    }
    for(var i = 0; i< object.EmployeeTimesheetHours.length; i++){
        var objectDate = new Date(object.EmployeeTimesheetHours[i].Date.slice(0, -1))
        if(objectDate.valueOf() === date.valueOf()){
            return object.EmployeeTimesheetHours[i].Hour;
        }
    }
    return null;
}

export class TimesheetGrid extends Component {
    static displayName = TimesheetGrid.name;

    constructor(props){
        super(props)
        loadMessages(roMessages);
        locale(navigator.language);
        this.grdRef = React.createRef();
        this.state = {
            StartDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        }

        this.auditProps = {
            EntryId: 0,
            Page: ""
        }

        this.setAuditEntry = (Key, Page) => {
            const component = ReactDOM.render(<PopupComponent info={{ EntryId: Key, Page: Page }} />, document.getElementById("auditPopupContainer"));
            component.forceUpdate();
        }

        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "EmployeeTimesheets");
        }

        this.dateInterval = [];

        this.getDateInterval = (date) => {
            var startDate = new Date(date)
            var dateList = [];
            var month = startDate.getMonth();
            var index = 0;
            while(startDate.getMonth() === month){
                var newDate = startDate.valueOf();
                dateList[index] = { date: new Date(newDate.valueOf()), key: index };
                index += 1;
                startDate = new Date(startDate.setDate(startDate.getDate() + 1))
            }
            
            return dateList;
        }

        this.renderAddPopup = () => {
            const component = ReactDOM.render(
                <AddPopup grdRef={this.grdRef}/>,
                document.getElementById("addPopupContainer")
            );
            component.forceUpdate();
        }

        this.renderEditPopup = (formData) => {
            const component = ReactDOM.render(
                <EditPopup grdRef={this.grdRef} entryId={formData.Id} formData={formData} />,
                document.getElementById("editPopupContainer")
            );
            component.forceUpdate();
        }
    }

    render() {
        return (
            <DataGrid
                id="timesheetGrid"
                ref={this.grdRef}
                dataSource={getEmployeeTimesheets(this.state.StartDate)}
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
                wordWrapEnabled={true}
                columnAutoWidth={true}
                width="auto"
                height="85vh"
                onRowUpdating={(e) => { onRowUpdating(e); }}
                remoteOperations={true}
                onToolbarPreparing={(e) => {
                    e.toolbarOptions.items.unshift({
                        location: "after",
                        widget: "dxButton",
                        options: {
                            icon: "add",
                            onClick: (e) => {
                                this.renderAddPopup();
                            }
                        }
                    })
                }}
                onEditingStart={(e) => {if(e.data.RowType === "summary") e.cancel = true;}}
            >
                <FilterRow visible={true} />
                <Scrolling columnRenderingMode="standard" mode="standard" scrollByThumb={true} useNative={true} />
                <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Cautare..." />
                <Editing allowUpdating={true} allowDeleting={true} allowAdding={false} mode="batch" useIcons={true}>
                </Editing>
                <Column type="buttons" cssClass="vertical-center" minWidth="10em" fixed={true} fixedPosition="left">
                    <Button icon="edit" hint="Editare" onClick={(e) => {this.renderEditPopup(e.row.data)}} visible={(e) => { return e.row.data.RowType === "data"}}/>
                    <Button name="delete" cssClass="underlineBtn" visible={(e) => { return e.row.data.RowType === "data"}}/>
                    <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={(e) => { return e.row.data.RowType === "data"}}/>
                </Column>
                <Column dataField="EmployeeId" caption="Angajat" cssClass="vertical-center" alignment="center" minWidth="10em" allowEditing={false} fixed={true} fixedPosition="left"
                    calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("Employee.Id", selectedFilterOperations, filterValue) }}
                    calculateSortValue="Employee.LastName" allowSorting={true}
                >
                    <Lookup displayExpr="FullName" valueExpr="Id" cssClass="vertical-center" alignment="center" dataSource={getEmployeesDataSource()} />
                </Column>
                <Column dataField="ClientId" caption="Client" cssClass="vertical-center" alignment="center" minWidth="10em" allowEditing={false} fixed={true} fixedPosition="left"
                    calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("Client.Id", selectedFilterOperations, filterValue) }}
                    calculateSortValue="Client.Name"
                >
                    <Lookup displayExpr="Name" valueExpr="Id" cssClass="vertical-center" alignment="center" dataSource={getClientsDataSource()} />
                </Column>
                <Column dataField="ProjectName" caption="Proiect" cssClass="vertical-center" alignment="center" minWidth="10em" allowEditing={false} fixed={true} fixedPosition="left"
                    calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("Project.Name", selectedFilterOperations, filterValue)}}
                    calculateSortValue="Project.Name"
                />
                <Column dataField="ActivityName" caption="Activitate" cssClass="vertical-center" alignment="center" minWidth="10em" allowEditing={false} fixed={true} fixedPosition="left"
                    calculateFilterExpression={(filterValue, selectedFilterOperations, target) => {return calculateFilterExpresion("Activity.Name", selectedFilterOperations, filterValue)}}
                    calculateSortValue="Activity.Name"
                />
                <Column dataField="TotalHours" caption="Total" cssClass="vertical-center" alignment="center" minWidth="10em" allowEditing={false} fixed={true} fixedPosition="left"
                    allowSorting={false} allowFiltering={false} allowUpdating={false}
                />
                {
                    this.dateInterval.map(function(item) {
                        return (
                            <Column key={item.key} caption={getColumnCaption(item.date)} dataField={getColumnDataField(item.date)} 
                                cssClass="vertical-center" alignment="center" minWidth="10em" dataType="number"
                                allowFiltering={false}
                                allowSorting={false}
                                calculateCellValue={(rowObject) => {return calculateCellValue(rowObject, item.date)}}
                            >                                
                            </Column>
                        )
                    })
                }
            </DataGrid>
            )
   
    }

    componentDidMount() {
        this.setState({
            StartDate: this.props.StartDate
        })

        this.dateInterval = this.getDateInterval(this.props.StartDate.valueOf())
    }

    componentDidUpdate(prevProps) {
        const { StartDate } = prevProps;
        if(StartDate !== this.props.StartDate){
            this.setState({
                StartDate: this.props.StartDate
            })
            this.dateInterval = this.getDateInterval(this.props.StartDate.valueOf())
        }
    }
}