import React, { Component, Fragment } from 'react';
import { LoginMenu } from './api-authorization/LoginMenu';
import './NavMenu.css';
import './Drawer.css';
import authService from './api-authorization/AuthorizeService';
import Drawer from 'devextreme-react/drawer';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import NavigationList from './NavigationList.js';
import { Link } from 'react-router-dom';

function renderLabel() {
    return <div><Link className="logo" style={{ textDecoration: 'none', color: 'white' }} to="/">Audit</Link></div>;
}

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.state = {
            opened: false,
            isAuthenticated: false,
            user: null,
            collapsed: true
        };

        this.menuButtonOptions = {
            icon: 'menu',
            onClick: () => this.setState({ opened: !this.state.opened })

        };

        this.onOutsideClick = this.onOutsideClick.bind(this);
    }

    onOutsideClick() {
        this.setState({ opened: false });
    }

    toolbarAttributes = {
        id: 'elementId',
        class: 'navbar-expand-sm navbar-toggleable-sm navbar-dark bg-dark border-navbar box-shadow mb-3'
    }

    drawerAttributes = {
        class: 'dx-drawer-content dx-theme-background-color'
    }

    render() {
        const isAuthenticated = this.state.isAuthenticated;
        const { opened } = this.state;
        if (isAuthenticated) {
            return (
                <Fragment>
                    <Toolbar
                        elementAttr={this.toolbarAttributes}
                    >
                        <Item location="before"
                            widget="dxButton"
                            options={this.menuButtonOptions}
                        >
                        </Item>

                        <Item location="before"
                            locateInMenu="never"
                            render={renderLabel} />

                        <Item location="after"
                            locateInMenu="never">
                            <ul className="navbar-nav flex-grow">
                                <LoginMenu>
                                </LoginMenu>
                            </ul>
                        </Item>
                    </Toolbar>

                    <Drawer
                        opened={opened}
                        openedStateMode="shrink"
                        position="left"
                        revealMode="slide"
                        component={NavigationList}
                        height="95vh"
                        closeOnOutsideClick={this.onOutsideClick}
                    >
                        <div id="content" className="dx-theme-background-color">
                            {this.props.sendPropsContainer}
                        </div>
                    </Drawer>
                </Fragment>

            );
        }
        else {
            return (
                <Fragment>
                    <Toolbar
                        elementAttr={this.toolbarAttributes}
                    >
                        <Item location="after"
                            locateInMenu="never">
                            <ul className="navbar-nav flex-grow">
                                <LoginMenu user={this.state.user} isAuthenticated={this.state.isAuthenticated}>
                                </LoginMenu>
                            </ul>
                        </Item>
                    </Toolbar>
                    <Drawer
                        opened={false}
                        openedStateMode="shrink"
                        position="left"
                        revealMode="slide"
                        width="auto"

                    >
                        <div id="content" className="dx-theme-background-color">
                            {this.props.sendPropsContainer}
                        </div>
                    </Drawer>

                </Fragment>
            );
        }
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.getAuthStatus());
        this.getAuthStatus();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async getAuthStatus() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()]
        )
        this.setState({
            isAuthenticated: isAuthenticated,
            user: user
        })
    }
}
