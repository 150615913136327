import React from 'react';
import { loadMessages, locale } from "devextreme/localization";
import roMessages from "./../../ro.json";
import Gantt, { Tasks, Dependencies, Resources, ResourceAssignments, Column, Editing, Toolbar, Item } from 'devextreme-react/gantt';
import { dataSourceTask, updateTask } from '../ProjectTasks/_TaskDataSource';
import { dataSourceDependency } from '../ProjectTasks/_DependencyDataSource';
import { dataSourceResourceAssignments } from '../ProjectTasks/_ResourceAssignmentsDataSource';
import { dataSourceResource } from '../ProjectTasks/_ResourceDataSource';
import TaskForm, { updateParentTask} from "../ProjectTasks/_TaskFormEditComponent";
import ReactDOM from 'react-dom';
import { isNullOrEmpty } from "Utils/Utils";
import 'devexpress-gantt/dist/dx-gantt.min.css'


export class ProjectTask extends React.Component {
    static displayName = ProjectTask.name;

    constructor(props) {
        super(props);
        loadMessages(roMessages);
        locale(navigator.language);

        this.dataSourceGantt = dataSourceTask;
        this.dataSourceDependency = dataSourceDependency;
        this.state = {
            isMoving: false,
            ActivityId: null,
            EmployeeId:null
        };
        this.ganttRef = React.createRef();

        this.setTaskEdit = (Key, popupVisible, DataSource, GanttRef, dataSourceGantt) => {
            const component = ReactDOM.render(<TaskForm dataSource={DataSource} dataSourceGantt={dataSourceGantt} ganttRef={GanttRef} info={{ TaskId: Key, Visible: popupVisible }} />, document.getElementById("taskEditPopupContainer"));
            component.forceUpdate();
        }

        this.onTaskInserted = this.onTaskInserted.bind(this);
        this.onTaskInserting = this.onTaskInserting.bind(this);
        this.onTaskUpdating = this.onTaskUpdating.bind(this);
        this.onTaskDeleted = this.onTaskDeleted.bind(this);
        this.onContextMenuPreparing = this.onContextMenuPreparing.bind(this);
    }

    onTaskInserting = (e) => {
        if (e.values.Progress === undefined) {
            e.values.Progress = 0;
        }
    }

    onTaskUpdating = (e) => {
        if (this.state.isMoving) 
            e.newValues.Progress = e.values.Progress;      

        e.newValues.ParentId = e.values.ParentId;
        e.newValues.Progress = parseInt(e.newValues.Progress);

        if (isNullOrEmpty(e.newValues.StartDate))
            e.newValues.StartDate = e.values.StartDate;

        if (isNullOrEmpty(e.newValues.EndDate))
            e.newValues.EndDate = e.values.EndDate;

        if (e.newValues.Title === undefined)
            e.newValues.Title = e.values.Title;

        updateParentTask(e.newValues.ParentId, e.newValues.StartDate, e.newValues.EndDate, this, e.newValues.Progress, e.key);

        this.setState({
            isMoving: false
        });
    }

    onTaskDeleted = (e) => {
        e.component.beginUpdate();
        this.dataSourceGantt.reload();
        this.dataSourceDependency.reload();
        e.component.repaint();
        e.component.endUpdate();
    }

    onTaskInserted(e) {
        e.component.beginUpdate();
        this.dataSourceGantt.reload();
        this.dataSourceDependency.reload();
        e.component.repaint();
        e.component.endUpdate();
    }

    onTaskMoving = (e) => {
        this.setState({
            isMoving: true
        });
    }

    onTaskEditDialogShowing = (e) => {
        e.cancel = true;
     
        fetch('/api/projecttasks/' + e.key)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setTaskEdit(e.key, true, result, this.ganttRef, this.dataSourceGantt);
                },
                (error) => {
                    throw new Error(error)
                }
            );
    }

    onContextMenuPreparing = (e) => {
        e.items.forEach(function (element) {
            if (element.disabled)
                element.disabled = false;
        });
    }

    render() {
        return (
            <Gantt
                taskListWidth={700}
                scaleType="days"
                height="85vh"
                width="auto"
                firstDayOfWeek={1}
                focusStateEnabled={true}
                taskTitlePosition="inside"
                onTaskMoving={this.onTaskMoving}
                onTaskEditDialogShowing={this.onTaskEditDialogShowing}
                onTaskInserted={this.onTaskInserted}
                onTaskDeleted={this.onTaskDeleted}
                onTaskInserting={this.onTaskInserting}
                onContextMenuPreparing={this.onContextMenuPreparing}
                onTaskUpdating={this.onTaskUpdating}
                ref={this.ganttRef}
               
            >

                <Tasks dataSource={this.dataSourceGantt}
                    keyExpr="Id"
                    parentIdExpr="ParentId"
                    titleExpr="Title"
                    progressExpr="Progress"
                    startExpr="StartDate"
                    endExpr="EndDate"
                    colorExpr="Color"
                />
                <Dependencies dataSource={dataSourceDependency}
                    keyExpr="Id"
                    typeExpr="Type"
                    predecessorIdExpr="PredecessorId"
                    successorIdExpr="SuccessorId"
                />
                <Resources dataSource={dataSourceResource}
                    keyExpr="Id"
                    textExpr="Text"
                />

                <ResourceAssignments dataSource={dataSourceResourceAssignments}
                    keyExpr="Id"
                    resourceIdExpr="ProjectResourceId"
                    taskIdExpr="ProjectTaskId"
                />

                <Toolbar>
                    <Item name="undo" />
                    <Item name="redo" />
                    <Item name="separator" />
                    <Item name="collapseAll" />
                    <Item name="expandAll" />
                    <Item name="separator" />
                    <Item name="addTask" />
                    <Item name="deleteTask" />
                    <Item name="separator" />
                    <Item name="zoomIn" />
                    <Item name="zoomOut" />
                </Toolbar>

                <Column dataField="Title" caption="Titlu" width={260} />
                <Column dataField="StartDate" caption="Data start" format="dd.MM.yyyy"  width={110} cssClass="vertical-center" alignment="center" dataType="date" />
                <Column dataField="EndDate" caption="Data end" format="dd.MM.yyyy" width={110} cssClass="vertical-center" alignment="center" dataType="date" />
                <Column dataField="Progress" caption="Progres" width={70} />
                <Column dataField="ParentId" caption="ParentId" width={50} visible={false} />
                <Column dataField="Employee" caption="Employee" width={40} />
           
                <Editing enabled={true}
                    allowResourceAdding={false}
                    allowResourceDeleting={false}
                    allowTaskResourceUpdating={false}
                />

            </Gantt>
        );
    }
}
/*<Validation autoUpdateParentTasks={true} />*/

