import React from 'react';
import { Popup } from 'devextreme-react/popup';
import { Form, ScrollView } from 'devextreme-react';
import { ButtonItem, EmptyItem, Item, Label, RequiredRule } from "devextreme-react/form";
import {
    activitiesEditorOptions,
    clientsEditorOptions,
    employeeEditorOptions,
    projectsEditorOptions
} from "./_FormsEditorOptions"

export class AddPopup extends React.Component{
    constructor(props){
        super(props);
        this.popupRef = React.createRef();
        this.formRef = React.createRef();
        this.state = {
            employeeId: null,
            clientId: null,
            grdRef: null
        }

        this.setEmployeeId = (employeeId) => {
           // console.log(employeeId)
            this.setState({
                employeeId: employeeId
            })
            this.formRef.current.instance.getEditor("ClientId").option("value", undefined)
        }

        this.setClientId = (clientId) => {
            this.setState({
                clientId: clientId
            })
            this.formRef.current.instance.getEditor("ProjectId").option("value", undefined)
        }

        this.addButtonOptions = () => {
            return {
                text: "Salvez",
                onClick: (e) => {
                    var instance = this.formRef.current.instance;
                    if (!instance.validate().isValid) {
                        return;
                    }

                    var formData = instance.option("formData")
                    return fetch("/api/employeetimesheets", {
                        method: "POST",
                        body: JSON.stringify(formData),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(response => response.json())
                        .then(response => {
                            this.formRef.current.instance.resetValues();
                            this.state.grdRef.current.instance.refresh();
                            this.popupRef.current.instance.hide();
                        })

                }
            }
        }
    }

    render() {
        return (
            <div>
                <Popup
                    dragEnabled={true}
                    closeOnOutsideClick={false}
                    showTitle={true}
                    ref={this.popupRef}
                    title="Activitatea angajatului"
                    minWidth="20em"
                    maxWidth="40em"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <Form
                            ref={this.formRef}
                            showColonAfterLabel={true}
                            labelLocation="left"
                            colCount={1}
                        >
                            <Item
                                dataField="EmployeeId"
                                caption="Angajat"
                                editorType="dxLookup"
                                editorOptions={employeeEditorOptions(this.setEmployeeId)}
                            >
                                <Label text="Angajat" />
                                <RequiredRule message="Camp obligatoriu!" />
                            </Item>
                            <Item
                                dataField="ClientId"
                                caption="Client"
                                editorType="dxLookup"
                                editorOptions={clientsEditorOptions(this.state.employeeId, this.setClientId)}
                            >
                                <Label text="Client" />
                                <RequiredRule message="Camp obligatoriu!" />
                            </Item>
                            <Item
                                dataField="ProjectId"
                                caption="Proiect"
                                editorType="dxLookup"
                                editorOptions={projectsEditorOptions(this.state.clientId)}
                            >
                                <Label text="Proiect" />
                                <RequiredRule message="Camp obligatoriu!" />
                            </Item>
                            <Item
                                dataField="ActivityId"
                                caption="Activitate"
                                editorType="dxLookup"
                                editorOptions={activitiesEditorOptions()}
                            >
                                <Label text="Activitate" />
                                <RequiredRule message="Camp obligatoriu!" />
                            </Item>
                            <EmptyItem />
                            <ButtonItem
                                horizontalAlignment="right"
                                buttonOptions={this.addButtonOptions()}
                            />
                        </Form>
                    </ScrollView>
                </Popup>
            </div>
        )
    }

    componentDidMount(props) {
       // console.log(this.props)
        const { grdRef } = this.props
        this.setState({
            grdRef: grdRef
        })
        this.popupRef.current.instance.show();
    }

    componentDidUpdate(prevProps){
        const { grdRef } = prevProps;
        if(grdRef !== this.props.grdRef){
            this.setState({
                grdRef: grdRef
            })
        }
        this.popupRef.current.instance.show();
    }
}