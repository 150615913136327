import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { Link } from "react-router-dom";
import ListSubheader from '@material-ui/core/ListSubheader';

const items = [
    { name: 'Localitati', label: 'Localitati' },
    { name: 'CAEN', label: 'Coduri CAEN' },
/*    { name: 'Grupuri', label: 'Grupuri de firme' },*/
    { name: 'Activitati', label: 'Activitati Timesheet' },
    { name: "Completitudine", label: "Risk Assesment - Dimensiuni" },
    { name: "CategoriiControl", label: "Risk Assesment - Categorii de control" },
    { name: "NomenclatorIndicatoriCompanie", label: "Indicatori financiari companie"}
]

export function Sidebar() {
    return (
        <div className="sidebar">
            <List disablePadding dense>
                <ListSubheader >Nomenclatoare</ListSubheader>
                {items.map(({ label, name, ...rest }) => (
                    <ListItem key={name} button {...rest} component={Link} to={name}>
                        <ListItemText>{ label }</ListItemText>
                    </ListItem>
                    )
                )}
            </List>
        </div>
    )
}

export default Sidebar