import React from 'react';
import { Popup } from 'devextreme-react/popup';
import { Form, ScrollView } from 'devextreme-react';
import { ButtonItem, EmptyItem, Item, Label, RequiredRule } from "devextreme-react/form";
import {
    employeeEditorOptions,
    clientsEditorOptions,
    projectsEditorOptions,
    yearsEditorOptions,
    activitiesEditorOptions
} from 'components/EmployeesScheduler/SchedulerComponent/FormEditorOptions'

export class EditAppointmentPopup extends React.Component {
    constructor(props) {
        super(props)
        this.popupRef = React.createRef();
        this.formRef = React.createRef();
        this.state = {
            entryId: null,
            clientId: null,
            employeeId: null,
            startDate: null,
            projectId: null,
            schedulerRef: null
        }

        this.setData = false;

        this.setEmployeeId = (employeeId) => {
            this.setData = true;
            this.setState({
                employeeId: employeeId
            })
            this.formRef.current.instance.getEditor("ClientId").option("value", undefined)
        }

        this.setClientId = (clientId) => {
            this.setData = true;
            this.setState({
                clientId: clientId
            })
            this.formRef.current.instance.getEditor("ProjectId").option("value", undefined)
        }

        this.setProjectId = (projectId) => {
            this.setData = true;
            this.setState({
                projectId: projectId
            })
            this.formRef.current.instance.getEditor("ProjectYearId").option("value", undefined)
        }

        this.editButtonOptions = () => {
            return {
                text: "Salvez",
                onClick: (e) => {
                    var instance = this.formRef.current.instance;
                    if (!instance.validate().isValid) {
                        return;
                    }

                    var formData = instance.option("formData")
                    return fetch(`/api/employeeappointments/${this.state.entryId}`, {
                        method: "PUT",
                        body: JSON.stringify(formData),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(response => response.json())
                        .then(response => {
                            this.formRef.current.instance.resetValues();
                            this.state.schedulerRef.current.instance.getDataSource().reload();
                            this.popupRef.current.instance.hide();
                        })

                }
            }
        }

        this.setFormData = (data) => {
            this.formRef.current.instance.getEditor("EmployeeId").option("value", data.EmployeeId);
            this.formRef.current.instance.getEditor("ClientId").option("value", data.ClientId);
            this.formRef.current.instance.getEditor("ProjectId").option("value", data.ProjectId);
            this.formRef.current.instance.getEditor("ProjectYearId").option("value", data.ProjectYearId);
            this.formRef.current.instance.getEditor("StartDate").option("value", data.StartDate);
            this.formRef.current.instance.getEditor("EndDate").option("value", data.EndDate);
            this.formRef.current.instance.getEditor("ActivityId").option("value", data.ActivityId);
            this.formRef.current.instance.getEditor("Description").option("value", data.Description);

        }

        this.formRef = React.createRef();
    }

    render() {
        return (
            <div>
                <Popup
                    dragEnabled={true}
                    closeOnOutsideClick={false}
                    showTitle={true}
                    ref={this.popupRef}
                    title="Activitatea angajatului"
                    minWidth="20em"
                    maxWidth="40em"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <Form
                            ref={this.formRef}
                            showColonAfterLabel={true}
                            labelLocation="left"
                            colCount={1}
                        >
                            <Item
                                dataField="EmployeeId"
                                caption="Angajat"
                                editorType="dxLookup"
                                editorOptions={employeeEditorOptions(this.setEmployeeId)}
                            >
                                <Label text="Angajat" />
                                <RequiredRule message="Camp obligatoriu!" />
                            </Item>
                            <Item
                                dataField="ClientId"
                                caption="Client"
                                editorType="dxLookup"
                                editorOptions={clientsEditorOptions(this.state.employeeId, this.setClientId)}
                            >
                                <Label text="Client" />
                                <RequiredRule message="Camp obligatoriu!" />
                            </Item>
                            <Item
                                dataField="ProjectId"
                                caption="Proiect"
                                editorType="dxLookup"
                                editorOptions={projectsEditorOptions(this.state.clientId, this.setProjectId)}
                            >
                                <Label text="Proiect" />
                                <RequiredRule message="Camp obligatoriu!" />
                            </Item>
                            <Item
                                dataField="ProjectYearId"
                                caption="An"
                                editorType="dxLookup"
                                editorOptions={yearsEditorOptions(this.state.projectId)}
                            >
                                <Label text="An" />
                                <RequiredRule message="Camp obligatoriu!" />
                            </Item>
                            <Item
                                dataField="StartDate"
                                editorType="dxDateBox"
                                editorOptions={{
                                    type: "datetime",
                                    dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss"
                                }}
                            >
                                <Label text="Data de start" />
                                <RequiredRule message="Camp obligatoriu!" />
                            </Item><Item
                                dataField="EndDate"
                                editorType="dxDateBox"
                                editorOptions={{
                                    type: "datetime",
                                    dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss"
                                }}
                            >
                                <Label text="Data finalizarii" />
                                <RequiredRule message="Camp obligatoriu!" />
                            </Item>
                            <Item
                                dataField="ActivityId"
                                caption="Activitate"
                                editorType="dxLookup"
                                editorOptions={activitiesEditorOptions()}
                            >
                                <Label text="Activitate" />
                                <RequiredRule message="Camp obligatoriu!" />
                            </Item>
                            <Item
                                dataField="Description"
                                caption="Observatii"
                                editorType="dxTextArea"
                            >
                                <Label text="Observatii" />
                            </Item>
                            <EmptyItem />
                            <ButtonItem
                                horizontalAlignment="right"
                                buttonOptions={this.editButtonOptions()}
                            />
                        </Form>
                    </ScrollView>
                </Popup>
            </div>
        )
    }

    componentDidMount(props) {
        const { info } = this.props
        this.setState({
            clientId: info.clientId,
            employeeId: info.employeeId,
            startDate: info.startDate,
            entryId: info.entryId,
            schedulerRef: info.schedulerRef
        })
        this.popupRef.current.instance.show();
        if (this.formRef.current !== null) {
            fetch(`/api/employeeappointments/${info.entryId}`)
                .then(response => response.json())
                .then(response => {
                    response.StartDate = new Date(response.StartDate)
                    response.EndDate = new Date(response.EndDate)
                    this.setFormData(response)
                })
        }
    }

    componentDidUpdate(prevProps) {
        const { info } = prevProps
        if (this.props.info.entryId !== info.entryId) {
            this.setState({
                clientId: this.props.info.clientId,
                employeeId: this.props.info.employeeId,
                startDate: this.props.info.startDate,
                entryId: this.props.info.entryId,
                schedulerRef: this.props.info.schedulerRef
            })
        }
        this.popupRef.current.instance.show();
        if (!this.setData) {
           if (this.formRef.current !== null) {
               fetch(`/api/employeeappointments/${info.entryId}`)
                   .then(response => response.json())
                   .then(response => {
                       response.StartDate = new Date(response.StartDate)
                       response.EndDate = new Date(response.EndDate)
                       this.setFormData(response)
                       if(this.state.employeeId == null){
                           this.setState({
                               employeeId: response.EmployeeId,
                               clientId: response.ClientId,
                               projectId: response.ProjectId
                           })
                       }
                   })
           }
        }
        this.setData = false;
    }
}

export default EditAppointmentPopup
