import DataSource from 'devextreme/data/data_source';

export const dataSourceResourceAssignments = new DataSource({
    load: (loadOptions) => {
        return fetch("/../api/projectresourceassignments").then(response => response.json())
            .then(response => {
                return response
            })
    },
    byKey: (key) => {
        return fetch('/api/projectresourceassignments/' + key)
            .then(response => response.json())
            .then(response => {
                return response
            })
    },
    insert: (values) => {
        console.log('insertra');
        console.log(values);
        return fetch("/api/projectresourceassignments", {
            method: "POST",
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
            .then(response => {
                return response
            })
    },
    update: (key, values) => {
        return fetch("/api/projectresourceassignments/" + key, {
            method: "PUT",
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => response.json())
    },
    remove: (key) => {
        return fetch("/api/projectresourceassignments/" + key, {
            method: "DELETE",
            body: JSON.stringify({ id: key }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
});
