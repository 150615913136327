// JavaScript source code
import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import CustomStore from 'devextreme/data/custom_store';
import roMessages from "./../../../ro.json";
import { isNotEmpty, handleErrors, onRowUpdating } from "Utils/Utils";
import { getClientRisksSubMeasureData} from "../ClientRiskData";
import { locale, loadMessages } from "devextreme/localization";
import DataGrid, {
    Button, Column,
    Editing,
    FilterRow,
    Form, FormItem,
    Popup, RequiredRule,
    StringLengthRule,
    Scrolling, SearchPanel
} from 'devextreme-react/data-grid';



const textAreaOptions = {
    height: "200vh"
}

export class ClientRiskSubMeasures extends Component {

    constructor(props) {
        super(props)
        this.state = {
            clientRiskId: 0,
            measureId: 0,
            setContainerState: null,
            selectedData: [],
            dataSource: null,
           
           
        }
        loadMessages(roMessages);
        locale(navigator.language);
        this.redirect = props.redirect     
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onCellPrepared = this.onCellPrepared.bind(this);
       
    }



    render() {
        return (
            <div id="clientsubMeasures">
                <DataGrid
                    width="100%"
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    wordWrapEnabled={true}
                    columnAutoWidth={true}
                    dataSource={this.state.dataSource}                 
                    onRowUpdating={onRowUpdating}
                    focusedRowEnabled={true}
                    remoteOperations={true}
                    selection={{ mode: 'single' }}                   
                    onSelectionChanged={this.onSelectionChanged}
                    onCellPrepared={this.onCellPrepared}
                    height={350}
                >
                    <FilterRow visible={true} />
                    <Scrolling columnRenderingMode="virtual" mode="standard" scrollByThumb={true} />
                    <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Cautare..." />

                    <Editing allowAdding={false} allowUpdating={true} allowDeleting={false} mode="popup" useIcons={true} >
                        <Form colCount={1} title="SubDimensiuni" />
                        <Popup title="SubDimensiuni" showTitle={true} />
                    </Editing>

                    <Column type="buttons" cssClass="vertical-center" width="32" alignment="left" fixed={true} fixedPosition="left">
                        <Button name="edit" cssClass="underlineBtn" />
                    </Column>

                   
                    <Column dataField="Id" visible={false} allowEditing={false} showEditorAlways={false}>
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="MeasureId" visible={false} allowEditing={false} showEditorAlways={false}>
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="SubMeasureId" visible={false} allowEditing={false} showEditorAlways={false}>
                        <FormItem visible={false} />
                    </Column>                  
                    <Column dataField="Name" caption="SubDimensiune" allowEditing={false} cssClass="vertical-center"
                        alignment="center" fixed={true} width="80" showEditorAlways={false} cellHintEnabled={true}>
                        <RequiredRule message="Camp obligatoriu!" />
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="InherentRisk" caption="Risc inerent" allowEditing={false} cssClass="vertical-center"
                        alignment="center" fixed={true} width="80" showEditorAlways={false} cellHintEnabled={true} >
                        <RequiredRule message="Camp obligatoriu!" />
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="FunctionalExistanceNotes" caption="Nota F" cssClass="vertical-center"
                        alignment="center" width="100" fixed={true} cellHintEnabled={true}>
                        <RequiredRule message="Camp obligatoriu!" />
                        <StringLengthRule max={500} message="Nota depaseste limita maxima de 500 de caractere!" />
                        <FormItem colSpan={1} editorType="dxTextArea" editorOption={textAreaOptions} dataField="FunctionalExistanceNotes" />
                    </Column>
                    <Column dataField="FunctionalExistanceValue" caption="F" allowEditing={false} cssClass="vertical-center" fixed={true}
                        alignment="center" width="30" showEditorAlways={false}>
                        <FormItem visible={false} />
                        <RequiredRule message="Camp obligatoriu!" />
                    </Column>
                    <Column dataField="RiskNotes" caption="Nota R" cssClass="vertical-center"
                        alignment="center" width="100" fixed={true} cellHintEnabled={true}>
                        <RequiredRule message="Camp obligatoriu!" />

                        <StringLengthRule max={500} message="Nota depaseste limita maxima de 500 de caractere!" />
                        <FormItem colSpan={1} editorType="dxTextArea" editorOption={textAreaOptions} dataField="RiskNotes" />
                    </Column>
                    <Column dataField="RiskScore" caption="R" caption="R" cssClass="vertical-center" fixed={true} allowEditing={false} alignment="center" fixed={true} width="30" showEditorAlways={false}>
                        <RequiredRule message="Camp obligatoriu!" />
                        <FormItem visible={false} />
                    </Column>                   
                </DataGrid>
               
            </div>
        )
    }


    onCellPrepared(e) {
        if ((e.columnIndex === 5 || e.columnIndex === 6) && e.rowType === 'header') {
            e.cellElement.bgColor = '#ED7D31';
        }

        if ((e.columnIndex == 3 || e.columnIndex === 4) && e.rowType === 'header') {
            e.cellElement.bgColor = '#4472C4';
        }
       
    }


    onSelectionChanged({ selectedRowsData }) {
      
       if (selectedRowsData.length > 0) {
            const data = selectedRowsData[0];    
            //  console.log(this.props);      
            this.setState({         
                clientRiskId: this.props.clientRiskId,
                measureId: this.props.measureId,          
                setContainerState: this.props.setContainerState(data.SubMeasureId)
            });
        }

     
      
       
    }
    
             dataSourceOptions = new CustomStore({
                 key: "Id",
                 load: (loadOptions) => {
                     let params = "?";
                     [
                         "skip",
                         "take",
                         "requireTotalCount",
                         "sort",
                         "filter"
                     ].forEach(function (i) {
                         if (i in loadOptions && isNotEmpty(loadOptions[i]))
                             params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                     });

                     params = params.slice(0, -1);

                     return fetch(`/api/clientRisks/${this.state.clientRiskId}/measures/${this.state.measureId}/submeasures${params}`)
                         .then(handleErrors)
                         .then(response => response.json())
                         .then(response => {
                           
                             return {
                                 data: response.data,
                                 totalCount: response.totalCount,
                                 summary: response.summary,
                                 groupCount: response.groupCount
                             };
                         })
                         .catch(err => err);
                 },
                 byKey: (key) => {
                     return fetch(`/api/clientRisks/${this.state.clientRiskId}/measures/${this.state.measureId}/submeasures` + key)
                         .then(response => response.json())
                         .then(response => {
                             return response
                         })

                 },
                 update: (key, values) => {
                     return fetch("/api/clientsubmeasures/" + key, {
                         method: "PUT",
                         body: JSON.stringify(values),
                         headers: {
                             'Content-Type': 'application/json'
                         }
                     }).then(response => response.json())
                 }
             })

    componentDidMount(props) {
          
        const { clientRiskId, measureId, setContainerState } = this.props;       
        this.setState({
            clientRiskId: clientRiskId,
            measureId: measureId,
            setContainerState: setContainerState,
            selectedData: [1],
            dataSource: this.dataSourceOptions,           
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.clientRiskId !== prevProps.clientRiskId || this.props.measureId !== prevProps.measureId) {

            const { clientRiskId, measureId, setContainerState } = this.props;
         
            this.setState({ 
                clientRiskId: clientRiskId,
                measureId: measureId,
                setContainerState: setContainerState,
                dataSource: getClientRisksSubMeasureData(clientRiskId, measureId)
            })
        }
        
    }

}

export default ClientRiskSubMeasures;