import React, { Component } from 'react';

import TabPanel, { Item } from 'devextreme-react/tab-panel';

import { ContractsDropdown } from './_ContractsDropdown';

export class InternalAudit extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div>
                <ContractsDropdown />

                <TabPanel
                    height={400}
                    // dataSource={companies}
                    // selectedIndex={selectedIndex}
                    // onOptionChanged={this.onSelectionChanged}
                    // loop={loop}
                    // itemTitleRender={this.itemTitleRender}
                    // itemComponent={CompanyItem}
                    // animationEnabled={animationEnabled}
                    // swipeEnabled={swipeEnabled}
                >

                    <Item title="Setup">
                        {/* ... */}
                    </Item>
                    <Item title="Misiuni">
                        {/* ... */}
                    </Item>
                    <Item title="Follow-up">
                        {/* ... */}
                    </Item>
                    
                </TabPanel>

                
            </div>
        );
    }
}