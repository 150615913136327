import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Localities } from './components/Nomenclatoare/_Localities';
import { CAEN } from './components/Nomenclatoare/_CAEN';
import { Clients } from './components/ClientsInterface/_Clients';
import { ClientGroup } from './components/Nomenclatoare/_ClientGroups';
import { ContractComponent } from './components/Contracts/_ContractComponent';
import { Login } from 'components/Authentication/_Login';
import { SetPassword } from 'components/SetPassword/_SetPassword';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import { RiskAssessment } from 'components/RiskAssessment/_RiskAssessment';
import { RiskAssessmentGraphic } from 'components/RiskAssessmentGraphic/_RiskAssessmentGraphic';
import { MailBills } from 'components/MailBills/_MailBills';
import { Users } from 'components/Users/_Users';
import roMessages from "./ro.json";
import { Bills } from 'components/Bills/_Bills';
import { Employees } from 'components/Employees/_Employees';
import { locale, loadMessages } from "devextreme/localization";
import { Project } from './components/Projects/_ProjectGrid';
import { EmployeeClients } from "components/EmployeeClients/_EmployeeClients"
import { Activity } from './components/Nomenclatoare/_Activity';
import  ClientRiskComponent  from 'components/RiskAssesmentClient/ClientRiskComponent';
import { ControlCategories } from'components/Nomenclatoare/ControlCategories/_ControlCategories'
import { ProjectTaskComponent } from './components/ProjectTasks/_ProjectTaskComponent';
import { CompanyIndicators } from 'components/Nomenclatoare/CompanyIndicators/_CompanyIndicators';
import { EmployeeTimesheet } from 'components/EmployeeTimesheet/_EmployeeTimesheet';
import { EmployeesScheduler } from "components/EmployeesScheduler/_EmployeesScheduler";
import './reset.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import './custom.css';
import { ClientCompanyIndicators } from 'components/ClientCompanyIndicators/_ClientCompanyIndicators';
import Offers from 'components/Offers/_Offers';
import { ContractEvolution } from './components/ContractEvolutions/_ContractEvolution';
import { Dashboard } from 'components/Dashboard/_Dashboard';
import { InternalAudit } from 'components/InternalAudit/_InternalAudit';

export default class App extends Component {
    constructor(props) {
        super(props);
        loadMessages(roMessages);
        locale(navigator.language)
    }
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <AuthorizeRoute exact path='/' component={Home} />
                <AuthorizeRoute path='/Localitati' component={Localities} />
                <AuthorizeRoute path='/CAEN' component={CAEN} />
                <AuthorizeRoute path='/Activitati' component={Activity} />
                <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                <Route path='/Facturare' component={Bills} />
                <AuthorizeRoute path='/Clienti' component={Clients} />
                <Route path='/Contracte' component={ContractComponent} />
                <AuthorizeRoute path='/Grupuri' component={ClientGroup} />
                <AuthorizeRoute path='/Utilizatori' component={Users} />
                <Route path='/Autentificare' component={Login} />
                <Route path="/resetPassword/:id/:token" component={SetPassword} />
                <AuthorizeRoute path='/Completitudine' component={RiskAssessment} />
                <AuthorizeRoute path='/Grafic' component={RiskAssessmentGraphic} />
                <Route path='/MailBills' component={MailBills} />
                <Route path='/Project' component={Project} />
                <AuthorizeRoute path='/Angajati' component={Employees} />
                <AuthorizeRoute path="/ClientiAngajati" component={EmployeeClients} />
                <AuthorizeRoute path="/Planificator" component={EmployeesScheduler} />
                <AuthorizeRoute path='/CompletitudineClient' component={ClientRiskComponent} />
                <AuthorizeRoute path='/CategoriiControl' component={ControlCategories} />
                <AuthorizeRoute path="/TimesheetAngajati" component={EmployeeTimesheet} />
                <AuthorizeRoute path="/NomenclatorIndicatoriCompanie" component={CompanyIndicators} />
                <AuthorizeRoute path="/IndicatoriCompanie" component={ClientCompanyIndicators} />
                <AuthorizeRoute path="/ProjectTaskComponent" component={ProjectTaskComponent} />
                <AuthorizeRoute path="/Oferte" component={Offers} />
                <AuthorizeRoute path="/EvolutieContracte" component={ContractEvolution} />
                <AuthorizeRoute path="/Dashboard" component={Dashboard} />
                <AuthorizeRoute path="/AuditIntern" component={InternalAudit} />
            </Layout> 
        );
    }
}



