import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import CustomStore from 'devextreme/data/custom_store';
import roMessages from "./../../../ro.json";
import { getClientRiskQuestionsData } from "../ClientRiskData";
import { isNotEmpty, handleErrors, multiLineCell, onRowUpdating } from "Utils/Utils";
import { locale, loadMessages } from "devextreme/localization";
import DataGrid, {
    Column,
    Editing,
    FilterRow,
    Form,
    FormItem,
    Popup,
    RequiredRule,
    SelectBox,
    Lookup,
    Button,
    Scrolling, SearchPanel
} from 'devextreme-react/data-grid';


const AnswersDataSourceOptions = new CustomStore({
    key: "Id",
    load: (loadOptions) => {
        let params = "?";
        [
            "skip",
            "take",
            "requireTotalCount",
            "sort",
            "filter"
        ].forEach(function (i) {
            if (i in loadOptions && isNotEmpty(loadOptions[i]))
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        });

        params = params.slice(0, -1);

        return fetch(`/api/answers${params}`)
            .then(handleErrors)
            .then(response => response.json())
            .then(response => {
                return {
                    data: response.data,
                    totalCount: response.totalCount,
                    summary: response.summary,
                    groupCount: response.groupCount
                };
            })
            .catch(err => err);
    },
    byKey: (key) => {
        key = key == 0 ? 1 : key;
        return fetch('/api/answers/' + key)
            .then(response => response.json())
            .then(response => {
                return response
            })

    }

});



export class ClientRiskQuestions extends Component {

    constructor(props) {
        super(props)
        this.state = {
            measureId: 0,
            subMeasureId: 0,
            clientRiskId: 0,
            categoryId: 0,
            dataSource: null,
            setContainerState: null,
        }
        loadMessages(roMessages);
        locale(navigator.language);

        this.answerEditorRender = this.answerEditorRender.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
    }

       

        answerEditorRender (cell) {
            let onAnswerValueChanged = this.onAnswerValueChanged.bind(this, cell);

            return <SelectBox
                defaultValue={cell.value}
                {...cell.column.lookup}
                onValueChanged={onAnswerValueChanged}
            />;
        }

        onAnswerValueChanged(cell, e) {
            cell.setValue(e.value);
        }

        onSelectionChanged({ selectedRowsData }) {
       
            if (selectedRowsData.length > 0) {
                const data = selectedRowsData[0];         
                this.setState({
                    clientRiskId: this.props.clientRiskId,
                    measureId: this.props.measureId,
                    subMeasureId: this.props.subMeasureId,
                    categoryId: this.props.categoryId,
                    setContainerState: this.props.setContainerState(data.Id)
                });
            }
    
        }



    render() {
        return (
            <div id="questions">
                <DataGrid
                    width="100%"
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    wordWrapEnabled={true}
                    columnAutoWidth={true}         
                    dataSource={this.state.dataSource}
                    height={350}
                    remoteOperations={true}
                    onSelectionChanged={this.onSelectionChanged}    
                >
                    <FilterRow visible={true} />
                    <Scrolling columnRenderingMode="virtual" mode="infinite" scrollByThumb={true} />
                    <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Cautare..." />
                    <Editing allowAdding={false} allowUpdating={true} allowDeleting={false} mode="popup" useIcons={true} >
                        <Form colCount={1} title="Intrebari" />
                        <Popup title="Intrebari" showTitle={true} />
                    </Editing>

                    <Column type="buttons" cssClass="vertical-center" width="40" alignment="left" fixed={true} fixedPosition="left">
                        <Button name="edit" cssClass="underlineBtn" />
                    </Column>
                    <Column dataField="Id" visible={false} allowEditing={false} showEditorAlways={false}>
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="CategoryId" visible={false} allowEditing={false} showEditorAlways={false}>
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="ControlCategoryQuestionId" visible={false} allowEditing={false} showEditorAlways={false}>
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="Question" caption="Intrebare" allowEditing={false} cssClass="vertical-center" alignment="center" minWidth="10em"
                        cellRender={function (e) { return multiLineCell(e.data.Question); }} editorType="dxTextArea">
                        <RequiredRule message="Camp obligatoriu!" />
                    </Column>            
                  
                    <Column dataField="AnswerId" caption="Raspuns" alignment="center" minWidth="10em" 
                        allowMerging={true}>
                        <Lookup dataSource={AnswersDataSourceOptions} valueExpr="Id" displayExpr="Value" />
                        <RequiredRule message="Camp obligatoriu!" />
                    </Column>
                    
                   
                </DataGrid>
              
            </div>
        )
    }


    dataSourceOptions = new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/clientRisks/${this.state.clientRiskId}/measures/${this.state.measureId}/subMeasures/${this.state.subMeasureId}/categories/${this.state.categoryId}/questions`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    };
                })
                .catch(err => err);
        },
        byKey: (key) => {
            return fetch(`/api/clientRisks/${this.state.clientRiskId}/measures/${this.state.measureId}/subMeasures/${this.state.subMeasureId}/categories/${this.state.measureId}/questions` + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })

        },
        update: (key, values) => {
            return fetch("/api/clientCategoryquestions/" + key, {
                method: "PUT",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
        }
    });


    componentDidMount(props) {
        const { clientRiskId, measureId, subMeasureId, categoryId } = this.props;
        this.setState({
            clientRiskId: clientRiskId,
            measureId: measureId,
            subMeasureId: subMeasureId,
            categoryId: categoryId,
            dataSource: this.dataSourceOptions
        })
    }

    componentDidUpdate(prevProps) {
       
        // console.log('test')
        // console.log(prevProps);
        if (this.props.clientRiskId !== prevProps.clientRiskId || this.props.measureId !== prevProps.measureId
            || this.props.subMeasureId !== prevProps.subMeasureId || this.props.categoryId !== prevProps.categoryId) {
            const { clientRiskId, measureId, subMeasureId, categoryId, setContainerState } = this.props;
           
            this.setState({ 
                clientRiskId: clientRiskId,
                measureId: measureId,
                subMeasureId: subMeasureId,
                categoryId: categoryId,
                setContainerState: setContainerState,
                dataSource: getClientRiskQuestionsData(clientRiskId, measureId, subMeasureId, categoryId)
            })

        }


    }
   

}

export default ClientRiskQuestions;