import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import roMessages from "./../../ro.json";
import { locale, loadMessages } from "devextreme/localization";
import LoginForm from "./LoginForm/_LoginForm"
import { ForgotPassword } from './ForgotPasswordPopup/_ForgotPassword';

export class Login extends Component {
    static displayName = Login.name;

    constructor(props) {
        super(props);
        loadMessages(roMessages);
        locale(navigator.language);
    }

    render() {
        return (
            <div className="row my-auto h-100">
                <div className="col-sm-1 col-md-2 col-lg-4 my-auto" />
                <div className="col-sm-10 col-md-8 col-lg-4 my-auto login-container">
                    <LoginForm />
                    <ForgotPassword />
                </div>
                <div className="col-sm-1 col-md-2 col-lg-4 my-auto" />
            </div>
        )
    }
}

export default Login;
