import React from 'react';
import { Popup } from 'devextreme-react/popup';
import { Form, ScrollView, TextArea, DateBox } from 'devextreme-react';
import { ButtonItem, EmptyItem, Item, Label, RequiredRule } from "devextreme-react/form";
import {
    employeeEditorOptions,
    clientsEditorOptions,
    projectsEditorOptions,
    yearsEditorOptions,
    activitiesEditorOptions
} from 'components/EmployeesScheduler/SchedulerComponent/FormEditorOptions'

export class AddAppointmentPopup extends React.Component {
    constructor(props) {
        super(props)
        this.popupRef = React.createRef();
        this.formRef = React.createRef();
        this.state = {
            clientId: null,
            employeeId: null,
            startDate: null,
            projectId: null,
            schedulerRef: null
        }

        this.setEmployeeId = (employeeId) => {
            this.setState({
                employeeId: employeeId
            })
            this.formRef.current.instance.getEditor("ClientId").option("value", undefined)
        }

        this.setClientId = (clientId) => {
            this.setState({
                clientId: clientId
            })
            this.formRef.current.instance.getEditor("ProjectId").option("value", undefined)
        }

        this.setProjectId = (projectId) => {
            this.setState({
                projectId: projectId
            })
            this.formRef.current.instance.getEditor("ProjectYearId").option("value", undefined)
        }

        this.addButtonOptions = () => {
            return {
                text: "Salvez",
                onClick: (e) => {
                    var instance = this.formRef.current.instance;
                    if (!instance.validate().isValid) {
                        return;
                    }

                    var formData = instance.option("formData")
                    return fetch("/api/employeeappointments", {
                        method: "POST",
                        body: JSON.stringify(formData),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(response => response.json())
                        .then(response => {
                            this.formRef.current.instance.resetValues();
                            this.state.schedulerRef.current.instance.getDataSource().reload();
                            this.popupRef.current.instance.hide();
                        })

                }
            }
        }
    }

    render() {
        return (
            <div>
                <Popup
                    dragEnabled={true}
                    closeOnOutsideClick={false}
                    showTitle={true}
                    ref={this.popupRef}
                    title="Activitatea angajatului"
                    minWidth="20em"
                    maxWidth="40em"
                    height="auto"
                >
                    <ScrollView width="100%" height="100%">
                        <Form
                            ref={this.formRef}
                            showColonAfterLabel={true}
                            labelLocation="left"
                            colCount={1}
                        >
                            <Item
                                dataField="EmployeeId"
                                caption="Angajat"
                                editorType="dxLookup"
                                editorOptions={employeeEditorOptions(this.setEmployeeId)}
                            >
                                <Label text="Angajat" />
                                <RequiredRule message="Camp obligatoriu!" />
                            </Item>
                            <Item
                                dataField="ClientId"
                                caption="Client"
                                editorType="dxLookup"
                                editorOptions={clientsEditorOptions(this.state.employeeId, this.setClientId)}
                            >
                                <Label text="Client" />
                                <RequiredRule message="Camp obligatoriu!" />
                            </Item>
                            <Item
                                dataField="ProjectId"
                                caption="Proiect"
                                editorType="dxLookup"
                                editorOptions={projectsEditorOptions(this.state.clientId, this.setProjectId)}
                            >
                                <Label text="Proiect" />
                                <RequiredRule message="Camp obligatoriu!" />
                            </Item>
                            <Item
                                dataField="ProjectYearId"
                                caption="An"
                                editorType="dxLookup"
                                editorOptions={yearsEditorOptions(this.state.projectId)}
                            >
                                <Label text="An" />
                                <RequiredRule message="Camp obligatoriu!" />
                            </Item>
                            <Item
                                dataField="StartDate"
                                editorType="dxDateBox"
                                editorOptions={{
                                    value: this.state.startDate,
                                    type: "datetime",
                                    dateSerializationFormat:"yyyy-MM-ddTHH:mm:ss"
                                }}
                            >
                                <Label text="Data de start" />
                                <RequiredRule message="Camp obligatoriu!" />
                            </Item><Item
                                dataField="EndDate"
                                editorType="dxDateBox"
                                editorOptions={{
                                    value: new Date(new Date(new Date(this.state.startDate).setDate(new Date(this.state.startDate).getDate() + 1)).setHours(17)),
                                    type: "datetime",
                                    dateSerializationFormat:"yyyy-MM-ddTHH:mm:ss"
                                }}
                            >
                                <Label text="Data finalizarii" />
                                <RequiredRule message="Camp obligatoriu!" />
                            </Item>
                            <Item
                                dataField="ActivityId"
                                caption="Activitate"
                                editorType="dxLookup"
                                editorOptions={activitiesEditorOptions()}
                            >
                                <Label text="Activitate" />
                                <RequiredRule message="Camp obligatoriu!" />
                            </Item>
                            <Item
                                dataField="Description"
                                caption="Observatii"
                                editorType="dxTextArea"
                            >
                                <Label text="Observatii" />
                            </Item>
                            <EmptyItem />
                            <ButtonItem
                                horizontalAlignment="right"
                                buttonOptions={this.addButtonOptions()}
                            />
                        </Form>
                    </ScrollView>
                </Popup>
            </div>
        )
    }

    componentDidMount(props) {
        const { info } = this.props
        this.setState({
            clientId: info.clientId,
            employeeId: info.employeeId,
            startDate: info.startDate,
            schedulerRef: info.schedulerRef
        })
        this.popupRef.current.instance.show();
    }

    componentDidUpdate(prevProps) {
        const { info } = prevProps
        if (this.props.info.clientId !== info.clientId || this.props.info.employeeId !== info.employeeId || this.props.info.startDate !== info.startDate) {
            this.setState({
                clientId: this.props.info.clientId,
                employeeId: this.props.info.employeeId,
                startDate: this.props.info.startDate,
                schedulerRef: this.props.info.schedulerRef
            })
        }
        this.popupRef.current.instance.show();
    }
}

export default AddAppointmentPopup
