// JavaScript source code
import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import CustomStore from 'devextreme/data/custom_store';
import roMessages from "./../../../ro.json";
import { getClientRiskCategoriesData } from "../ClientRiskData";

import {onRowUpdating, isNotEmpty, handleErrors} from "Utils/Utils";
import { locale, loadMessages } from "devextreme/localization";
import DataGrid, {

    Button,
    Column,
    Editing,
    FilterRow,
    Form, FormItem,
    StringLengthRule,
    Popup, RequiredRule,
    Lookup,
    Scrolling, SearchPanel
} from 'devextreme-react/data-grid';
import { data } from 'jquery';


const textAreaOptions = {
    height: "200vh"
}


const riskImpactDataSourceOptions = new CustomStore({
    key: "Id",
    load: (loadOptions) => {
        let params = "?";
        [
            "skip",
            "take",
            "requireTotalCount",
            "sort",
            "filter"
        ].forEach(function (i) {
            if (i in loadOptions && isNotEmpty(loadOptions[i]))
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        });

        params = params.slice(0, -1);

        return fetch(`/riskimpacts${params}`)
            .then(handleErrors)
            .then(response => response.json())
            .then(response => {
                return {
                    data: response.data,
                    totalCount: response.totalCount,
                    summary: response.summary,
                    groupCount: response.groupCount
                };
            })
            .catch(err => err);
    },
    byKey: (key) => {
        return fetch('/riskimpacts/' + key)
            .then(response => response.json())
            .then(response => {
                return response
            })

    }
})

const riskProbabilityDataSourceOptions = new CustomStore({
    key: "Id",
    load: (loadOptions) => {
        let params = "?";
        [
            "skip",
            "take",
            "requireTotalCount",
            "sort",
            "filter"
        ].forEach(function (i) {
            if (i in loadOptions && isNotEmpty(loadOptions[i]))
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
        });

        params = params.slice(0, -1);

        return fetch(`/riskprobabilities${params}`)
            .then(handleErrors)
            .then(response => response.json())
            .then(response => {
                return {
                    data: response.data,
                    totalCount: response.totalCount,
                    summary: response.summary,
                    groupCount: response.groupCount
                };
            })
            .catch(err => err);
    },
    byKey: (key) => {
        return fetch('/riskprobabilities/' + key)
            .then(response => response.json())
            .then(response => {
                return response
            })

    }
})

export class ClientRiskCategories extends Component {

    constructor(props) {
        super(props)
        this.state = {
            clientRiskId: 0,
            measureId : 0,
            subMeasureId : 0,
            setContainerState: null,
            selectedRow: [],
            dataSource: null
          
        }

        loadMessages(roMessages);
        locale(navigator.language);

        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onCellPrepared = this.onCellPrepared.bind(this);
    
    }

   


    render() {
        return (
            <div id="clientcategories">
                <DataGrid
                    width="100%"                                
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    wordWrapEnabled={true}
                    columnAutoWidth={true}   
                    onRowUpdating={onRowUpdating}
                    focusedRowEnabled={true}
                    dataSource={this.state.dataSource}
                    onSelectionChanged={this.onSelectionChanged}  
                    selection={{ mode: 'single' }}                                        
                    height={350}
                    remoteOperations={true}
                    onCellPrepared={this.onCellPrepared}
                >
                    <FilterRow visible={true} />
                 
                    <SearchPanel visible={true} highlightCaseSensitive={true} placeholder="Cautare..." />

                    <Editing allowAdding={false} allowUpdating={true} allowDeleting={false} mode="popup" useIcons={true} >
                        <Form colCount={1} title="Categorii" />
                        <Popup title="Categorii" showTitle={true} />
                    </Editing>

                    <Column type="buttons" cssClass="vertical-center" width="32" alignment="left" fixed={true} fixedPosition="left">
                        <Button name="edit" cssClass="underlineBtn" />
                    </Column>

                    <Column dataField="Id" visible={false} allowEditing={false} showEditorAlways={false}>
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="MeasureId" visible={false} allowEditing={false} showEditorAlways={false}>
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="ClientRiskId" visible={false} allowEditing={false} showEditorAlways={false}>
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="SubMeasureId" visible={false} allowEditing={false} showEditorAlways={false}>
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="CategoryId" visible={false} allowEditing={false} showEditorAlways={false}>
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="Name" caption="Categorie" allowEditing={false} cssClass="vertical-center"
                        alignment="center" fixed={true} width="70" showEditorAlways={false} cellHintEnabled={true} >
                        <RequiredRule message="Camp obligatoriu!" />
                        <FormItem visible={false} />
                    </Column>

                    <Column dataField="FunctionalExistenceNotes" caption="Nota F" cssClass="vertical-center"
                        alignment="center" width="95" fixed={true} cellHintEnabled={true}>
                        <RequiredRule message="Camp obligatoriu!" />
                        <StringLengthRule max={500} message="Nota depaseste limita maxima de 500 de caractere!" />
                        <FormItem colSpan={1} editorType="dxTextArea" editorOption={textAreaOptions} dataField="FunctionalExistenceNotes" />
                    </Column>
                    <Column dataField="FunctionalExistenceScore" caption="F" allowEditing={false} cssClass="vertical-center" fixed={true}
                        alignment="center" width="30" showEditorAlways={false}>
                        <FormItem visible={false} />
                        <RequiredRule message="Camp obligatoriu!" />
                    </Column>
                    <Column dataField="RiskNotes" caption="Nota R" cssClass="vertical-center"
                        alignment="center" width="95" fixed={true} cellHintEnabled={true}>
                        <RequiredRule message="Camp obligatoriu!" />
                        <StringLengthRule max={500} message="Nota depaseste limita maxima de 500 de caractere!" />
                        <FormItem colSpan={1} editorType="dxTextArea" editorOption={textAreaOptions} dataField="RiskNotes" />
                    </Column>

                    <Column dataField="RiskImpactId" caption="Impact" cssClass="vertical-center" fixed={true} allowEditing={true} alignment="center" fixed={true} width="100" showEditorAlways={false}>
                        <RequiredRule message="Camp obligatoriu!" />
                        <Lookup dataSource={riskImpactDataSourceOptions} displayExpr="Name" valueExpr="Id" />
                    </Column>

                    <Column dataField="RiskProbabilityId" caption="Probabilitate"  cssClass="vertical-center" fixed={true} allowEditing={true} alignment="center" width="100" showEditorAlways={false}>
                        <RequiredRule message="Camp obligatoriu!" />
                        <Lookup dataSource={riskProbabilityDataSourceOptions} displayExpr="Name" valueExpr="Id" />
                    </Column>

                    <Column dataField="RiskScore" caption="R" cssClass="vertical-center" fixed={true} allowEditing={false} alignment="center" fixed={true} width="30" showEditorAlways={false}>
                        <RequiredRule message="Camp obligatoriu!" />
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="Percent" caption="Procent" cssClass="vertical-center" fixed={true} allowEditing={false} alignment="center" fixed={true} width="30" showEditorAlways={false}>
                        <RequiredRule message="Camp obligatoriu!" />
                        <FormItem visible={false} />
                    </Column>
                </DataGrid>
               
            </div>
        )
    }

   
     
        dataSourceOptions = new CustomStore({
            key: "Id",
            load: (loadOptions) => {
                let params = "?";
                [
                    "skip",
                    "take",
                    "requireTotalCount",
                    "sort",
                    "filter"
                ].forEach(function (i) {
                    if (i in loadOptions && isNotEmpty(loadOptions[i]))
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                });

                params = params.slice(0, -1);

                return fetch(`/api/clientRisks/${this.state.clientRiskId}/measures/${this.state.measureId}/subMeasures/${this.state.subMeasureId}/categories${params}`)
                    .then(handleErrors)
                    .then(response => response.json())
                    .then(response => {                       
                        return {
                            data: response.data,
                             totalCount: response.totalCount,
                            summary: response.summary,
                            groupCount: response.groupCount
                        };
                    })
                    .catch(err => err);
            },
            byKey: (key) => {
                return fetch(`/api/clientcategoryrisks/` + key)
                    .then(response => response.json())
                    .then(response => {
                        return response
                    })

            },
            update: (key, values) => {
                return fetch("/api/clientcategoryrisks/" + key, {
                    method: "PUT",
                    body: JSON.stringify(values),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json())
            }
        })




    onCellPrepared(e) {
      
        if ((e.columnIndex === 4 || e.columnIndex === 5 || e.columnIndex === 6 || e.columnIndex === 7) && e.rowType === 'header') {
            e.cellElement.bgColor = '#ED7D31';
        }

        if ((e.columnIndex === 2 || e.columnIndex === 3) && e.rowType === 'header') {
            e.cellElement.bgColor = '#4472C4';
        }
    }

    onSelectionChanged({ selectedRowsData }) {
      
     
         if (selectedRowsData.length > 0) {
             const data = selectedRowsData[0];         
             this.setState({     
                 clientRiskId: this.props.clientRiskId,
                 measureId: this.props.measureId,
                 subMeasureId: this.props.subMeasureId,              
                 setContainerState: this.props.setContainerState(data.Id)
             });
         }
   
     }

  
    componentDidMount(props) {   
        //console.log('mount');
        // console.log(this.props);
        const { clientRiskId, measureId, subMeasureId, setContainerState } = this.props;
        this.setState({
            clientRiskId: clientRiskId,
            setContainerState: setContainerState,
            selectedData: [1],
            measureId: measureId,
            subMeasureId: subMeasureId,
            dataSource: this.dataSourceOptions,
        
        })

    }

    componentDidUpdate(prevProps) {      
        if (this.props.clientRiskId !== prevProps.clientRiskId || this.props.measureId !== prevProps.measureId || this.props.subMeasureId !== prevProps.subMeasureId) {
            const { clientRiskId, measureId, subMeasureId, setContainerState  } = this.props;
            
            console.log(this.props);
            this.setState({ 
                clientRiskId: clientRiskId,
                measureId: measureId,
                subMeasureId: subMeasureId,
                setContainerState: setContainerState,
                dataSource: getClientRiskCategoriesData(clientRiskId, measureId, subMeasureId)
            })
        }
    }
    

}

export default ClientRiskCategories;