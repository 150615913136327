import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import roMessages from "./../../ro.json";
import Form, { Item, GroupItem } from "devextreme-react/form";
import { locale, loadMessages } from "devextreme/localization";
import { EmployeesGrid } from "components/Employees/EmployeesGrid/_EmployeesGrid"

export class Employees extends Component {
    static displayName = Employees.name;

    constructor(props) {
        super(props)
        loadMessages(roMessages);
        locale(navigator.language);
    }

    render() {
        return (
            <div className="risk-assessment-container">
                <Form colCount={1}>
                    <GroupItem caption="Angajati">
                        <Item>
                            <EmployeesGrid />
                        </Item>
                    </GroupItem>
                </Form>
                <div id="auditPopupContainer" />
            </div>
        )
    }
}

export default Employees;