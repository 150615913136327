import React, { Component } from 'react';
import { CheckBox } from 'devextreme-react/check-box';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import './OffersStyle.css';
import { getoffersDataSource } from './OffersData';



export class OfferStatuses extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
            checkedItems: new Map([["cbBidder", true], ["cbAccepted", false], ["cbRefused", false], ["cbDeleted", false]])
        };

        this.checkBoxBidderRef      = React.createRef();
        this.checkBoxAcceptedRef    = React.createRef();
        this.checkBoxRefusedRef     = React.createRef();
        this.checkBoxDeletedRef     = React.createRef();

        this.onValueChanged = this.onValueChanged.bind(this);
    }

    onValueChanged(e) {
        const item = e.element.id;
        const isChecked = e.value;

       
        this.setState(
            prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) })
        );
     
        this.props.getDataSource(getoffersDataSource(this.state.checkedItems));

        this.props.sendRefGrid.current.instance.refresh();
    }

    render() {

        return (
            <div className="dx-field-all-statuses">

                <div className="offer-checkBox">
                    <CheckBox
                        ref={this.checkBoxBidderRef}
                        id ="cbBidder"                     
                        name="cbBidder"
                        text="Ofertat"                        
                        defaultValue = {true}
                        value={this.checkBoxBidderRef.current != null ? this.state.checkedItems.get(this.checkBoxBidderRef.current.props.id) : true}
                        onValueChanged={this.onValueChanged}

                    />

                </div>
                <div className="offer-checkBox">
                    <CheckBox
                        ref={this.checkBoxAcceptedRef}
                        id = "cbAccepted"
                        name="cbAccepted"
                        text="Acceptat"
                        defaultValue={false}
                        value={this.checkBoxAcceptedRef.current != null ? this.state.checkedItems.get(this.checkBoxAcceptedRef.current.props.id) : false}
                        onValueChanged={this.onValueChanged}
                    />
                </div>
                <div className="offer-checkBox">
                    <CheckBox
                        ref={this.checkBoxRefusedRef}
                        id = "cbRefused"
                        name="cbRefused"
                        text="Refuzat"
                        defaultValue={false}
                        value={this.checkBoxRefusedRef.current != null ? this.state.checkedItems.get(this.checkBoxRefusedRef.current.props.id) : false}
                        onValueChanged={this.onValueChanged}
                    />
                </div>
                <div className="offer-checkBox">
                    <CheckBox
                        ref={this.checkBoxDeletedRef}
                        id = "cbDeleted"
                        name="cbDeleted"
                        text="Sters"
                        defaultValue={false}
                        value={this.checkBoxDeletedRef.current != null ? this.state.checkedItems.get(this.checkBoxDeletedRef.current.props.id) : false}
                        onValueChanged={this.onValueChanged}
                    />
                </div>

            </div>


        );
    }

}

export default OfferStatuses;