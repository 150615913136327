import React, { Component } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import roMessages from "./../../../ro.json";
import { locale, loadMessages } from "devextreme/localization";
import TextBox from "devextreme-react/text-box";
import Form, { Item, RequiredRule, EmailRule, ButtonItem } from "devextreme-react/form";
import { Redirect } from 'react-router';
import notify from 'devextreme/ui/notify';
import { LoginActions } from '../../api-authorization/ApiAuthorizationConstants';

export class LoginForm extends Component {
    static displayName = LoginForm.name;

    constructor(props) {
        super(props);
        loadMessages(roMessages);
        locale(navigator.language);

        this.state = {
            redirect: false
        }
        this.formRef = React.createRef();

        this.userNameTextOptions = {
            mode: "email"
        }

        this.passwordTextOptions = {
            mode: "password"
        }

        this.submitButtonOptions = {
            width: "10em",
            text: "Autentificare",
            onClick: (e) => {
                this.submitButtonOnClick(e);
            }
        }

        this.submitButtonOnClick = (e) => {
            var validationResult = this.formRef.current.instance.validate();
            if (validationResult.isValid) {
                var formData = this.formRef.current.instance.option("formData");
                fetch("/account", {
                    method: "POST",
                    body: JSON.stringify(formData),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(response => {
                    if (response.status === 200) {
                        this.setState({ redirect: true })
                    }
                    else {
                        notify("Adresa de e-mail sau parola nu sunt corecte!", "error", 3000)
                    }
                })
            }
        }
    }

    render() {
        const { redirect } = this.state;

        if (redirect) {
            return <Redirect to={`/authentication/${LoginActions.Login}`} />
        }

        return (
            <div className="vertical-element">
                <Form
                    className="login-form"
                    ref={this.formRef}
                    showColonAfterLabel={true}
                    labelLocation="left"
                    colCount={1}
                    width="auto"
                >
                    <Item
                        dataField="Email"
                        editorType="dxTextBox"
                        editorOptions={this.userNameTextOptions}
                        label={{ text: "Adresa de e-mail" }}
                    >
                        <RequiredRule message="Adresa de e-mail este obligatorie!" />
                        <EmailRule message="Adresa de e-mail introdusa nu are un format valid!" />
                    </Item>
                    <Item
                        dataField="Password"
                        editorType="dxTextBox"
                        editorOptions={this.passwordTextOptions}
                        label={{text: "Parola"}}
                    >
                        <RequiredRule message="Parola este obligatorie!" />
                    </Item>
                    <Item
                        dataField="RememberMe"
                        editorType="dxCheckBox"
                        label={{ text: "Pastreaza-ma autentificat" }}
                        editorOptions={{value: false}}
                    >
                    </Item>
                    <ButtonItem horizontalAlignment="center" buttonOptions={this.submitButtonOptions}/>
                </Form>
                </div>
        )
    }
}

export default LoginForm;
