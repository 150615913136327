import { handleErrors, isNotEmpty, isNullOrEmpty } from "Utils/Utils";
import fetchDataContactPersons from '../Contracts/SyncDataContactPersons';
import CustomStore from 'devextreme/data/custom_store';

function getStatusesParam(statuses) {
    let mapToArray = Array.from(statuses.values());
    var param = "";
    mapToArray.forEach(function (i) {
        param += "&statuses=" + i;
    });
    return param;
}

const dataSourceOptions = (statuses) => {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {

            let filter = "";
            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter",
                "searchExpr",
                "searchOperation",
                "searchValue"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i])) {      
                    if (!isNotEmpty(loadOptions.filter))
                        if (i === "searchExpr") {
                            filter = "filter=[" + JSON.stringify(loadOptions.searchExpr) + "," + JSON.stringify(loadOptions.searchOperation) + "," + JSON.stringify(loadOptions.searchValue) + "]"; // for lookup
                        }
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            });

            if (isNotEmpty(filter))
                params = params.concat(filter);
            else
                params = params.slice(0, -1);
       
            return fetch(`/api/contracts${params}${getStatusesParam(statuses)}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                     response.data.forEach(element => element.Date = element.Date.replace('Z', ''));
               
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    };
                })
                .catch(err => err);
        },
        byKey: (key) => {
            return fetch('/api/contracts/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })

        },
        insert: (values) => {
            var contractId = values.ContractId;
            return fetch("/api/contracts", {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
                .then(response => {
                    if (isNullOrEmpty(contractId))
                        fetchDataContactPersons(response.ContractId, values.ContactPersons);
                    return response
                })
        },
        update: (key, values) => {
            fetchDataContactPersons(values.ContractId, values.ContactPersonId);

            return fetch("/api/contracts/" + key, {
                method: "PUT",
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => response.json())
        },
        remove: (key) => {
            return fetch("/api/contracts/" + key, {
                method: "DELETE",
                body: JSON.stringify({ id: key }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        }
    })
}
export default dataSourceOptions;