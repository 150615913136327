import { GetCustomDate_yyyy_MM_dd } from "Utils/Utils";

function getLastDayOfLastYearDate() {
   return new Date(new Date().getFullYear() - 1, 11, 31);
}

const fetchExchangeRate = async () => {
    const response = await fetch(`/../api/exchangerates?date=${GetCustomDate_yyyy_MM_dd(getLastDayOfLastYearDate())}`);
    const data = await response.json();

    if (response.state >= 400) {
        throw new Error(data.errors)
    }
    return data;
}

export default fetchExchangeRate;
