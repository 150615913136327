import React, { Component } from 'react';
import { onRowUpdating, isAuditVisible } from "Utils/Utils";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import CustomStore from 'devextreme/data/custom_store';
import roMessages from "./../../../ro.json";
import { locale, loadMessages } from "devextreme/localization";
import TextArea from "devextreme-react/text-area";
import DataGrid, {
    Button,
    Column,
    Editing,
    RequiredRule,
    FormItem,
    Scrolling,
    StringLengthRule
} from 'devextreme-react/data-grid';

const textAreaOptions = {
    height: "30vh"
}

export class MeetingJournal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            meetingJournalDataSourceOptions: null
        };
        loadMessages(roMessages);
        locale(navigator.language);
        this.setAuditEntry = props.func;
        this.auditOnClick = (e) => {
            this.setAuditEntry(e.row.key, "MeetingJournal");
        }
    }

    render() {
        return (
            <div className="container-master-detail">
                <DataGrid
                    width="100%"
                    dataSource={this.state.meetingJournalDataSourceOptions}
                    showBorders={true}
                    showColumnLines={true}
                    showRowLines={true}
                    wordWrapEnabled={true}
                    height="100%"
                    remoteOperations={false}
                    onRowUpdating={onRowUpdating}
                >
                    <Scrolling columnRenderingMode="virtual" mode="infinite" />
                    <Editing allowAdding={true} allowUpdating={true} allowDeleting={true} mode="form" useIcons={true} />
                    <Column type="buttons" width={120} cssClass="vertical-center">
                        <Button name="edit" />
                        <Button name="delete" />
                        <Button icon="info" hint="Audit" onClick={this.auditOnClick} visible={isAuditVisible} />
                    </Column>
                    <Column dataField="Id" visible={false} allowEditing={false}>
                        <FormItem visible={false} />
                    </Column>
                    <Column dataField="Date" caption="Data intalnirii" dataType="date" format="dd.MM.yyyy" cssClass="vertical-center" alignment="center">
                        <RequiredRule message="Camp obligatoriu!" />
                    </Column>
                    <Column dataField="Summary" caption="Sumarul intalnirii">
                        <RequiredRule message="Camp obligatoriu!" />
                        <StringLengthRule max={500} message="Sumarul depaseste limita maxima de 500 de caractere!" />
                        <FormItem colSpan={1} editorType="dxTextArea" editorOption={textAreaOptions} dataField="Summary" />
                    </Column>
                </DataGrid>
            </div>
        );
    }

    componentDidMount(props) {
        const { clientId } = this.props;
        this.setState({
            meetingJournalDataSourceOptions: new CustomStore({
                key: "Id",
                load: (loadOptions) => {
                    return fetch(`/Client/${clientId}/meetingjournals`)
                        .then(response => response.json());
                },
                insert: (values) => {
                    values["ClientId"] = clientId;
                    return fetch("/MeetingJournal", {
                        method: "POST",
                        body: JSON.stringify(values),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(response => response.json());
                },
                update: (key, values) => {
                    return fetch("/MeetingJournal/" + key, {
                        method: "PUT",
                        body: JSON.stringify(values),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(response => response.json());
                },
                remove: (key) => {
                    return fetch("/MeetingJournal/" + key, {
                        method: "DELETE",
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                }
            })
        })
    }
}

export default MeetingJournal;