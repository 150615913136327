import CustomStore from "devextreme/data/custom_store";
import { isNotEmpty, handleErrors } from "Utils/Utils";

export function getProjectDataSource(clientId) {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            if (!clientId) {
                return Promise.resolve([])
            }

            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);
            return fetch(`/api/clients/${clientId}/projects${params}`)
                .then(response => response.json())
                .then(response => {
                    return response;
                })
        },
        byKey: (key) => {
            return fetch('/api/projects/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    });
}

export function getClientsDataSource(employeeId) {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            if (!employeeId) {
                return Promise.resolve([])
            }

            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/employees/${employeeId}/clients${params}`)
                .then(response => response.json())
                .then(response => {
                    return response;
                })
        },
        byKey: (key) => {
            return fetch('/client/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })
        }
    });
}

export function getEmployeesDataSource() {
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "sort",
                "filter"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/employees${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return response.data;
                })
                .catch(err => err);
        },
        byKey: (key) => {
            return fetch('/api/employees/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })

        }
    })
}
export function getActivitiesDataSource(){
    return new CustomStore({
        key: "Id",
        load: (loadOptions) => {
            let params = "?";
            [
                "sort",
                "filter",
                "group",
                "parentIds"
            ].forEach(function (i) {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            params = params.slice(0, -1);

            return fetch(`/api/activities${params}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(response => {
                    return {
                        data: response.data,
                        totalCount: response.totalCount,
                        summary: response.summary,
                        groupCount: response.groupCount
                    };
                })
                .catch(err => err);
        },
        byKey: (key) => {
            return fetch('/api/activities/' + key)
                .then(response => response.json())
                .then(response => {
                    return response
                })

        }
    })
}