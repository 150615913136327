import React, { Component, Fragment } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import roMessages from "./../../ro.json";
import Form, { Item, GroupItem, EmptyItem, Label } from "devextreme-react/form";
import { locale, loadMessages } from "devextreme/localization";
import { DateBox } from 'devextreme-react';
import { Lookup } from 'devextreme-react';
import { CalendarOptions } from 'devextreme-react/date-box';
import { getClientsDataSource } from './_ClientCompanyIndicatorsDatasources';
import { CompanyIndicatorsGrid } from './_CompanyIndicatorsGrid';
import { ScrollView } from 'devextreme-react';

const setDate = (component, date) => {
    component.option("value", date)
}

export class ClientCompanyIndicators extends Component {
    static displayName = ClientCompanyIndicators.name;
    constructor(props){
        super(props)
        loadMessages(roMessages)
        locale(navigator.language);
        this.state = {
            clientId: null,
            startYear: new Date().getFullYear() - (new Date().getFullYear() % 10),
            endYear: (new Date().getFullYear() - (new Date().getFullYear() % 10)) + 10
        }

        this.startDateRef = React.createRef();
        this.endDateRef = React.createRef();
        this.clientLookupRef = React.createRef();
    }

    render() {
        return(
            <Fragment>
                    <Form colCount={1}>
                        <GroupItem caption="Indicatori companie" colSpan={1} colCount={2}>
                            <GroupItem colSpan={1} colCount={3}>
                                <Item colSpan={1}>
                                    <Label alignment="top" showColon={true} text="Client" />
                                    <Lookup displayExpr="Name" valueExpr="Id" dataSource={getClientsDataSource(this.clientLookupRef)} onValueChanged={(e) => {this.setState({clientId: e.value})}} 
                                        dropDownOptions={{title: "Clienti"}}
                                        ref={this.clientLookupRef}
                                        onInitialized={(e) => {if(!e.component.option("value")) e.component.getDataSource().load()}}
                                    />
                                </Item>
                                <Item colSpan={1}>
                                    <Label alignment="top" showColon={true} text="An start" />
                                    <DateBox displayFormat="yyyy" ref={this.startDateRef} onValueChanged={(e) => {this.setState({startYear: e.value.getFullYear()})}}
                                        onInitialized={(e) => setDate(e.component, new Date(this.state.startYear, 0, 1))}
                                    >
                                        <CalendarOptions maxZoomLevel="decade" minZoomLevel="century" zoomLevel="decade" />
                                    </DateBox>
                                </Item>
                                <Item colSpan={1}>
                                    <Label alignment="top" showColon={true} text="An final" />
                                    <DateBox displayFormat="yyyy" ref={this.endDateRef} onValueChanged={(e) => {this.setState({endYear: e.value.getFullYear()})}}
                                        onInitialized={(e) => setDate(e.component, new Date(this.state.endYear, 0, 1))}
                                    >
                                        <CalendarOptions maxZoomLevel="decade" minZoomLevel="century" zoomLevel="decade" />
                                    </DateBox>
                                </Item>
                            </GroupItem>
                            <EmptyItem colSpan={1} />
                        </GroupItem>
                    </Form>
                    <ScrollView height="79.5vh">
                        <Form colCount={1} key="Form">
                            <GroupItem colSpan={2} colCount={1} key="Group">
                                <GroupItem colSpan={1} caption="Balance Sheet" key="BalanceSheet">
                                    <CompanyIndicatorsGrid ClientId={this.state.clientId} StartYear={this.state.startYear} EndYear={this.state.endYear} IndicatorsCode="BalanceSheet" key="BalanceSheetGrd"/>
                                </GroupItem>
                                <GroupItem colSpan={1} caption="Profit & Loss" key="ProfitLoss">
                                    <CompanyIndicatorsGrid ClientId={this.state.clientId} StartYear={this.state.startYear} EndYear={this.state.endYear} IndicatorsCode="ProfitLoss" key="ProfitLossGrd"/>
                                </GroupItem>
                                <GroupItem colSpan={1} caption="Formular F30" key="F30">
                                    <CompanyIndicatorsGrid ClientId={this.state.clientId} StartYear={this.state.startYear} EndYear={this.state.endYear} IndicatorsCode="F30" key="F30Grd"/>
                                </GroupItem>
                                <GroupItem colSpan={1} caption="Indicatori Analiza Financiara" key="Indicators">
                                    <CompanyIndicatorsGrid ClientId={this.state.clientId} StartYear={this.state.startYear} EndYear={this.state.endYear} IndicatorsCode="Indicators" key="IndicatorsGrd" />
                                </GroupItem>
                            </GroupItem>
                        </Form>
                    </ScrollView>
                <div id="auditPopupContainer" />
                <div id="editPopupContainer" />
                <div id="addPopupContainer" />
            </Fragment>
        )
    }
}
