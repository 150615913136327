import React, { Component, Fragment } from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import roMessages from "./../../ro.json";
import Form, { Item, GroupItem } from "devextreme-react/form";
import { locale, loadMessages } from "devextreme/localization";
import { ProjectGidComponent } from './_ProjectsGridComponent';

export class Project extends Component {
    static displayName = Project.name;

    constructor(props) {
        super(props)
        loadMessages(roMessages);
        locale(navigator.language);
    }

    render() {
        return (
            <Fragment >
                <Form colCount={1}>
                    <GroupItem caption="Proiecte">
                        <Item>
                            <ProjectGidComponent />
                        </Item>
                    </GroupItem>
                </Form>
                <div id="auditPopupContainer" />
            </Fragment>
        )
    }
}

export default Project;