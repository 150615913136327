import React from 'react';
import { Popup } from 'devextreme-react/popup';
import { ScrollView } from 'devextreme-react';
import AuditGrid from 'components/Shared/AuditPopup/AuditGrid/_AuditGridComponent';

class PopupComponent extends React.Component {
    constructor(props) {
        super(props)
        this.popupRef = React.createRef();
        this.state = {
            popupVisible: false,
            Page: props.info.Page,
            EntryId: props.info.EntryId,
        }
        this.showAudit = () => {
            this.state.popupVisible = true;
            
        }

        this.hideAudit = () => {
            this.state.popupVisible = false;
        }

        this.ShowAuditGrid = (EntryId, Page) => {
            return <AuditGrid Page={Page} EntryId={EntryId} />
        }
    }

    render() {
        return (
            <div>
                <Popup
                    onHiding={this.hideAudit}
                    dragEnabled={true}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    ref={this.popupRef}
                    title="Audit"
                    width="70vw"
                    height="80vh"
                >
                    <ScrollView width="100%" height="100%">
                        {this.ShowAuditGrid(this.state.EntryId, this.state.Page)}
                    </ScrollView>
                </Popup>
            </div>
        )
    }

    componentDidUpdate(prevProps) {
        const { info } = prevProps
        if (this.props.info.Page !== info.Page || this.props.info.EntryId !== info.EntryId) {
            this.setState({
                Page: this.props.info.Page,
                EntryId: this.props.info.EntryId
            })
        }
        this.popupRef.current.instance.show();
    }

    componentDidMount(props) {
        const { info } = this.props
        this.setState({
            Page: info.Page,
            EntryId: info.EntryId
        })
        this.popupRef.current.instance.show();
    }
}

export default PopupComponent;
