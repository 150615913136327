import React from 'react';

export function AppointmentTemplate(model) {
    const { appointmentData } = model.data
    return (
        <div>
            <div className="dx-scheduler-appointment-title"><b>{appointmentData.Name}</b></div>
            <div dangerouslySetInnerHTML={{__html: appointmentData.Description}} />
            <div>
                <div className="dx-scheduler-appointment-content-date">
                    {formatDate(new Date(appointmentData.StartDate))}
                    {' -> '}
                    {formatDate(new Date(appointmentData.EndDate))}
                </div>
            </div>
        </div>
    );
}

function formatDate(date) {
    var day = date.getDate().toString().padStart(2, "0")
    var month = (date.getMonth() + 1).toString().padStart(2, "0")
    var year = (date.getFullYear()).toString();
    var hour = (date.getHours() + 1).toString().padStart(2, "0")
    var minutes = (date.getMinutes()).toString().padStart(2, "0")

    return `${day}.${month}.${year} ${hour}:${minutes}`;
}